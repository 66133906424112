<template>
    <div class="modal is-active" >
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <div class="box">
                <h1 class="title is-5">Reservation History</h1>
                <div v-if="!hasReservations">
                    <div class="hero is-light">
                        <div class="hero-body">
                            <div>
                                <h1 class="title is-3 has-text-grey-light has-text-centered">No reservation yet</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <table v-else class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Salesforce ID</th>
                            <th>Customer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="reservation in reservations" :key="reservation.salesforce_id">
                            <td>{{ reservation.lastUpdateDate | formatDate}}</td>
                            <td>
                                <router-link :to="`/reservations/show/${reservation.salesforce_id}`" target="_blank">
                                    {{ reservation.salesforce_id }}
                                </router-link>
                            </td>
                            <td>{{ (allReservations[reservation.salesforce_id] || {}).customer }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
export default {
    props:['reservations'],
    methods:{
        close(){
            this.$emit('close')
        },
        
    },
    computed:{
        hasReservations(){
            return this.reservations.length > 0
        },
        allReservations(){
            return R.pipe(
                R.indexBy(R.prop('salesforce_id'))
            )(this.$store.state.capry.reservations)
        }
    },
    filters:{
        formatDate(str){
            return moment(str).format('YYYY-MM-DD')
        }
    }
}
</script>
