import { category, capacityType, halfOwnership } from './rules'
export const mapping  = () => ({
  aag: {
    cableName: 'Asia-America Gateway (AAG) Cable System',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [
      { description: 'Old MIU capacity', equipedInGB: 63.56,  remainingInGB: 11.51, }, // attached to segment?,
    ],
    reportingRoutes: [
      { name: 'Malaysia - Hong Kong', from: "Mersing, Malaysia", to: "Lantau Island, Hong Kong, China" },
      { name: 'Singapore - Hong Kong', from: "Changi North, Singapore", to: "Lantau Island, Hong Kong, China" },
      { name: 'Hong Kong - USA', from: "Lantau Island, Hong Kong, China", to: "San Luis Obispo, California, United States" },
      // {
      //   name: 'Singapore/Malaysia to US',
      //   from: ['Mersing, Malaysia', 'Changi North, Singapore'],
      //   to: 'San Luis Obispo, California, United States'
      // }
    ]
  },
  apcn2: {
    cableName: 'APCN-2',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [
      
    ],
    reportingRoutes: [{
        name: 'Malaysia - Chikura, Japan (via Singapore)',
        from: "Kuantan, Malaysia",
        to: "Chikura, Japan",
        excludes: ['Lantau Island, Hong Kong, China']
      },
      {
        name: 'Malaysia - Kitaibaraki, Japan (via Hong Kong)',
        from: "Kuantan, Malaysia",
        to: "Kitaibaraki, Japan",
        excludes: ['Katong, Singapore']
      },
      {
        name: 'Chikura - Kitaibaraki',
        from: "Kitaibaraki, Japan",
        to: "Chikura, Japan",
        excludes: ['Lantau Island, Hong Kong, China']
      },
    ]
  },
  bbg: {
    cableName: 'Bay of Bengal Gateway (BBG)',
    rules: [
      category('onnet'),
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [
      {
        name: 'Singapore to Malaysia',
        from: ['Equinix Pop', 'Global Switch Pop'],
        to: ["Penang, Malaysia"]
      },
      {
        name: 'Malaysia to Middle East',
        from: ["Penang, Malaysia"],
        to: ["Barka, Oman", "Fujairah, United Arab Emirates"]
      },
      {
        name: 'Malaysia to India',
        from: ["Penang, Malaysia"],
        to: ["Chennai, India", "Mumbai, India"]
      },
    ]
  },
  bdm: {
    cableName: 'Batam Dumai Melaka (BDM) Cable System',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Melaka to Batam',
      from: 'Melaka, Malaysia',
      to: 'Batam, Indonesia'
    }, {
      name: 'Melaka to Dumai',
      from: 'Melaka, Malaysia',
      to: 'Dumai, Indonesia'
    }]
  },
  cm: {
    cableName: 'Asia Submarine-cable Express (ASE)/Cahaya Malaysia',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [
      {
        name: 'Mersing to Hong Kong',
        from: 'Mersing, Malaysia',
        to: 'Tseung Kwan O, Hong Kong, China',
         excludes: ['Shin-Maruyama, Maruyama, Japan']
      },
      {
        name: 'Mersing to Maruyama',
        from: 'Mersing, Malaysia',
        to: 'Shin-Maruyama, Maruyama, Japan',
         excludes: ['Tseung Kwan O, Hong Kong, China']
      },
      {
        name: 'Hong Kong to Maruyama',
        from: 'Tseung Kwan O, Hong Kong, China',
        to: 'Shin-Maruyama, Maruyama, Japan',
        excludes: ['Mersing, Malaysia']
      },
    ]
  },
  smw5: {
    cableName: 'SeaMeWe-5',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Singapore PoPs to Marseille',
      from: ['Equinix Pop, Tuas, Singapore', 'Global Switch Pop, Tuas, Singapore'],
      to: 'Marseille Pop, Toulon, France'
    }, ]
  },
  skr1m: {
    cableName: 'Sistem Kabel Rakyat 1Malaysia (SKR1M)',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'Domestic'
    },
    offsets: [],
    reportingRoutes: [
      {
        name: 'Semananjung to Sabah/Sarawak',
        from: ['Cherating, Malaysia', 'Mersing, Malaysia'],
        to: ['Kota Kinabalu, Malaysia', 'Kuching, Malaysia'],
        excludes: ['Miri, Malaysia']
      },
      {
        name: 'Sabah to Sarawak',
        from: ['Kota Kinabalu, Malaysia'],
        to: ['Kuching, Malaysia']
      },
    ]
  },
  stingrayPangkor: {
    cableName: 'Stingray-Pangkor',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'Domestic'
    },
    offsets: [],
    reportingRoutes: [{
        name: 'Lumut - Pangkor',
        from: ['Lumut, Malaysia'],
        to: ['Pulau Pangkor, Malaysia'],
      },
    ]
  },
  stingrayPerhentian: {
    cableName: 'Stingray-Perhentian',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'Domestic'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Kuala Besut - Perhentian',
      from: ['Kuala Besut, Malaysia'],
      to: ['Pulau Perhentian, Malaysia'],
    }, ]
  },
  stingrayTioman: {
    cableName: 'Stingray-Tioman',
    rules: [
      category('onnet')
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'Domestic'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Rompin - Tioman',
      from: ['Kuala Rompin, Malaysia'],
      to: ['Pulau Tioman, Malaysia'],
    }, ]
  },
  dmcs:{
    cableName: 'Dumai-Melaka Cable System',
    notes: 'TM own half-circuit capacities',
    rules: [
      category('onnet'),
      halfOwnership,
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [
      {
        name: 'Melaka to Dumai, Indonesia',
        from: ['Melaka, Malaysia'],
        to: ['Dumai, Indonesia'],
      },
    ]
  },
  juscn: {
    cableName: 'Japan-U.S. Cable Network (JUS)',
    miuOnly: true,
    notes: '',
    rules: [
      category('onnet'),
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Japan to USA',
      from: ['Shima, Japan', 'Kitaibaraki, Japan', 'Maruyama, Japan'],
      to: ['Manchester, United States', 'Morro Bay, United States',],
    }]
  },
  smw3: {
    cableName: 'SeaMeWe-3',
    miuOnly: true,
    notes: '',
    rules: [
      category('onnet'),
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Malaysia to Goonhilly',
      from: ['Penang, Malaysia', 'Mersing, Malaysia'],
      to: ['Goonhilly, UK'],
    }]
  },
  mct: {
    cableName: 'Malaysia-Cambodia-Thailand (MCT) Cable',
    notes: '',
    rules: [
      category('onnet'),
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Malaysia to Thailand',
      from: ['Cherating, Malaysia'],
      to: ['Rayong, Thailand'],
      excludes: ['Sihanoukville, Cambodia'],
    },
    {
      name: 'Malaysia to Cambodia',
      from: ['Cherating, Malaysia'],
      to: ['Sihanoukville, Cambodia'],
      excludes: ['Rayong, Thailand'],
    }]
  },
  nugate: {
    cableName: 'Nugate',
    notes: '',
    rules: [
      category('onnet'),
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [
      {
        name: 'Equinix Singapore to Jakarta',
        from: ['Equinix Pop, Tanah Merah, Singapore'],
        to: ['Jakarta Cyberbuilding Pop, Ancol, Indonesia'],
        // excludes: ['Sihanoukville, Cambodia'],
      },
      // {
      //   name: 'Equinix Singapore to Batam',
      //   from: ['Equinix Pop, Tanah Merah, Singapore'],
      //   to: ['Batam Interchange', 'Batam Tg Bemban'],
      //   // excludes: ['Sihanoukville, Cambodia'],
      // },
    ]
  },
  safe: {
    cableName: 'SAFE/SAT-3/WASC',
    miuOnly: true,
    notes: '',
    rules: [
      category('onnet'),
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Malaysia to South Africa',
      from: ['Penang, Malaysia'],
      to: ['Melkbosstrand, South Africa'],
      // excludes: ['Sihanoukville, Cambodia'],
    }, ]
  },
  smw4: {
    cableName: 'SeaMeWe-4',
    miuOnly: true,
    notes: '',
    rules: [
      category('onnet'),
    ],
    meta: {
      cableType: 'Onnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Singapore to Marseilles',
      from: ['Tuas, Singapore'],
      to: ['Marseilles, France'],
      // excludes: ['Sihanoukville, Cambodia'],
    }]
  },
  aae1: {
    cableName: 'Asia Africa Europe-1 (AAE-1)',
    // miuOnly: true,
    notes: '',
    rules: [
      category('offnet'),
    ],
    meta: {
      cableType: 'Offnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Hong Kong to Europe',
      from: ['Mega -1','Hong Kong Pop, Cape D\'Aguilar, Hong Kong, China'],
      to: ['Germany', 'Marseille Pop, Marseille, France']
    }]
  },
  eig: {
    cableName: 'Europe India Gateway (EIG)',
    // miuOnly: true,
    notes: '',
    rules: [
      category('offnet'),
    ],
    meta: {
      cableType: 'Offnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Middle East to UK',
      from: ['Barka, Oman', 'Fujairah, United Arab Emirates'],
      to: ['Bude, United Kingdom', 'London TH, Bude, United Kingdom']
    }]
  },
  faster: {
    cableName: 'FASTER',
    // miuOnly: true,
    notes: '',
    rules: [
      category('offnet'),
    ],
    meta: {
      cableType: 'Offnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Japan to US',
      from: ['CLS Chikura , Chikura, Japan ', 'Chikura, Japan'],
      to: ['OWB , Bandon, OR, United States', 'San Jose, Bandon, Oregon, United States', 'One Wilshire, Bandon, Oregon, United States']
    }]
  },
  imewe: {
    cableName: 'IMEWE',
    // miuOnly: true,
    notes: '',
    rules: [
      category('offnet'),
    ],
    meta: {
      cableType: 'Offnet',
      cableCategory: 'International'
    },
    offsets: [],
    reportingRoutes: [{
      name: 'Fujairah to Italy',
      from: ['Fujairah, United Arab Emirates'],
      to: ['Catania, Italy']
    }, {
      name: 'Fujairah to France',
      from: ['Fujairah, United Arab Emirates'],
      to: ['Marseilles Interxion, Marseille, France', 'Frankfurt Interxion, Marseille, France', 'London TC2, Marseille, France']
    }]
  },
})
