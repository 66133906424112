<template>
    <div>
        <utilization-table :rawdata="rawdata" @datachanged="handleDatachange" @missing="handleMissing" />
    </div>
</template>


<script>


import * as R from 'ramda'
import UtilizationTable from './UtilizationTable'
import CapacitySourceData from './../../../ITF/webcomponent/services/CapacitySourceData'

const calc = require('./../../Models/CapacityCalc')
const cableMapping = require('./cableMapping')


export default {
    components:{
        UtilizationTable,
    },
    data(){
        return {
            loaded: false,
            rawdata: false,
            refdata: false,
            cableMapping,
            utilization_data: false,
            error: {

            },
            showOld: false,
        }
    },
    mounted(){
        this.loadData()
    },
    computed:{
        cableSystems(){
            return R.sortBy(R.identity, R.keys(this.rawdata))
        },
        missings(){
            return R.pipe(R.identity, R.values,R.flatten)(this.error)
        }
    },
    methods:{
        handleMissing(missings){
            this.error = missings
        },
        handleDatachange(data){
            this.utilization_data = data
        },
        viewCable(cable){
            this.$store.commit('UPDATE_INVENTORY_KEYWORD', cable)
            this.$router.push('/inventory')
        },  
        getUtilization(data, cable){
            const cable_type = this.getRef(cable).cable_type;
            return 1 - (this.getTotal('total_capacity', cable_type , data) / this.getTotalEquiped(data, cable_type))
        },
        getActivated(data, cable){
            const cable_type = this.getRef(cable).cable_type;
            return this.getTotalEquiped(data, cable_type) - this.getTotal('total_capacity', cable_type, data)
        },
        getTotal(key, type, data){
            return R.pipe(R.filter(data => data.category == type),R.pluck(key), R.sum)(data)
        },
        getTotalEquiped(data, cable_type){
            return R.pipe(
                R.filter( data => data.category == cable_type),
                R.map(this.getTotalEquip),
                R.filter( data => data),
                R.sum
            )(data)
        },
        getRef(cable){
            return this.refdata[this.cableMapping[cable]] || {
                utilization: 0,
                remaining_capacity: 0,
                total_capacity: 0,
            }
        },
        loadData(){
            this.$store.dispatch('capry.utilization.load').then(res => {
                this.rawdata = res.data
                this.loaded = true
            })
        },
        getTotalEquip(data){
            if(!data.equiped_qty){
                return 0
            }
            return calc.toGbps(data.equiped_qty, data.unit)
        }
    },
    filters:{
        toPercent (value) {
            const percent = (value * 100).toFixed(1)
            return `${percent}%`
        },
        formatCapacity(value){
            return parseFloat(value).toLocaleString('en', { maximumFractionDigits: 2 })
        }
    }
}
</script>