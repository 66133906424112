import * as R from 'ramda'
import PathFinder from './../../Models/PathFinder'
import { cablemaps } from './../../Models/RouteExpender'



const byCableSystem = (routes) => {
    console.log("RECULALATE BY CABLE SYSTEM")
    const specialCountry = (name) => {
        const mapping = {
            'Rep.': 'Korea, Rep.',
            'UAE': 'United Arab Emirates',
            'UK': 'United Kingdom'
        }
        return mapping[name] || name
    }

    const mapPoint = point => {
        return {
            label: point,
            cable_system: R.head(point.split(", ")),
            country: specialCountry(R.last(point.replace('Hong Kong, China', 'Hong Kong').split(", ")))
        }
    }

    return R.pipe(
        R.map(mapPoint),
        R.groupBy(R.prop('cable_system')),
        R.map(R.groupBy(R.prop('country')))
    )(routes.points())
}

const calculate = (routes, from, to, type) => {
    const package_name = `(${type.toUpperCase()}) ${from}-${to}`
    const cable_systems = R.pipe(
        R.pluck('cable_system'),
    )(getPoints(routes, to, from))

    const froms = R.pipe(
        R.filter(data => cable_systems.indexOf(data.cable_system) > -1),
        R.groupBy(R.prop('cable_system'))
    )(getPoints(routes, from))

    const tos = R.pipe(
        R.groupBy(R.prop('cable_system'))
    )(getPoints(routes, to, from))

    const all = R.flatten(R.keys(froms).map(key => {
        return R.map(x => R.map(y => ({
            from: x,
            to: y
        }), tos[key]), froms[key])
    })).map(x => [x.from.label, x.to.label])

    const packages = routes.addPackage(package_name, all)

    let offerings = packages.getPackage(package_name)
    const groupByPoint = R.pipe(
        R.prop('routes'),
        R.groupBy(data => [R.head(data.path), R.last(data.path)].join(' -> ')),
        R.map(options => {
            return {
                route: [R.head(R.head(options).path), R.last(R.head(options).path)],
                // route: ["foo","bar"],
                total_options: options.length,
                capacity: routes.calculateCapacity(options),
                options: R.map(option => Object.assign({}, option, {
                    getSegmentCapacity: (id) => {
                        return routes.getSegmentCapacity(option.path[id], option.path[id + 1])
                    }
                }),options),
            }
        })
    )(offerings)
    
    offerings.groupRoutes = groupByPoint
    offerings.totalRoutes = R.values(groupByPoint).length

    return offerings
}

const getPoints = (routes, country, from) => {
    let hasCableSystem = () => true

    if (from) {
        const cable_systems = R.pipe(
            R.pluck('cable_system'),
            R.uniq,
        )(getPoints(routes,from))

        hasCableSystem = (cable_system) => cable_systems.indexOf(cable_system) > -1
    }

    return R.pipe(
        R.values,
        R.map(R.values),
        R.flatten,
        R.filter(data => data.country == country && hasCableSystem(data.cable_system))
    )(routes.byCableSystem)
}

const getCountries = (routes, start) => {
    const notCountry = data => {
        return [
            'any',
            'no-cls',
            start
        ].indexOf(data) == -1
    }

    return R.pipe(
        R.values,
        R.map(R.keys),
        list => {
            if (start) {
                return R.filter(R.includes(start), list)
            } else {
                return list
            }
        },
        R.flatten,
        R.uniq,
        R.sortBy(R.identity),
        R.filter(notCountry)
    )(routes.byCableSystem)
} 

export default (capacities, _miu_routes, type) => {
    let routes = PathFinder()
    const label = (cable_system, data) => [cable_system, data.pop, data.cls].filter(data => data).join(", ")
    const miulabel = (cable_system, mr) => [cable_system, mr.name, mr.country].join(", ")

    const getTotalCapacity = R.pipe(R.prop('summary'), R.filter(data => data.type == type), R.pluck('total_reservable_capacity'),R.sum)

    // load segment capacity
    capacities.forEach(route => {
        const isMiu = R.isEmpty(route.capacity_breakdown)
        if (!isMiu) {
            const lp = route.route.landing_points
            const capacity = getTotalCapacity(route)
            if (capacity > 0) {
                routes = routes.add(label(route.cable_system, lp[0]), label(route.cable_system, lp[1]), capacity, route)
            }
        } else {
            //MIU 
            let miu_routes = _miu_routes
                .filter(m => m.cable == cablemaps[route.cable_system])
                .map(mr => PathFinder.miuRoute(
                    miulabel(route.cable_system, mr.locations[0]),
                    miulabel(route.cable_system, mr.locations[1]),
                    mr.distance, mr.miu_for_10GB
                ))
            const capacity = getTotalCapacity(route)
            if (capacity > 0) {
                routes = routes.addMiu(route.cable_system, capacity, miu_routes)
            }
        }
    })

    routes.byCableSystem = byCableSystem(routes)
    routes.calculate = (from, to) => {
        let offerings = calculate(routes, from, to, type)
        return offerings
    }
    routes.getCountries = (start) => getCountries(routes, start)

    return routes
}




