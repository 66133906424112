import moment from 'moment-timezone';
moment().tz("Asia/Kuala_Lumpur");

function Service({
    id,
    phase_id,
    customer_id,
    customer_name,
    tm_cct_id,
    mrc,
    asset_id,
    phase,
    customer,
    supplier_cct_id
}) {
    this.id = id;
    this.phase_id = phase_id;
    this.customer_id = customer_id;
    this.customer_name = customer_name;
    this.tm_cct_id = tm_cct_id;
    this.mrc = mrc;
    this.asset_id = asset_id;
    this.phase = phase;
    this.customer = customer;
    this.supplier_cct_id = supplier_cct_id;
}

Service.prototype.isActive = function (date) {
    if (!moment.isMoment(date)) {
        throw Error({status: 400, message: 'Data is not a momemnt date'})
    }
    
    return true
};

export default Service;
