'use strict'
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueRouter from 'vue-router'
import VueMermaid from "vue-mermaid";
import App from './standalone/App.vue'
import VeeValidate from 'vee-validate';
import VueAnalytics from 'vue-analytics'
import VueClipboard from 'vue-clipboard2'
import FeatureFlag from './../../Mobile/webcomponent/common/FeatureFlag.vue'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueMermaid)
Vue.use(VueClipboard)
Vue.use(VeeValidate, { events: 'blur' });
Vue.component('FeatureFlag', FeatureFlag)

const routes = require('./standalone/routes')

import apollo_store from './../../Apollo/webcomponent/store.js'
import capry_store from './store'
import login_store from './../../Authentication/webcomponent/store'

// const token = process.env.CAPRY_TOKEN
// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

const store = new Vuex.Store({
    modules: {
        apollo: apollo_store,
        capry: capry_store,
        login: login_store,
        'user-management': require('./../../UserManagement/webcomponent/store').default
    },
    state: {
        inventory_keyword: ''
    },
    mutations: {
        UPDATE_INVENTORY_KEYWORD(state, value) {
            state.inventory_keyword = value.replace("/" , " ")
        }
    }
})


const router = new VueRouter({
    routes // short for `routes: routes`
})

Vue.use(VueAnalytics, {
    id: 'UA-138514942-1',
    router,
})

Vue.config.productionTip = false

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
