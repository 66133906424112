<template>
    <div class="section" v-if="reservation" >
        <div style="padding-bottom: 20px;">
<div class="notification animated faster is-radiusless" style="position: fixed; top: 0px; left:0px; z-index:99; width: 100%;"
    :class="notificationAnim">
    <div class="container">
        <button class="delete" @click="hideErrorNotification" style="float:right"></button>
        {{ notification.message }}
    </div>
</div>

<div style="padding-bottom:40px;">
    <div class="card">
        <div class="card-content">
            <steps :current_step="current_step" />
        </div>
    </div>
</div>
<div v-if="isLoading" class="section">
      <div class="notification">Loading...</div>
    </div>
    <div v-if="!isLoading">
        <h1 class="title is-5">Salesforce ID : {{reservation.salesforce_id}} <span class="tag is-pulled-right" :class="statusClass(reservation.status)">{{(reservation.status || '').toUpperCase()}}</span>
</h1>
<div class="columns">
    <div class="column is-3">
        <div class="card">
            <div class="card-content content">
                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Customer
                    </span> <br>
                    {{ reservation.customer }}
                </p>

                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Contact Person
                    </span> <br>
                    {{ reservation.contact_person }}
                </p>

                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Asset type
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.type }}</span>
                </p>

                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Bandwidth
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.bandwidth }}G</span>
                </p>

                <p v-if="reservation.service_no">
                    <span class="is-size-7 has-text-grey-lig">
                        Service No.
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.service_no }}</span>
                </p>

                <p v-if="reservation.revenue">
                    <span class="is-size-7 has-text-grey-lig">
                        Revenue
                    </span> <br>
                    <span class="is-capitalized">$USD {{ reservation.revenue.toLocaleString() }}</span>
                </p>

                <p v-if="reservation.contract_term">
                    <span class="is-size-7 has-text-grey-lig">
                        Contract term
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.contract_term | years }}</span>
                </p>

            </div>
        </div>
        <p style="margin-top:10px;">
            <span class="is-size-7 has-text-grey-light">Reserved by <b>{{reservation.reserved_by}}</b></span>
        </p>
    </div>

    <div class="column">
        <div v-for="(c,i) in reservation.capacities" :key="i" class="card">
            <div class="card-content">
                <h3 class="title is-7 has-text-grey-light is-marginless">{{ c.cable_system}}</h3>
                <h2>
                    <router-link :to="`/inventory/show/${c.inventory_id}`" class="title is-5">{{ c.route.sortedLabel
                        }}</router-link>
                </h2>

                <div class="field is-grouped is-grouped-multiline" style="margin-top:10px;">
                    <div class="control">
                        <div class="tags has-addons">
                            <span class="tag is-dark">Required Capacity</span>
                            <span class="tag is-info">{{ c.capacity.consumedQty }} {{ c.capacity.unitLabel }}</span>
                        </div>
                    </div>

                    <div class="control">
                        <div class="tags has-addons">
                            <span class="tag is-dark">Category</span>
                            <span class="tag is-info">{{ c.capacity.category.toUpperCase() }}</span>
                        </div>
                    </div>

                    <div class="control">
                        <div class="tags has-addons">
                            <span class="tag is-dark">Type</span>
                            <span class="tag is-info">{{ c.capacity.type.toUpperCase() }}</span>
                        </div>
                    </div>
                </div>

                <div v-if="c.capacity.selected_options" class="content" style="margin-top:10px;">
                    <p><span class="heading">{{  c.capacity.category == 'offnet' ? "Providers" : "BTN CCT IDS"}}</span></p>
                    <ul>
                        <li v-for="(total,label) in groupSelected(c.capacity.selected_options)" :key="label">
                            <span class="tags has-addons">
                                <span class="tag ">{{ label }}</span>
                                <span v-if="c.capacity.category == 'offnet'" class="tag has-background-grey-light has-text-white">{{  total }} {{ c.capacity.unitLabel }}</span>
                            </span>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr>

        <div v-if="$store.getters.capry_usercan('manage_reservation')" style="padding-bottom:10px;">    
            <confirm-order :reservation="reservation" @notification="showNotification" />
            <complete-order :reservation="reservation" @notification="showNotification" />
        </div>

        <a @click.prevent="showHistory = !showHistory" class="button is-size-7">{{ showHistory? "Hide" :
            "Show"}} history</a>
        <div class="card" v-if="showHistory == true" style="margin-top:20px;">
            <table class="table is-fullwidth">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>By</th>
                        <th>Event</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(log,i) in reservation.logs" :key="i">
                        <td>{{ log.date }}</td>
                        <td>{{ log.by }}</td>
                        <td>{{ log.event }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
    </div>
        </div>
    </div>
</template>


<script>
    import * as R from 'ramda'
    import Steps from './Steps.vue'
    export default {
        components: {
            Steps,
            ConfirmOrder: () => import("./ConfirmOrder"),
            CompleteOrder: () => import("./CompleteOrder")
        },
        data() {
            return {
                isLoading: true,
                showCompleteForm: false,
                showHistory: false,
                form: {
                    contract_term: '',
                    revenue: ''
                },
                notification: {
                    status: false,
                    message: '',
                    timer: false
                },
            }
        },
        mounted() {
            this.$store.dispatch('capry.reservations.view', this.salesforce_id).then(res => {
                this.isLoading = false
            })
        },
        methods: {
            groupSelected(list){
                return R.pipe(
                    R.groupBy(R.identity),
                    R.map(data => data.length)
                )(list || [])
            },
            statusClass(status) {
                return {
                    'is-success': status == 'completed',
                    'is-info': status == 'open',
                    'is-link': status == 'confirmed'
                }
            },
            showNotification(msg, type) {
                if (this.notification.timer) {
                    clearTimeout(this.notification.timer)
                }
                this.notification = {
                    status: 'show',
                    message: msg || 'Validation error. Please check the form',
                    timer: setTimeout(() => {
                        this.hideErrorNotification()
                    }, 3000),
                    type
                }
            },
            
            hideErrorNotification() {
                this.notification = {
                    status: 'hide'
                }
            },
        },
        computed: {
            current_step() {
                switch (this.reservation.status) {
                    case 'open':
                        return 2
                    case 'completed':
                        return 5
                    case 'confirmed':
                        return 3
                    case 'cancelled':
                        return 0
                }
            },
            notificationAnim() {
                if (!this.notification.status) {
                    return {
                        'hide': true
                    }
                }

                if (this.notification.status == 'show') {
                    return {
                        'slideInDown': true,
                        [this.notification.type || 'is-danger']: true
                    }
                }

                if (this.notification.status == 'hide') {
                    return {
                        'slideOutUp': true,
                        [this.notification.type || 'is-danger']: true
                    }
                }
            },
            salesforce_id() {
                return this.$route.params.id
            },
            reservation() {
                return this.$store.state.capry.currentReservation
            }
        },
        filters:{
            years(value){
                return `${value} ${value > 1 ? 'years' : 'year'}`
            }
        }
    }
</script>