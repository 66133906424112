<template>
  <div>
    <div v-if="!isLoading && !isSufficient" class="notification is-warning">
      Sorry. Insufficient capacity to convert the reservation
    </div>
    <div class="field">
      <div class="control">
        <span class="heading">Salesforce ID</span>
        <div class="input" disabled>{{ details.salesforce_id }}</div>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <span class="heading">Customer</span>
        <div class="input" disabled>{{ details.customer }}</div>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <span class="heading">Contact Person</span>
        <div class="input" disabled>{{ details.contact_person }}</div>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <span class="heading">Bandwidth</span>
        <div class="field has-addons">
          <div class="control is-expanded">
            <div class="input " disabled>{{ details.bandwidth }} Gbps</div>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <span class="heading">Contract </span>
      </div>
    </div>

    <div class="field">
      <span class="heading">Contract type</span>
      <div class="control">
        <div class="field has-addons">
          <div class="control is-expanded">
            <div class="button is-static is-fullwidth">{{ details.reservation_type | originalReservationType }}</div>
          </div>
          <div class="control">
            <div class="button is-static is-fullwidth">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
            </div>
          </div>
          <div class="control is-expanded">
            <div class="button is-static is-fullwidth">{{ details.reservation_type }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="field">
      <span class="heading">Asset type</span>
      <div class="control">
        <div class="field has-addons">
          <div class="control is-expanded">
            <div class="button is-static is-fullwidth">{{ details.type | originalAssetType  }}</div>
          </div>
          <div class="control">
            <div class="button is-static is-fullwidth">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
            </div>
          </div>
          <div class="control is-expanded">
            <div class="button is-static is-fullwidth">{{ details.type }}</div>
          </div>
        </div>
      </div>
    </div>

    <form @submit.prevent="submit" class="buttons">
      <button type="submit" class="is-primary button" :class="{'is-loading':loading}" :disabled="loading || isLoading || !isSufficient">Convert</button>
      <button type="button" class="button" @click="$emit('cancel')">Cancel</button>
    </form>
  </div>
</template>

<script>
import * as R from 'ramda'
export default {
  props:{
    form:{
      type: Object,
      default(){
        return {

        }
      }
    }
  },
  data(){
    return {
      loading: false
    }
  },
  methods:{
    async submit(){
        await this.$validator.validateAll();

        if(this.$validator.errors.any()){
            this.$emit('error', this.$validator.errors.items.length == 1 ? R.head(this.errors.all()) : null) 
        }else{
            this.loading = true;
            this.$store.dispatch('capry.reservations.convert', this.submission_form).then(res => {
                this.$emit('success', res.data.message) 
            }).catch(err => {
                this.$emit('error', err.response.data.message) 
            }).then(() => {
                this.loading = false;
            })
        }
    }
  },
  computed:{
    isLoading(){
      return this.form.capacities.length == 0
    },
    isSufficient(){
      return R.pipe(
        R.map( c => !c.isSufficient(this.form.bandwidth, this.form.type)),
        R.sum,
        d => d === 0
      )(this.form.capacities) && !this.isLoading
    },
    details(){
      return R.omit(['capacities'], this.form)
    },
    submission_form(){
        return Object.assign({},this.form, {
            capacities: this.form.capacities.map(capacity => {
                const unit = (capacity.selected || {}).unit || ''
                return {
                    inventory_id: capacity.id,
                    cable_system: capacity.cable_system,
                    route: capacity.route,
                    capacity: Object.assign({}, capacity.selected, {
                        consumedQty: !capacity.selected ? 0 : capacity.selected.consumedQty(this.form.bandwidth),
                        unitLabel: unit == 'miu' ? ' MIU' : ` x ${unit}`
                    }),
                }
            })
        })
    },
  },
  filters:{
    originalReservationType(v){
      return v == 'iru' ? 'lease' : 'iru'
    },
    originalAssetType(v){
      return v == 'asset' ? 'stock' : 'asset'
    }
  }
}
</script>