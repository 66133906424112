<template>
  <div>
    <table class="table is-hoverable is-fullwidth">
      <thead>
        <tr class="is-size-7">
          <th></th>
          <th>
            <a @click="sortField('cable_system')" href="javascript:void()">Cable System</a>
          </th>
          <th>
            <a @click="sortField('route')" href="javascript:void()">Route</a>
          </th>
          <th  class="has-text-right">
            Stock
          </th>
          <th  class="has-text-right">
            Asset
          </th>
          <th class="has-text-centered">
            <a class="button is-small" :class="{'is-loading': generatingExcel}" @click="downloadExcel">
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24" style="fill:currentColor;">
                    <path d="M20 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.1a1.1 1.1 0 0 0-.31-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9v-.06zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3zm-4.71-4.71l-.29.3V12a1 1 0 0 0-2 0v2.59l-.29-.3a1 1 0 0 0-1.42 1.42l2 2a1 1 0 0 0 .33.21.94.94 0 0 0 .76 0 1 1 0 0 0 .33-.21l2-2a1 1 0 0 0-1.42-1.42z"/>
                  </svg>
                </span>
            </a>
          </th>
        </tr>
      </thead>
      <tbody>
        <router-link :to="`inventory/show/${row.id}`" tag="tr" v-for="(row,i) in sortedData" :key="i" style="cursor:pointer;">
          <th class="has-text-grey-lighter">{{ i + 1 }}</th>
          <th>
              {{ row.cable_system}}
          </th>
          <td>
            {{ row.route.sortedLabel }}
          </td>
          <td class="has-text-right" style="width:120px">
            <span class="animated flash faster" :key="`${row.route.sortedLabel}-${(getTotal(row, 'stock') || 0)}`">{{ (getTotal(row, 'stock') || 0)  | addUnit(row.summary[0].unit) }}</span> <br>
          </td>
          <td class="has-text-right" style="width:120px">
            <span class="animated flash faster" :key="`${row.route.sortedLabel}-${(getTotal(row, 'asset') || 0)}`">{{ (getTotal(row, 'asset') || 0)  | addUnit(row.summary[0].unit) }}</span> <br>
          </td>
          <!-- <td style="width:80px">
              <capacity-breakdown v-if="showGraph" width="50" height="16" :breakdowns=row.capacity_breakdown />
              <span v-else class="button is-small is-loading is-borderless is-paddingless"  style="border:none;">loading...</span>
          </td> -->
          <td>
              <div class="popover is-popover-top" >
                <span class="popover-trigger is-small is-text">
                  <i>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg>
                  </i>
                </span>
                <div class="popover-content" style="max-width:500px">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Equiped Qty</th>
                        <th>Remaining Qty</th>
                        <th>Reservable Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(type,i) in row.summary" :key="i">
                        <td>{{type.label.toUpperCase()}}</td>
                        <td>{{ type.equiped_qty || '-' | formatUnit(type.unit) }}</td>
                        <td>{{type.qty | formatNumber}} {{type.unit  == 'miu' ? "MIU" : `x ${type.unit}`}}</td>
                        <td>{{ type.reservableQty  | formatNumber}} {{type.unit  == 'miu' ? "MIU" : `x ${type.unit}`}}</td>
                      </tr>
                      
                    </tbody>
                    <tfoot v-if="notAnyToAny(row)">
                      <tr>
                        <td>TOTAL</td>
                        <td>
                          {{ totalCapacity('equiped_qty', row.summary) }} Gbps
                        </td>
                        <td>
                          {{ totalCapacity('qty', row.summary) }} Gbps
                        </td>
                        <td>
                          {{ totalCapacity('reservableQty', row.summary) }} Gbps
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div>
                <span :class="hasReservation(row)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                </span>
              </div>
          </td>
        </router-link>
      </tbody>
    </table>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import * as R from "ramda";
import VueTable from "vuetable-2";
import { setTimeout } from 'timers';
import AnimatedNumber from "animated-number-vue"
import CapacityBreakdown from "./CapacityBreakdown.vue"
import {toGbps} from './../../../Models/CapacityCalc'

export default {
  components: {
    VueTable,
    CapacityBreakdown,
    AnimatedNumber,
  },
  props: ["data", "excludeReserved"],
  data() {
    return {
      sortBy: "route",
      sortAsc: true,
      showGraph: false,
      generatingExcel: false,
    };
  },
  mounted(){
    // setTimeout(() => this.showGraph = true, 200)
  },
  computed: {
    capacityColumn(){
      return this.excludeReserved ? 'total_reservable_capacity' : 'total_capacity'
    },
    sortedData() {
      let sorter

      if(this.sortBy == 'route'){
        sorter = data => {
          return R.pipe(
            R.sortBy(R.identity),
            R.head
          )([data.route.landing_points[0].dist,data.route.landing_points[1].dist])
        }
      }else{
        sorter = R.path(this.sortBy.split("."));
      }
        const Sort = this.sortAsc ? R.ascend(sorter) : R.descend(sorter);
      return R.sort(Sort, this.data || []);
    }
  },
  methods: {
    notAnyToAny(row){
      return row.route.label !== 'any → any'
    },
    totalCapacity(prop, summary){
      return R.pipe(
        R.map( data => ({
          qty: data[prop] || 0,
          unit: data.unit,
          total: toGbps(data[prop] || 0, data.unit)
        })),
        R.pluck('total'),
        R.sum,
        data => data.toFixed(2)
      )(summary)
    },
    hasReservation(row){
      const reservations = R.pipe(R.prop('summary'),R.map(data => data.reservableQty < data.qty), R.sum)(row)
      return reservations ? 'has-text-success' : 'has-text-grey-lighter'
    },
    navigate(id){
      this.$router.push(`inventory/show/${id}`)
    },
    getTotal(row, type){
      const total = R.pipe(
        R.groupBy(R.prop('type')),
        R.map(R.pluck(this.capacityColumn)),
        R.map(R.sum)
      )(row.summary)
      return total[type] || 0
    },
    sortField(path) {
        this.sortBy = path;
        this.sortAsc = !this.sortAsc
    },
    downloadExcel(){
      this.generatingExcel = true
      const cable_breakdowns = R.pipe(R.pluck('summary'),R.flatten, R.indexBy(R.prop('label')),R.map(() => 0))(this.data)
      const mapCapacity = list => {
        const keyed = R.pipe(R.indexBy(R.prop('label')), R.map(R.prop('qty')))(list)
        return Object.assign({}, cable_breakdowns, keyed)
      }
      const mapCableRoutes = routes => {
        return routes.map(route => {
          return {
            cable_system: route.cable_system,
            route: route.route.sortedLabel,
            ...mapCapacity(route.summary)
          }
        })
      }

      const prepareData = R.pipe(
        R.groupBy(R.prop('PK')),
        R.map(mapCableRoutes),
        R.values,
        R.flatten,
      )
      
      const outputData = prepareData(this.data) 
      setTimeout(() => this.exportExcel(outputData), 1000)  
    },
    exportExcel(datas){
      
      const ws = XLSX.utils.json_to_sheet(datas)
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Export");
      XLSX.writeFile(wb, "export.xlsx");
      this.generatingExcel = false
    },
  },
  filters:{
    formatNumber(value){
      return value.toLocaleString('en-US')
    },
    addUnit(value, unit){
      if(unit == 'miu'){
        return value.toLocaleString() + ' Miu'
      }else{
        return `${value} Gbps`
      }
    },
    formatUnit(value,unit){
      if(value == '-'){
        return '-'
      }
      return unit == 'miu' ?`${value} MIU` : `${value} x ${unit}`
    }
  }
};
</script>

<style lang="scss">
@import url('~bulma-popover/css/bulma-popover.css');
</style>
