<template>
    <div>
        <div v-if="!loaded" class="has-text-centered">
            <span class="button is-white is-loading"></span>
        </div>
        <div v-else-if="loaded && reservation">
            <span class="heading">Reservation</span>
            <router-link :to="`/reservations/show/${reservation.PK}`" class="title is-5 has-text-link">{{ reservation.PK }}</router-link>
            <span class="help">{{ reservation.customer }}</span>
        </div>
        <div v-else>

        </div>
    </div>
</template>

<script>
export default {
    props:['transaction'],
    data(){
        return {
            reservation: false,
            loaded: false,
        }
    },
    computed:{
        reservationId(){
            const remarks = this.transaction.remarks
            const reg = /^Activation for reservation (.*)$/
            if(!remarks.match(reg)){
                return ''
            }
            
            return remarks.replace(reg,"$1")
        }
    },
    mounted(){
        this.loadReservation()
    },
    methods:{
        loadReservation(){
            if(!this.reservationId){
                this.loaded = true
            }

            this.$store.dispatch('capry.reservation.query',this.reservationId).then(reservation => {
                this.reservation = reservation
                this.loaded = true
            })
        }
    }
}
</script>

