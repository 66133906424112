<template>
    <div class="field is-grouped is-grouped-multiline">
        <input data-vv-as="Capacity" type="hidden" v-model='selected' v-validate="'required'" :name="selectedName">
        
        <div v-for="(summary,i) in filteredSummary" :key="i" class="control">
            <a  @click="selectRoute(summary)" class="tags has-addons">
                <span class="tag" :class="isSelectedClass(summary)[0]"><input type="checkbox" :checked="isSelected(summary)"></span>
                <span class="tag" :class="isSelectedClass(summary)[0]">{{ `${summary.category.toUpperCase()}-${summary.unit.toUpperCase()}-${summary.type.toUpperCase()}` }}</span>
                <span class="tag" :class="isSelectedClass(summary)[1]">{{ `${(summary.total_reservable_capacity || 0).toFixed(1)}Gbps`  }} / {{ `${(summary.total_capacity || 0).toFixed(1)}Gbps`  }} </span>
            </a>
        </div>
        <span class="is-size-7 has-text-danger">{{ errors.first(selectedName) }}</span>
        <div class="control">
            <span class="is-size-7" v-if="filteredSummary.length == 0">NO SUFFICIENT CAPACITY <a @click.prevent="$emit('remove',id)">[REMOVE]</a> </span>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
export default {
    inject: ['$validator'],
    props:['capacity','type','bandwidth','id', 'consumed'],
    computed:{
        filteredSummary(){
            return R.pipe(R.values, R.filter( summary => this.isSufficient(summary) && summary.type == this.type))(this.capacity.summary)
        },
        selected(){
            return this.capacity.selected || ''
        },
        selectedName(){
            return this.capacity.id
        }
    },
    methods:{
        selectRoute(summary){
            this.capacity.selected = summary
        },
        isSufficient(summary){
            return summary.isSufficient(this.bandwidth, this.consumed, true)
        },
        isSelected(summary){
            return this.capacity.selected == summary
        },
        isSelectedClass(summary){
            return [
                {
                    'is-dark' : this.isSelected(summary),
                    'has-background-grey-light' : !this.isSelected(summary),
                },
                {
                    'is-link' : this.isSelected(summary),
                    'has-background-grey-lighter' : !this.isSelected(summary),
                },
            ]
        }
    }
}
</script>

