<template>
    <div class="container" style="padding-top:40px;">
        <div class="section">
            <div class="box">
                
                <div class="columns">
                    <div class="column">
                        <h1 class="title is-5">Path Finder (BETA)</h1>
                        <div v-if="notification" class="notification">
                            <a @click="notification = false" class="delete is-pulled-right"></a>
                            <h3 class="title">Notice</h3>
                            <p>This is experimental feature for routing algorightm. If you notice and error, appreciate if you could feedback to me directly at <b>faisal.arbain@tm.com.my</b></p>
                            <hr>
                            <div class="content">
                                <h4 class="title is-6">Change logs</h4>
                                <ul>
                                    <li><strong>9th July 2019</strong> Split routing for Asset and Stock</li> 
                                </ul>
                            </div>

                        </div>
                        <div v-if="!loaded" class="hero">
                            <div class="hero-body has-text-centered" style="width: 100%">
                                <span class="button is-white is-loading"></span>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="!offerings" class="columns is-centered">
                                <div class="column is-4">
                                    <div class="box" style="height:100%">
                                        <label for="#">Asset type:</label>
                                        <div class="buttons has-addons">
                                            <a @click="capacity_type = 'asset'" class="button" :class="{'is-info': capacity_type == 'asset'}" style="width:50%;">Asset</a>
                                            <a @click="capacity_type = 'stock'" class="button" :class="{'is-info': capacity_type == 'stock'}" style="width:50%;">Stock</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-6"  v-if="capacity_type">
                                    <div class="box" style="height:100%">
                                        <div class="field is-grouped">
                                            <div class="control is-expanded">
                                                <label for="#">From:</label>
                                                <div class="select is-fullwidth">
                                                    <select v-model="from">
                                                        <option v-for="(country, i) in getCountries()" :key="i" :value="country">{{country}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="control is-expanded">
                                                <label for="#">To:</label>
                                                <div class="select is-fullwidth">
                                                    <select v-model="to">
                                                        <option v-for="(country, i) in getCountries(from)" :key="i" :value="country">{{country}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-2 "  v-if="capacity_type && from && to">
                                    <div class="control">
                                        <a @click="calculate" class="button is-primary" style="height:100%;">Calculate</a>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <a @click="offerings = false" class="button is-primary">&laquo; Back</a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div v-if="offerings" class="box">
                    <offering-details :segments="segments" :offering="offerings" :pathfinder="routes" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import OfferingDetails from './OfferingDetails'
import PathFinderService from './PathFinderService'

export default {
    components:{
        OfferingDetails,
    },
    data(){
        return {
            notification: true,
            routes_asset: false,
            routes_stock: false,
            from: '',
            to: '',
            capacity_type:'',
            offerings: false,
            loaded: false
        }
    },
    mounted(){
        this.loadData()
    },

    computed:{
        routes(){
            return this[`routes_${this.capacity_type}`]
        },
        segments(){
            return this.routes.segments()  
        },
        miu_routes(){
            return this.$store.state.capry.miu_routes
        },
        
        capacities(){
            return this.$store.state.capry.routes
        },
    },
    methods:{
        calculate(){
            this.offerings = this.routes.calculate(this.from, this.to)
        },
        getCountries(start = ''){
            return this.routes.getCountries(start)
        },
        loadData(){
            Promise.all([
                this.$store.dispatch('capry.load.miuroutes'),
                this.$store.dispatch('capry.loadRoutes')
            ]).then(() => {
                this.routes_asset = PathFinderService(this.capacities, this.miu_routes, 'asset')           
                this.routes_stock = PathFinderService(this.capacities, this.miu_routes, 'stock')           
                this.loaded = true
            })
        },
    }
}
</script>
