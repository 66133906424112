<template>
    <div>
        <div class="box">
            <div class="box is-paddingless">
                <div class="column" style="border-bottom: solid 1px #ccc;">
                    <a v-if="capacites.stock > 0" @click="viewOffering(offeringResult.stock)" class="button is-pulled-right has-icon is-small">
                        <i class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M7 17l9.2-9.2M17 17V7H7"/></svg>
                        </i>
                    </a>
                    <span class="heading">STOCK</span>
                    <h1 class="title is-4">{{ capacites.stock }} GB</h1>
                    <div class="tags">
                        <span v-for="(name,label) in getCableName(offeringResult.stock )" :key="label" class="tag is-info tooltip is-tooltip-left" :data-tooltip="name">
                            {{ label }}
                        </span>
                    </div>
                    
                </div>
                <div class="column">
                    <a v-if="capacites.asset > 0" @click="viewOffering(offeringResult.asset)" class="button is-pulled-right has-icon is-small">
                        <i class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M7 17l9.2-9.2M17 17V7H7"/></svg>
                        </i>
                    </a>
                    <span class="heading">ASSET</span>
                    <h1 class="title is-4">{{ capacites.asset }} GB</h1>
                    <div class="tags">
                        <span v-for="(name,label) in getCableName(offeringResult.asset )" :key="label" class="tag is-info tooltip is-tooltip-left" :data-tooltip="name">
                            {{ label }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'

const cableMapping = require('./cableMapping')


export default {
    props: ['offeringResult'],
    data(){
        return {
            selectedOffering: false
        }
    },
    computed:{
        capacites(){
            return {
                stock: R.view(R.lensPath(['stock','capacity']), this.offeringResult) || 0,
                asset: R.view(R.lensPath(['asset','capacity']), this.offeringResult) || 0,
            }
        },
    },
    methods:{
        getCableName(offering){
            return R.pipe(
                R.prop('groupRoutes'),
                R.keys,
                R.map(str => {
                    const cable = str.replace(/,.*/, '')
                    const label = cableMapping[cable] || cable
                    return {
                        [label]: cable
                    }
                }),
                R.mergeAll
            )(offering)
        },
        viewOffering(type_offering){
            const cables = this.getCableName(type_offering)
            this.selectedOffering = R.pipe(
                R.prop('groupRoutes'),
                R.toPairs,
                R.groupBy(data => data[0].replace(/,.*/,'')),
                data => {
                    return R.mapObjIndexed((cable, cable_abbrv) => {
                        return {
                            cable_abbrv,
                            cable,
                            routes: R.map(x => x[1],data[cable])
                        }
                    },cables)
                },
                routes => ({
                    total_capacity:  type_offering.capacity,
                    package: type_offering.name,
                    routes
                })
            )(type_offering)
            
            // console.log(this.selectedOffering, cables)
            this.$emit('offeringSelected', this.selectedOffering)
        }
    }
    
}
</script>