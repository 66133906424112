<template>
    <div class="steps">
        <div class="step-item" :class="isCompletedClass(1)">
            <a class="step-marker" @click="$emit('change', 1)">
            <span class="icon">
                <svg v-if="isCompleted(1) && confirmed_order" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                </svg>
            </span>
            </a>
            <div class="step-details">
            <p class="step-title">Order Confirmation</p>
            <p>Customer has confirmed for order and has provided SOFCA form</p>
            </div>
        </div>

        <div class="step-item" :class="isCompletedClass(2)">
            <div class="step-marker">
                <span class="icon" v-if="isCompleted(2)">
                    <svg v-if="isCompleted(1) && confirmed_order" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                    </svg>
                </span>
            </div>
            <div class="step-details">
            <p class="step-title">Capacity Reservation</p>
            <p>Reserve Capacity for the order provisioning </p>
            </div>
        </div>

        <div class="step-item" :class="isCompletedClass(3)">
            <div class="step-marker">
                <span class="icon" v-if="isCompleted(3)">
                    <svg v-if="isCompleted(1) && confirmed_order" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                    </svg>
                </span>
            </div>
            <div class="step-details">
            <p class="step-title">Order Provisioning</p>
            <p>Order captured and is provision by GOM</p>
            </div>
        </div>

        <div class="step-item" :class="isCompletedClass(4)">
            <div class="step-marker">
            <span class="icon" v-if="isCompleted(4)">
                    <svg v-if="isCompleted(1) && confirmed_order" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                    </svg>
                </span>
            </div>
            <div class="step-details">
            <p class="step-title">Capacity Activated</p>
            <p>Utilized capacity will be deducted from inventory</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['current_step','confirmed_order'],
    methods:{
        isCompleted(step){
            return this.current_step > step
        },
        isCompletedClass(step){
            return {
                'is-completed': this.isCompleted(step) && this.confirmed_order,
                'is-active': this.current_step == step,
                
            }
        }
    }
}
</script>

<style lang="scss">
@import './../../../../utils/styles/tm.scss';
@import './../../../../node_modules/bulma-steps/src/sass/index.sass';
</style>
