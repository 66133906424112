<template>
  <div class="container">
    <reservation-history v-if="currentDetail" @close="currentDetail = false" :reservations="currentDetail" />
    <div v-if="isLoading" class="section">
        <loading-screen />
      <div class="notification">Loading...</div>
    </div>
    <div v-if="!isLoading && !capacity" class="section">
        <div class="notification ">
            <h3 class="title is-4">RECORD NOT FOUND</h3>
        </div>
    </div>
    <div v-if="!isLoading && capacity" class="section">
       <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li>
                        <a href="#/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" style="fill:currentColor;"/>
                        </svg>
                        </a>
                    </li>
                    <li><a href="#/inventory">Inventory</a></li>
                    <li class="is-active"><a href="">{{ capacity.cable_system }} - {{ capacity.route.sortedLabel }}</a></li>
                </ul>
            </nav>
    <div class="columns">
        <div class="column is-12">
            <h1 class="title is-3">{{ capacity.cable_system }}</h1>
            <h2 class="title is-5">{{ capacity.route.sortedLabel}}</h2>
          </div>
    </div>
    
      <div class="columns">
          <div class="column is-9">
              <div class="columns is-multiline">
                <div class="column is-6" v-for="(category,i) in filteredCapacityCategories" :key="i">
                <capacity-card :category="category" :capacity="capacity" :editable="$store.getters.capry_usercan('manage_capacity')"  /> 
                </div>
            </div>
          </div>
          <div class="column">
            <p class="menu-label">
                Filter tags
            </p>
              <div class="tags">
                  <a @click.prevent="tags['Apollo'] = !tags['Apollo']" class="tag" :class="{'is-info' : tags['Apollo']}">Apollo</a>
                  <a @click.prevent="tags['Onnet'] = !tags['Onnet']" class="tag" :class="{'is-info' : tags['Onnet']}">Onnet</a>
                  <a @click.prevent="tags['Offnet'] = !tags['Offnet']" class="tag" :class="{'is-info' : tags['Offnet']}">Offnet</a>
              </div>
              <div class="tags">
                  <a @click.prevent="tags['Stock'] = !tags['Stock']" class="tag" :class="{'is-info' : tags['Stock']}">Stock</a>
                  <a @click.prevent="tags['Asset'] = !tags['Asset']" class="tag" :class="{'is-info' : tags['Asset']}">Asset</a>
              </div>
              <div class="tags">
                  <a @click.prevent="tags['10Gbps'] = !tags['10Gbps']" class="tag" :class="{'is-info' : tags['10Gbps']}">10Gbps</a>
                  <a @click.prevent="tags['100Gbps'] = !tags['100Gbps']" class="tag" :class="{'is-info' : tags['100Gbps']}">100Gbps</a>
                  <a @click.prevent="tags['STM1'] = !tags['STM1']" class="tag" :class="{'is-info' : tags['STM1']}">STM1</a>
                  <a @click.prevent="tags['STM16'] = !tags['STM16']" class="tag" :class="{'is-info' : tags['STM16']}">STM16</a>
                  <a @click.prevent="tags['STM64'] = !tags['STM64']" class="tag" :class="{'is-info' : tags['STM64']}">STM64</a>
              </div>
                <a @click.prevent="selectAllTags" href="javascript:void()" class="is-size-7">Select All</a>

                <hr>
              <div class="buttons" v-if="$store.getters.capry_usercan('manage_capacity')">
                  <a @click.prevent="addMoreCapacity" class="button is-primary is-fullwidth">Add/Remove Capacity</a>
              </div>
          </div>
      </div>
        <hr>
        <cable-information :cable=capacity.GS2 :route=capacity.route :editable="$store.getters.capry_usercan('manage_capacity')" />
        <hr>
            <div class="columns">
                <div class="column">
                    <div class="tabs is-boxed">
                        <ul>
                            <li :class="{ 'is-active' : tab == 'reservations'}"><a @click="tab = 'reservations'" >Reservations &nbsp;<span class="tag">{{ reservations.length }}</span></a></li>
                            <li :class="{ 'is-active' : tab == 'transactions'}"><a @click="tab = 'transactions'">Transactions &nbsp;<span class="tag">{{ capacityTransactions.length }}</span></a></li>
                            <li :class="{ 'is-active' : tab == 'apollo'}"><a @click="tab = 'apollo'">Apollo &nbsp;<span class="tag">{{apollos.length}}</span></a></li>
                            <li :class="{ 'is-active' : tab == 'offnet'}"><a @click="tab = 'offnet'">Offnet &nbsp;<span class="tag">{{offnets.length}}</span></a></li>
                        </ul>
                    </div>

                    <div v-if="tab == 'apollo'">
                        <h2 class="title is-4">Apollo</h2>
                        <div v-if="apollos.length == 0" class="hero is-light">
                            <div class="hero-body has-text-centered has-text-grey-light">
                                <span class="title is-3 has-text-grey-light">No Apollo circuit</span>
                            </div>
                        </div>
                        <table v-else class="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>BTN CCT ID</th>
                                    <th>Phase</th>
                                    <th>Unit</th>
                                    <th>Route</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(apollo,i) in apollos" :key="i">
                                    <td>{{i + 1}}</td>
                                    <td>{{apollo.btn_cct_id}}</td>
                                    <td>{{apollo.phase}}</td>
                                    <td>{{apollo.unit}}</td>
                                    <td>{{apollo.route.sortedLabel}}</td>
                                    <td><a @click="viewDetail(apollo)" class="tag" :class="{
                                        'is-info': apollo.status == 'Free',
                                        'is-dark' : apollo.status == 'In used',
                                        'is-danger' : apollo.status == 'ERROR'
                                    }">{{ apollo.status}}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="tab == 'offnet'">
                        <h2 class="title is-4">Offnet</h2>
                        <div v-if="offnets.length == 0" class="hero is-light">
                            <div class="hero-body has-text-centered has-text-grey-light">
                                <span class="title is-3 has-text-grey-light">No Offnet circuit</span>
                            </div>
                        </div>
                        <table v-else class="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Provider</th>
                                    <th>OCM Reference #</th>
                                    <th>Contract start date</th>
                                    <th>Contract years</th>
                                    <th class="has-text-centered">Total qty</th>
                                    <th class="has-text-centered">Remaining qty</th>
                                    <th>Unit</th>
                                    <th class="has-text-right">IRU Cost</th>
                                    <th class="has-text-right">O&amp;M</th>
                                    <th class="has-text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(offnet,i) in offnets" :key="i" >
                                    <td> <p class="text-grey is-size-7">{{offnet.provider}}</p></td>
                                    <td>{{ offnet.ocm  }}</td>
                                    <td>{{ offnet.contract_start_date | formatContractStart  }}</td>
                                    <td>{{ offnet.contract_years }}</td>
                                    <td class="has-text-centered">{{ offnet.total_qty }}</td>
                                    <td class="has-text-centered">{{ offnet.remaining_qty }}</td>
                                    <td>{{ offnet.unit }}</td>
                                    <td class="has-text-right">{{ offnet.iru_cost  | currency}}</td>
                                    <td class="has-text-right">{{ offnet.onm | currency}}</td>
                                    <td class="has-text-right">
                                        <a @click="viewDetail(offnet)" class="has-text-right">
                                            <span v-if="offnet.remaining_qty > 0" class="tag is-info">Available</span>
                                            <span v-else class="tag is-dark">Unavailable</span>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="tab == 'reservations'">
                        <div class="level">
                            <div class="level-start">
                                <h3 class="title is-5">Reservations</h3>
                            </div>
                            <div class="level-end">
                                
                            </div>
                        </div>
                        <div v-if="reservations.length == 0" class="hero is-light">
                            <div class="hero-body">
                                <div class="has-text-centered has-text-grey-light">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" viewBox="0 0 462.035 462.035" style="fill:currentColor;" >
                                        <path d="M457.83 158.441c-.021-.028-.033-.058-.057-.087l-50.184-62.48c-.564-.701-1.201-1.305-1.879-1.845-2.16-2.562-5.355-4.225-8.967-4.225H65.292c-3.615 0-6.804 1.661-8.965 4.225-.678.54-1.316 1.138-1.885 1.845l-50.178 62.48c-.023.029-.034.059-.057.087C1.655 160.602 0 163.787 0 167.39v193.07c0 6.5 5.27 11.771 11.77 11.771h438.496c6.5 0 11.77-5.271 11.77-11.771V167.39c.001-3.603-1.655-6.788-4.206-8.949zm-49.314-23.826l16.873 21.005h-16.873v-21.005zm-23.541-21.27v42.274H296.84c-2.514 0-4.955.805-6.979 2.293l-58.837 43.299-58.849-43.305c-2.023-1.482-4.466-2.287-6.978-2.287H77.061v-42.274h307.914zM53.523 155.62H36.65l16.873-21.005v21.005zm384.975 193.07H23.54V179.16h137.796l62.711 46.148c4.15 3.046 9.805 3.052 13.954-.005l62.698-46.144h137.799V348.69z"/>
                                    </svg>

                                    <h1 class="title has-text-grey-light">
                                        No open reservation
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-else>
                            <table class="table is-fullwidth is-hoverable" >
                                <thead>
                                    <tr>
                                        <th>Salesforce ID</th>
                                        <th>Route</th>
                                        <th>Qty</th>
                                        <th>Category</th>
                                        <th>Type</th>
                                        <th>Contact Person</th>
                                        <th>Customer</th>
                                        <th>Reserved by</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(reservation) in reservations" :key="reservation.salesforce_id">
                                        <th>
                                            <router-link :to="`/reservations/show/${reservation.salesforce_id}`">{{reservation.salesforce_id}}</router-link>
                                        </th>
                                        <td>{{ reservation.route.label }}</td>
                                        <td>{{ reservation.qty | formatNumber}} {{ reservation.unit | unitLabel }}</td>
                                        <td>{{ reservation.category.toUpperCase()}}</td>
                                        <td>{{ reservation.type.toUpperCase() }}</td>
                                        <td>{{ reservation.contact_person }}</td>
                                        <td>{{ reservation.customer }}</td>
                                        <td>{{ reservation.reserved_by }}</td>
                                        <td>
                                            <span class="tag is-info">
                                                {{ reservation.status.toUpperCase() }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div v-if="tab == 'transactions'" id="transactions">
                    <div class="level">
                        <div class="level-start">
                            <h3 class="title is-5">Transactions</h3>
                        </div>
                        <div class="level-end">
                            <div>
                                <input v-model="qfilter" type="text" class="input is-rounded" placeholder="filter">
                            </div>
                        </div>
                    </div>
                    <div class="card" v-for="(transaction,i) in capacityTransactions" :key=i>
                        <div class="card-content">
                            <div class="columns">
                                <div class="column is-3">
                                    <div>
                                        <span>{{ transaction.remarks }}</span>
                                        <span class="heading"><span class="has-text-grey-light">Added on</span> {{ transaction.creationDate | date }} <span class="has-text-grey-light">by</span> {{ transaction.created_by || 'System' }}</span>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="columns">
                                        <div class="column">
                                            <div>
                                                <span class="heading">QTY</span>
                                                <span class="title is-5">{{ transaction.qty | formatNumber }} {{ transaction.unit == 'miu' ? 'Miu' : ` x ${transaction.unit}` }}</span>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div>
                                                <span class="heading">Type</span>
                                                <span class="title is-5 is-capitalized">{{ transaction.type }}</span>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <div>
                                                <span class="heading">Category</span>
                                                <span class="title is-5 is-capitalized">{{ transaction.category }}</span>
                                            </div>
                                        </div>
                                        <div class="column is-4">
                                            <div v-if="transaction.category == 'apollo'">
                                                <span class="heading">BTN CCT ID</span>
                                                <span class="title is-5 is-capitalized">{{ transaction.meta.btn_cct_id }}</span>
                                            </div>
                                            <div v-if="transaction.category == 'offnet'">
                                                <span class="heading">PROVIDER</span>
                                                <span class="title is-5 is-capitalized">{{ transaction.meta.provider }}</span>
                                            </div>

                                            <div v-if="transaction.unit == 'miu'">
                                                <span class="heading">Route</span>
                                                <span class="title is-5 is-capitalized">{{ (transaction.meta.route||{}).label || '-' }}</span>
                                            </div>
                                        </div>
                                        <div class="column">
                                            <reservation-detail :transaction="transaction" />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            </div>
      </div>
      
    </div>
  
</template>


<script>
const R = require("ramda");
const moment = require('moment-timezone')
const toGbps = require('./../../../Models/CapacityCalc.js').toGbps
const Route = require('./../../../Models/Route.js').Route
import CableInformation from './CableInformation.vue'
import CapacityCard from './CapacityCard.vue'
import LoadingScreen from './../LoadingScreen.vue'
import ReservationHistory from './ReservationHistory.vue'
import ReservationDetail from './ReservationDetail'

const axios = require('axios')

export default {
    components:{
        CableInformation,
        CapacityCard,
        LoadingScreen,
        ReservationHistory,
        ReservationDetail,
    },
    data(){
        return {
            tags: {
                'Apollo': true,
                'Onnet': true,
                'Offnet': true,
                'Stock': true,
                'Asset': true,
                '10Gbps': true,
                '100Gbps': true,
                'STM1': true,
                'STM16': true,
                'STM64': true,
            },
            additionalInfo: {},
            tab: 'reservations',
            isLoading: true,
            qfilter: '',
            currentDetail: false,
        }
    },
    mounted(){
        this.tab = this.routerQuery
        const id = this.$route.params.id.toLowerCase()
        this.$store.dispatch('capry.loadRoute', id).then(() => {
            this.isLoading = false
        })

        this.$store.dispatch('capry.summarize', {PK: id})
    },
    methods:{
        viewDetail(apollo){
            const reservations = apollo.reservations
            this.currentDetail = reservations
        },
        getTotalReserved(label){
            return this.consolidatedReservations[label] || 0
        },
        selectAllTags(){
            this.tags = R.map(data => true, this.tags)
        },
        deselectAllTags(){
            this.tags = R.map(data => false, this.tags)
        },
        btnCctIds(str){
            return str.split(',')
        },
        addMoreCapacity(){
            this.$router.push({ path: '/inventory/add', query: {
                cable_system: this.capacity.cable_system,
                landing_points: this.capacity.items[0].landing_points
            } })
        },
        hasAdditionalInfo(category){
            return ['apollo', 'offnet'].indexOf(category.category) > -1
        },
        showAdditionalInfo(category){
            this.additionalInfo = category
        }
    },
    watch:{
        tab(value){
            this.$router.push({ query: { tab:value }})
        },
        routerQuery(value){
            this.tab = value
        }
    },
  computed: {
    routerQuery(){
        return this.$route.query.tab || 'reservations'
    },
    offnets(){
        const process = R.pipe(
            R.filter(data => data.GS1 == 'offnet'),
            R.groupBy(data => `${data.GS2}-${data.unit}`),
            R.map(items => {
                const item = R.last(items)
                const total_qty = R.pipe(R.filter(data => data.qty > 0),R.pluck('qty'), R.sum)(items)
                const total_used = (R.pipe(R.filter(data => data.qty < 0),R.pluck('qty'), R.sum)(items)) * -1
                const remaining_qty = total_qty - total_used

                const reservations = R.pipe(
                    R.filter(record => record.remarks.indexOf('Activation for reservation ') == 0),
                    R.map(rec => ({
                        salesforce_id: rec.remarks.replace('Activation for reservation ', ''),
                        lastUpdateDate: rec.lastUpdateDate,
                    }))
                )(items)

                return Object.assign({},item,{
                    provider: item.GS2,
                    total_qty,
                    total_used,
                    remaining_qty,
                    reservations,
                })
            }),
            R.values
        )
        return process(this.capacity.items)
    },
    apollos(){
        const statuses = {
            0: 'In used',
            1: 'Free'
        }

        const process = R.pipe(
            R.filter(data => data.GS1 == 'apollo'),
            R.groupBy(R.prop('GS2')),
            R.map(items => {
                const item = R.find(item => item.meta.phase ,items)
                const qty = R.sum(R.pluck('qty', items))

                const reservations = R.pipe(
                    R.filter(record => record.remarks.indexOf('Activation for reservation ') == 0),
                    R.map(rec => ({
                        salesforce_id: rec.remarks.replace('Activation for reservation ', ''),
                        lastUpdateDate: rec.lastUpdateDate,
                    }))
                )(items)

                return {
                    btn_cct_id: item.GS2,
                    phase: item.meta.phase,
                    cable_system: item.cable_system,
                    unit: item.unit,
                    qty,
                    route: new Route(item.landing_points),
                    status: statuses[qty] || 'ERROR',
                    reservations,
                }
            }),
            R.values
        )

        return process(this.capacity.items)
    },
    filterTags(){
        return R.pipe(R.filter(data => !data), R.keys, R.join("|"))(this.tags)
    },
    filteredCapacityCategories(){
        if(this.filterTags == ''){
            return this.capacityCategories
        }

        const reg = new RegExp(`(${this.filterTags})`, 'gi')
        return R.filter(data => !R.test(reg,JSON.stringify(data)),this.capacityCategories)
    },
    capacityCategories() {
        return this.capacity.summary; 
    },
    filteredTags(){
        if(this.filterTags == ''){
            return this.capacity.items
        }

        const reg = new RegExp(`(${this.filterTags})`, 'gi')
        return R.filter(data => !R.test(reg,JSON.stringify(data)),this.capacity.items)
    },
    filteredCapacityTransactions(){
        return this.filteredTags.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.qfilter.toLowerCase()) > -1)
    },
    capacityTransactions() {
        const sortTransactions = R.sort(R.descend( R.prop('creationDate')));
        return sortTransactions(this.filteredCapacityTransactions)
    },
    capacity() {
        return this.$store.state.capry.currentRoute
    },
    capacities() {
      return []//this.$store.getters.capry_capacitySummary;
    },
    reservations(){
        return this.capacity.reservations
    },
    consolidatedReservations(){
        return R.pipe(
            R.groupBy(data => data.capacity.label),
            R.map(R.pipe(
                R.map(data => data.capacity.consumedQty),
                R.sum
            ))
        )(this.reservations)
    }
  },
  filters:{
      formatNumber(value){
          if(!value){
              return "-"
          }
          return value.toLocaleString('en-US')
      },
      date(value){
          return moment(value).format('DD MMM YYYY hh:mm A')
      },
      currency(value){
          if(!value){
              return
          }
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD'})
    },
    formatContractStart(value){
        if(!value){
            return value
        }
        return `${value.month || ''} ${value.year || ''}`
    },
    unitLabel(value){
        if(value == 'miu'){
            return 'MIU'
        }

        return `x ${value}`
    }
  }
};
</script>


<style>
.if-hover{
    opacity: 0;
}

tr:hover .if-hover{
    opacity: 1;
}

</style>