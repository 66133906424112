<template>
  <div>
      <apexchart :height="400" type="bar" :options="options" :series="series || []"></apexchart>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts'
import * as R from 'ramda'
export default {
  props:['utilizationData'],
  components:{
    apexchart,
  },
  computed:{
    cables(){
      return R.pluck('cable',this.formattedData)
    },
    options(){
      const thiss = this;
      return {
        chart: {
          id: 'utilization-chart',
          stacked: true,
          stackType: '100%'
        },
        xaxis: {
          categories: this.cables,
          labels: {
            rotateAlways: true,
            // rotate: -90,
            // trim: false,
          }
        },
        legend:{
          position: 'top'
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px'
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45
          }
        },
        tooltip:{
          shared: true,
          followCursor: true,
          onDatasetHover:{
            highlightDataSeries: false
          },
          y:{
            formatter(x,{dataPointIndex}){
              const unit = thiss.indexedFormatted[thiss.cables[dataPointIndex]].display.unit
              const unitd = {
                miu: 'Miu',
                gb: 'GB'
              }
              return `${x.toLocaleString()} ${unitd[unit]}`
            }
          }
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              height: thiss.height || 600
            },
            legend: {
              position: 'top'
            },
            dataLabels: {
              style: {
                fontSize: '14px'
              },
              offsetX: -10
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top'
                }
              }
            }
          }
        }]
      }
    },
    series(){
      const displays = R.pluck('display', this.formattedData)
      return [
        { name: 'Utilized', data: R.pluck('activated', displays)},
        { name: 'Unutilized', data: R.pluck('total_remaining', displays)},
      ]
    },
    indexedFormatted(){
      return R.indexBy(R.prop('cable'), this.formattedData)
    },
    formattedData(){
      return R.pipe(
        R.toPairs,
        R.map(data => ({
          cable: data[0],
          miu: R.omit(['records'],data[1].miu),
          gb: R.omit(['records'],data[1].gb)
        })),
        R.map(data => ({
          ...data,
          display: data.miu.total_remaining ? {...data.miu, unit: 'miu'} : {...data.gb, unit: 'gb'}
        })),
        R.filter(data => data.display.utilization),
        R.sortBy(data => data.display.utilization),
      )(this.utilizationData)
    }
  }
}
</script>