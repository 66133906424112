'use strict'

import axios from 'axios'
import * as R from 'ramda'
import Contract from './../Contract'
import Asset from './../Asset'
import Service from './../Service';

export default {
    state: {
        ready: false,
        assets: [],
        services: [],
        contracts: [],
        phases: [],
        customers: [],
        current_data: null
    },
    getters: {
        apollo_totalCost(state) {
            return (filter, daterange) =>
                R.pipe(
                    R.filter(filter || R.identity),
                    R.map(contract => contract.totalCost(daterange)),
                    R.sum
                )(state.contracts);
        },
        apollo_totalRevenue(state) {
            return (filter, map) =>
                R.pipe(
                    R.filter(filter || R.identity),
                    R.map(map || (contract => contract.totalRevenue())),
                    R.sum
                )(state.contracts);
        },
        apollo_phases(state) {
            return R.pipe(
                R.pluck('phase'),
                R.uniq,
                R.sortBy(R.identity)
            )(state.contracts);
        },
        apollo_contractYearRange(state) {
            return R.pipe(
                R.map(
                    R.pipe(
                        R.pick(['contract_start_date', 'contract_end_date']),
                        R.values
                    )
                ),
                R.flatten,
                R.sortBy(R.identity),
                list => [R.head(list), R.last(list)],
                R.map(date => date?date.format('YYYY') : "")
            )(state.contracts);
        },
        apollo_totalCapacity(state) {
            return filter =>
                R.pipe(
                    R.filter(filter || R.identity),
                    R.map(() => 10),
                    R.sum
                )(state.assets);
        },
        apollo_totalActivatedCapacity(state) {
            return filter =>
                R.pipe(
                    R.filter(filter || R.identity),
                    R.map(contract => contract.qty * 10),
                    R.sum
                )(state.contracts);
        },
        apollo_totalAvailableCapacity(state, getters) {
            return filter => {
                return (
                    getters.apollo_totalCapacity(filter) -
                    getters.apollo_totalActivatedCapacity(filter)
                );
            };
        },
        apollo_phaseEndDate(state) {
            return phase => state.assets.find(asset => asset.phase === phase).end_date
        }
    },
    actions: {
        'apollo.loadData'(context, force) {
            const API_URL = process.env.API_URL;

            if (context.state.ready && !force) {
                console.log('APOLLO DATA ALREADY LOADED')
                return;
            }

            if (context.state.ready && force) {
                console.log('FORCE RELOAD APOLLO DATA')
            }

            return Promise.all([
                axios.get(`${API_URL}/apollo/assets`),
                axios.get(`${API_URL}/apollo/services`),
                axios.get(`${API_URL}/apollo/contracts`),
                axios.get(`${API_URL}/apollo/phases`),
                axios.get(`${API_URL}/apollo/customers`)
            ]).then(
                ([
                    assets_res,
                    services_res,
                    contracts_res,
                    phases_res,
                    customers_res
                ]) => {
                    const contracts = contracts_res.data.data.map(
                        data => new Contract(data)
                    );

                    const services = services_res.data.data.map(
                        data => new Service(data)
                    );
                    const assets = assets_res.data.data.map(
                        data => new Asset(data)
                    );
                    const phases = phases_res.data.data;
                    const customers = customers_res.data.data;

                    context.commit('UPDATE_STATE', {
                        assets,
                        services,
                        contracts,
                        phases,
                        customers,
                        ready: true
                    });
                }
            );
        }
    },
    mutations: {
        UPDATE_STATE(state, data) {
            Object.assign(state, data);
        },
        SET_ASSETS(state, data) {
            state.assets = data;
        },
        SET_SERVICES(state, data) {
            state.services = data;
        },
        SET_CONTRACTS(state, data) {
            state.contracts = data;
        },
        SET_PHASES(state, data) {
            state.phases = data;
        },
        SET_CUSTOMERS(state, data) {
            state.customers = data;
        }
    }
};