<template>
    <div> 
        <div v-if="!loaded" class="hero is-large">
            <div class="hero-body is-fullwidth has-text-centered">
                <span class="button is-white is-loading is-fullwidth"></span>
            </div>
        </div>
        <div class="notification" v-else>
            <h2 class="title is-6">Search Routes</h2>
            <div class="animated fadeInUp faster">
                <span class="heading">Deal type:</span>
                <div class="buttons has-addons is-mobile is-fullwidth is-centered">
                    <a @click="type = 'stock'" class="button" style="width:50%; padding: 30px;" :class="{'is-info' : type == 'stock'}">
                        Stock
                    </a>
                    <a @click="type = 'asset'" class="button" style="width:50%; padding: 30px;" :class="{'is-info' : type == 'asset'}">
                        Asset
                    </a>
                </div>
            </div>
            <div v-if="type">
                <br>
                <div class="field has-addons animated fadeInUp faster " style="animation-delay: 0.1s">
                    <div class="control">
                        <div class="button is-static" style="width:80px;">From</div>
                    </div>
                    <div class="control is-expanded">
                        <div class="select is-fullwidth"  >
                            <select v-model="from">
                                <option value="">Select</option>
                                <option v-for="(country, i) in getCountries()" :key="i" :value="country">{{country}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="field has-addons animated fadeInUp faster" style="animation-delay: 0.2s">
                    <div class="control">
                        <div class="button is-static" style="width:80px;">To</div>
                    </div>
                    <div class="control is-expanded">
                        <div class="select is-fullwidth"  >
                            <select v-model="to">
                                <option value="">Select</option>
                                <option v-for="(country, i) in getCountries(from)" :key="i" :value="country">{{country}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            
            <br>
            <div v-if="type && from && to" class="field  animated fadeInUp faster">
                <div class="control">
                    <div class="buttons">
                        <a @click="search" class="button is-primary is-fullwidth">Search</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PathFinderService from './../../../../Capry/webcomponent/Offering/PathFinderService'
import moment from 'moment';

export default {
    data(){
        return {
            type: '',
            from: '',
            to: ''
        }
    },
    mounted(){
        this.loadMiuRoutes()
    },
    computed:{
        routes(){
            return this[`routes_${this.type}`]
        },
        
        miu_routes(){
            return this.$store.state.capry.miu_routes
        },
        
        capacities(){
            return this.$store.state.capry.routes
        },
        
        loaded(){
            return this.capacities.length > 0 && this.miu_routes.length > 0
        },

        routes_asset(){
            return PathFinderService(this.capacities, this.miu_routes, 'asset')           
        },
        
        routes_stock(){
            return PathFinderService(this.capacities, this.miu_routes, 'stock')           
        } 
    },
    watch:{
        loaded(value){
            this.$emit('loaded', value)
        }
    },
    methods:{
        search(){
            const offerings = this.routes.calculate(this.from, this.to, this.type)
            this.$emit('search', offerings)
        },
        getCountries(from = ''){
            return this.routes.getCountries(from)
        },
        loadMiuRoutes(){
            this.$store.dispatch('capry.load.miuroutes')
        },
    },
    filters:{
        date(value){
            if(!moment.isMoment(value)){
                value = moment(value)
            }

            return value.format('YYYY-MM-DD hh:mm A')
        }
    }
}
</script>
