<template>
  <div>
      <apexchart :height="400" type="bar" :options="options" :series="series || []"></apexchart>
  </div>
</template>

<script>
import * as R from 'ramda'
import apexchart from 'vue-apexcharts'
import GraphTooltip from './GraphTooltip'
export default {
  props:{
    utilization: {
      type: Object,
      default: () => ({})
    }
  },
  components:{
    apexchart,
  },
  computed:{
    cables(){
      return R.pluck('cable', this.formattedData)
    },
    options(){
      const thiss = this;
      return {
        chart: {
          animations: {
            enabled: false
          },
          id: 'utilization-chart',
          stacked: true,
          stackType: '100%'
        },
        xaxis: {
          categories: this.cables,
          labels: {
            rotateAlways: true,
          }
        },
        legend:{
          position: 'top'
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px'
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45
          }
        },
        tooltip:{
          shared: true,
          followCursor: true,
          onDatasetHover:{
            highlightDataSeries: false
          },
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            const utilized = series[0][dataPointIndex]
            const unutilized = series[1][dataPointIndex]
            const cable = thiss.cables[dataPointIndex]
            const routes = thiss.indexedFormatted[cable].routes
            const addList = str => `<li>${str}</li>`
            return `
            <div class="box">
              <h5 class="title is-6">${cable}</h5>
              <table class="table is-fullwidth">
                <tbody>
                  <tr><th>Utilized</th><td>${utilized} GB</td></tr>
                  <tr><th>Unutilized</th><td>${unutilized} GB</td></tr>
                </tbody>
              </table>
              <div class="content is-small">
                <span class="heading">Calculated routes</span>
                This utilization uses common routes as below:
                <ul>${routes.map(addList).join('')}</ul>
              </div>
            </div>
            `
          },
          // y:{
          //   formatter(x,data){
          //     return `${x} GB`
          //   }
          // },
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              height: thiss.height || 600
            },
            legend: {
              position: 'top'
            },
            dataLabels: {
              style: {
                fontSize: '14px'
              },
              offsetX: -10
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top'
                }
              }
            }
          }
        }]
      }
    },
    series(){
      return [
        { name: 'Utilized', data: R.pluck('activated', this.formattedData)},
        { name: 'Unutilized', data: R.pluck('capacity', this.formattedData)},
      ]
    },
    indexedFormatted(){
      return R.indexBy(R.prop('cable'), this.formattedData)
    },
    formattedData(){
      return R.pipe(
        R.mapObjIndexed((data,cable) => ({
          cable: data.cableName,
          activated: data.commonRoutesSummary.equiped_capacity - data.commonRoutesSummary.capacity,
          capacity: data.commonRoutesSummary.capacity,
          utilization: 1 - (data.commonRoutesSummary.capacity / data.commonRoutesSummary.equiped_capacity ),
          routes: R.pipe(R.prop('commonRoutes'),R.pluck('name'))(data)
        })),
        R.values,
        R.sortBy(R.prop('utilization'))
      )(this.utilization.data)
    }
  }
}
</script>