<template>
    <div class="container">
      <div class="section" v-if="apollo_status =='loading'">
        <p class="title is-1">LOADING APOLLO DATA...</p>
      </div>
      <div class="section" v-if="apollo_status =='error'">
        <p class="title is-1">ERROR LOADING APOLLO DATA</p>
      </div>
      <div v-if="apollo_status =='ready'" class="section">
        <div class="content">
          <h1 class="title is-2">Apollo Tracking</h1>
        </div>
        <apollo-summary :state="$store.state.apollo" :getters="$store.getters"/>
        <hr>
        <apollo-utilization :state="$store.state.apollo" :getters="$store.getters"/>
        <apollo-mismatched :state="$store.state.apollo" :getters="$store.getters"/>
        <apollo-contributions :state="$store.state.apollo" :getters="$store.getters" />
      </div>
    </div>
</template>

<script>
import ApolloSummary from "./../../Apollo/webcomponent/Summary.vue";
import ApolloUtilization from "./../../Apollo/webcomponent/Utilization.vue";
import ApolloMismatched from "./../../Apollo/webcomponent/Mismatched.vue";
import ApolloContributions from './../../Apollo/webcomponent/Contributions.vue';

export default {
  components: {
    ApolloSummary,
    ApolloUtilization,
    ApolloMismatched,
    ApolloContributions,
  },
  data() {
    return {
      apollo_status: "loading"
    };
  },
  methods: {
    loadApolloData() {
      this.apollo_status = "loading";
      this.$store
        .dispatch("apollo.loadData")
        .then(() => {
          this.apollo_status = "ready";
        })
        .catch(() => {
          this.apollo_status = "error";
        });
    }
  },
  mounted() {
    this.loadApolloData();
  }
};

</script>

