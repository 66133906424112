<template>
  <div>
    <div class="dropdown" :class="{'is-active': open}" >
      <div class="dropdown-trigger">
        <div class="input" style="min-width:140px;" @click="toggle">
          <span>{{ currentValue || 'Select Month Year'}}</span>
        </div>
      </div>
      <div class="dropdown-menu" style="width:210px;" role="menu">
        <div class="dropdown-content">
          <div class="dropdown-item">
            <div class="field has-addons">
              <div class="control">
                <div class="select">
                  <select v-model="form.year">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </select>
                </div>
              </div>
              <div class="control" style="width:100%">
                <div class="select is-fullwidth">
                  <select v-model="form.month">
                    <option v-for="month in months" :key="month" :value="month">{{month}}</option>
                  </select>
                </div>
              </div>
              
            </div>
            <a @click.prevent="handleApply" class="button is-primary is-fullwidth">APPLY</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
moment().tz("Asia/Kuala_Lumpur");
export default {
    props: {
        range: {
            default: 10
        }
    },
    data(){
        return {
            open: false,
            currentValue: false,
            form: {
                year: moment().format('YYYY'),
                month: moment().format('MMM'),
            },
            months: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ]
        }
    },
    computed: {
        years(){
            let start = parseInt(moment().subtract(this.range, 'years').format('YYYY'))
            return [...Array(this.range * 2).keys()].map(i => start+i)
        }
    },
    methods:{
        toggle(){
            this.open = !this.open;
        },
        handleApply(){
            this.currentValue = `${this.form.year}-${this.form.month}`
            this.$emit('update', this.form)
            this.toggle()
        }
    }
}
</script>

