import * as R from 'ramda'
import {
    Validator
} from 'vee-validate';

const units = {
    "STM1": "x STM1",
    "STM16": "x STM16",
    "STM64": "x STM64",
    "10Gbps": "x 10Gbps",
    "100Gbps": "x 100Gbps",
    "miu": "Miu"
}

export const apollo_rules = {
    phase: {
        required: true,
    },
    btn_cct_ids: {
        required: true
    }
}

export const offnet_rules = {
    provider: {
        required: true
    },
    iru_cost: {
        numeric: true,
        min: 0,
    },
    onm: {
        numeric: true,
        min: 0
    },
    contract_years: {
        numeric: true,
        min: 1
    },
    contract_start_date: {
        required: true
    },
    ocm: {
        required: false,
    },
    // btn_cct_ids: {
    //     required: false
    // }
}

export const rules = {
    cable_system: {
        required: true,
    },
    category: {
        required: true
    },
    type: {
        required: true
    },
    qty: {
        required: true,
    },
    unit: {
        required: true
    },
    landing_points: {
        cls: {
            required: true,
        },
        pop: {
            required: false
        },
    },
    remarks: {
        required: true,
        max: 255
    },
}

export function validate(data) {
    const validator = new Validator(rules)
    return validator.validateAll(data).then(result => {
        return {
            validated: result,
            errors: validator.errors,
            validator
        }
    })
}