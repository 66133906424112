import axios from 'axios'
const hash = require('sha1')
import moment from 'moment-timezone'
moment.tz.setDefault('Asia/Kuala_Lumpur')
import * as R from 'ramda'

let mode = 'staging'

const api = (path) => {
    if (mode == 'production') {
        return `${process.env.API_URL_PROD}${path}`
    }

    return `${process.env.API_URL}${path}`
}

export default {
    state: {
        users: [],
    },
    getters: {
        
    },
    mutations: {
        SET_USERS(state, users) {
            state.users = users
        }
    },
    actions: {
        'user-management.load'(context) {
            axios.get(api('/user-management/users')).then(res => {
                context.commit('SET_USERS', res.data.users)
            })
        }
    }
}