<template>
    <div class="capry">
        <login v-if="requireLogin" />
        <div v-else>
            <nav-bar @logout="logout" @toggleMode="toggleMode"/>
            <router-view :store="$store"></router-view>
        </div>
    </div>
</template>

<script>
require('./../../../utils/styles/tm.scss')
import(/* webpackChunkName: "animate.css" */'animate.css')
import(/* webpackChunkName: "tooltip" */'bulma-tooltip')
import NavBar from './NavBar.vue'
import Login from "./../../../Authentication/webcomponent/Login.vue" 

export default {
    components: {
        NavBar,
        Login
    },
    data(){
        return {

        }
    },
    created(){
        this.$store.dispatch('requiresAuth', true)
        this.$store.dispatch('login.init')
        this.$store.dispatch('capry.init', this.$store.state.login.user)
    },
    computed:{
        requireLogin(){
            return this.$store.getters.show_login
        },
        user(){
            return this.$store.state.login.user
        }
    },
    watch:{
        user(newUser){
            this.$ga.set({userId: newUser.staff_id, user_name: newUser.name})
        }
    },
    methods:{
        logout(){
            this.$store.dispatch('login.logout')
        },
        toggleMode(mode){
            this.$store.dispatch('capry.mode.toggle',mode)
            window.location.reload()
        }
    }
}
</script>

