<template>
  <div>
    <div class="table-container card">
      <table ref="table" class="table is-fullwidth is-bordered is-small is-hoverable table-small-font" style="width:1800px">
        <thead>
          <tr class="has-background-light">
            <th class="has-background-light" style="position: sticky; left:0; box-shadow: inset -1px 0px 0 #ccc; " rowspan="2">Cable</th>
            <th rowspan="2">Cable Type</th>
            <th rowspan="2">Cable Category</th>
            <th v-if="details" colspan="4" class="has-text-centered">All segments</th>
            <th :colspan="details? 8 : 5" class="has-text-centered">Common routes</th>
          </tr>
          <tr class="has-background-light">
            <template v-if="details">
              <th class="has-text-right">Total Capacity (Gbps)</th>
              <th class="has-text-right">Remaining Capacity (Gbps)</th>
              <th class="has-text-right">Activated Capacity (Gbps)</th>
              <th class="has-text-right">Utilization (%)</th>
            </template>
            <th>Route</th>
            <th>Total Capacity (Gbps)</th>
            <th>Remaining Capacity (Gbps)</th>
            <th>Activated Capacity (Gbps)</th>
            <th>Utilization (%)</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(cable) in utilization.data">
            <tr v-for="(route,id) in cable.commonRoutes" :key="`${cable.cableName}-${id}`">
              <template v-if="id == 0">
                <th class="has-background-white" style="position: sticky; left:0; box-shadow: inset -1px 0px 0 #ccc; " :rowspan="cable.commonRoutes.length">
                  <a @click="viewBreakdown(cable)" class="button has-icons-left is-white is-small is-pulled-right">
                    <icon class="icon" name="info"/>
                  </a>
                  {{ cable.cableName }}
                </th>
                <td :rowspan="cable.commonRoutes.length">{{ cable.cableType }} </td>
                <td :rowspan="cable.commonRoutes.length">{{ cable.cableCategory }}</td>
                <template v-if="details">
                  <td class="has-text-right" :rowspan="cable.commonRoutes.length">{{ cable.allSegments.total_capacity  | formatCapacity }}</td>
                  <td class="has-text-right" :rowspan="cable.commonRoutes.length">{{ cable.allSegments.remaining_capacity | formatCapacity  }}</td>
                  <td class="has-text-right" :rowspan="cable.commonRoutes.length"> {{ cable.allSegments.activated_capacity | formatCapacity  }} </td>
                  <td class="has-text-right" :rowspan="cable.commonRoutes.length">{{ cable.allSegments.utilization }}</td>
                </template>
              </template>
              <td>
                <span class="">
                  {{ route.name }}
                  <a @click="viewRoute(route, cable)">
                    <icon width="12px" name="info"/>
                  </a>
                </span>

              </td>
              <td class="has-text-right">{{ route.equiped_capacity | formatCapacity  }}</td>
              <td class="has-text-right">{{ route.capacity | formatCapacity }}</td>
              <td class="has-text-right">{{ route.activated_capacity | formatCapacity  }}</td>
              <td class="has-text-right">{{ route.utilization }}</td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr  class="has-background-light" >
            <th rowspan="2" class="has-background-light" style="position: sticky; left:0; box-shadow: inset -1px 0px 0 #ccc; ">
              TOTAL
            </th>
            <th colspan="2"></th>
            <template v-if="details">
              <th class="has-text-right">
                <span class="heading has-text-weight-light">Total equipped</span>
              </th>
              <th class="has-text-right">
                <span class="heading has-text-weight-light">Remaining</span>
              </th>
              <th class="has-text-right">
                <span class="heading has-text-weight-light">Activated</span>
              </th>
              <th class="has-text-right">
                <span class="heading has-text-weight-light">Utilization</span>
              </th>
            </template>
            <th colspan="1"></th>
            <th class="has-text-right">
              <span class="heading has-text-weight-light">Total equipped</span>
            <th class="has-text-right">
              <span class="heading has-text-weight-light">Remaining</span>
            <th class="has-text-right">
              <span class="heading has-text-weight-light">Activated</span>
            </th>
            <th class="has-text-right">
              <span class="heading has-text-weight-light">Utilization</span>
            </th>
          </tr>
          <tr class="has-background-light" >
            <th colspan="2"></th>
            <template v-if="details">
              <th class="has-text-right">
                {{ utilization.allSegmentsSummary.equiped_capacity | formatCapacity }}
              </th>
              <th class="has-text-right">
                {{ utilization.allSegmentsSummary.capacity | formatCapacity }}
              </th>
              <th class="has-text-right">
                {{ utilization.allSegmentsSummary.activated_capacity | formatCapacity }}
              </th>
              <th class="has-text-right">
                {{ utilization.allSegmentsSummary.utilization }}%
              </th>
            </template>
            <th colspan="1"></th>
            <th class="has-text-right">
              {{ utilization.commonRoutesSummary.equiped_capacity | formatCapacity }}</th>
            <th class="has-text-right">
              {{ utilization.commonRoutesSummary.capacity | formatCapacity }}</th>
            <th class="has-text-right">
              {{ utilization.commonRoutesSummary.activated_capacity | formatCapacity }}</th>
            <th class="has-text-right">
              {{ utilization.commonRoutesSummary.utilization  }}%</th>
          </tr>
          <tr class="is-sr-only">
            <td >Genarated based on data as of </td>
            <td :colspan="details ? 11: 7">{{ now }}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="buttons">
      <button @click="downloadExcel" class="button is-small has-icons-left">Download</button>
    </div>
  </div>
</template>
<script>
import XLSX from 'xlsx'
import moment from 'moment'
import saveAs from 'file-saver'
import Icon from './../../common/Icon'
export default {
  components:{
    Icon,
  },
  props:{
    details:{
      type: Boolean,
      default: false
    },
    utilization: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed:{
    now(){
      return moment().format('DD MMM YYYY HH:mm:ss')
    }
  },
  methods:{
    viewBreakdown(cable){
      this.$emit('view', cable)
    },
    viewRoute(route, cable){
      this.$emit('route', {...route, groupedItems: cable.groupedItems })
    },
    downloadExcel(){
      var workbook = XLSX.utils.table_to_book(this.$refs.table);
      var wopts = { bookType:'xlsx', bookSST:false, type:'array' };
      var wbout = XLSX.write(workbook,wopts);
      saveAs(new Blob([wbout],{type:"application/octet-stream"}), `capry.xlsx`);
    }
  },
  filters:{
    formatCapacity(value){
      return value.toLocaleString()
    }
  }
}
</script>

<style lang="scss">
.table-small-font{
  font-size: 0.9em !important;
}
</style>