<template>
    <div>
        <div class="section" >
            <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li>
                        <a href="#/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" style="fill:currentColor;"/>
                        </svg>
                        </a>
                    </li>
                    <li class="is-active"><a href="">Inventory</a></li>
                </ul>
            </nav>

            <div class="notification" v-if="isLoading">
                <loading-screen />
                <h2 class="title is-4">
                    Loading...
                </h2>
            </div>
        
            <div v-if=!isLoading>
                <div class="columns">
                    <div class="column">
                        <div class="card">
                            <div class="column">
                                <div class="level">
                                <div class="level-left">
                                    <div class="level-item">
                                        <input ref="filterfield" :value="keywordFilter" @keyup="updateSearch" class="input is-rounded" type="text" placeholder="press '\' to filter" @keyup.esc="resetSearch" >
                                    </div>
                                    
                                </div>
                                <div class="level-right">
                                    <div class="level-item">
                                        <div class="field">
                                            <input type="checkbox" id="excludeReserved" class="is-small switch is-rounded" checked="checked" name="excludeReserved" v-model="excludeReserved">
                                            <label for="excludeReserved">Exclude reserved capacity</label>
                                        </div>
                                    </div>
                                    <div v-if="false" class="level-item">
                                        <div class="tabs is-toggle is-small">
                                            <ul>
                                                <li :class="currentTabIs('list')">
                                                    <a class="" @click="currentTab = 'list'">
                                                        <span class="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" style="fill: currentColor" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li :class="currentTabIs('cards')">
                                                    <a class=" is-disabled" @click="currentTab = 'cards'">
                                                        <span class="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" style="fill: currentColor" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"/>
                                                            </svg>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <vue-table v-if="currentTab === 'list'" :data=filteredCapacities :excludeReserved="excludeReserved"></vue-table>
                        </div>
                    </div>
                    <div class="column is-3">
                        <div class="">
                            
                            <aside class="menu">
                                <p class="menu-label">
                                    Categories
                                </p>
                                <ul class="menu-list">
                                    <li><a @click="filterCategory = false" :class="{'is-active' : filterCategory === false }">All categories</a></li>
                                    <li><a @click="filterCategory = 'apollo'" :class="{'is-active' : filterCategory === 'apollo' }">Apollo</a></li>
                                    <!-- <li><a @click="filterCategory = 'offnet'" :class="{'is-active' : filterCategory === 'offnet' }">Offnet</a></li> -->
                                    <li><a @click="filterCategory = 'onnet'" :class="{'is-active' : filterCategory === 'onnet' }">Onnet</a></li>
                                    <!-- <li><a @click="toggleCategory('apollo')" :class="{'has-text-grey-light' : !categories['apollo'] }"><input type="checkbox" disabled v-model="categories['apollo']"> Apollo</a></li>
                                    <li><a @click="toggleCategory('offnet')" :class="{'has-text-grey-light' : !categories['offnet'] }"><input type="checkbox" disabled v-model="categories['offnet']"> Offnet</a></li>
                                    <li><a @click="toggleCategory('onnet')" :class="{'has-text-grey-light' : !categories['onnet'] }"><input type="checkbox" disabled v-model="categories['onnet']"> Onnet</a></li> -->
                                </ul>
                            </aside>
                            <hr>
                            <div class="card" style="margin-bottom:20px; padding-top:10px;">
                                <div class="tag has-background-grey-lighter" style="position:absolute; top: -10px; left:10px;">Legends</div>
                                <div class="column">
                                    <div class="">
                                        <div class="">
                                            <div class="tags has-addons">
                                                <div class="tag is-white">
                                                    <div class="has-text-grey-lighter" style="position:relative;top:1px;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                                                    </div>
                                                </div>
                                                <div class="tag is-white" >
                                                    No reservation
                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="tags has-addons">
                                                <div class="tag is-white">
                                                    <div class="has-text-success" style="position:relative;top:1px;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                                                    </div>
                                                </div>
                                                <div class="tag is-white" >
                                                    Have reservation
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <hr>
                             <route-finder v-if="false" @search="handleOffering"></route-finder>
                             <offering-modal v-if="offerings" :offerings="offerings" @close="offerings = false" />
                            <div >
                                <span class="help">Data as of {{ capacities.as_of | formatDate }}</span>
                            </div>
                            <br>
                             <div v-if="$store.getters.capry_usercan('manage_capacity')" class="buttons">
                                <router-link class="button is-primary  is-fullwidth" to="/inventory/add">Add/Remove Capacity</router-link>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
const R = require('ramda')
import axios from 'axios'
import moment from 'moment'
import VueTable from './CapacityTable.vue'
import LoadingScreen from './../LoadingScreen.vue'
import GlobalKey from './../Services/GlobalKey'
import RouteFinder from './RouteFinder.vue'
import OfferingModal from './OfferingModal.vue'
export default {
    components:{
        VueTable,
        LoadingScreen,
        RouteFinder,
        OfferingModal,
    },
    data(){
        return {
            currentTab: 'list',
            excludeReserved: true,
            
            categories: {
                apollo: true,
                offnet: true,
                onnet: true,
            },

            offerings: false
        }
    },
    mounted(){
				if(this.$route.query.filter){
					this.$store.commit('UPDATE_INVENTORY_KEYWORD', this.$route.query.filter)
				}
        this.$store.dispatch('capry.loadRoutes')
        GlobalKey.register('quicksearch', (ev) => {
            console.log(ev)
            if(ev.key == '\\'){
                ev.preventDefault()
                this.$refs.filterfield.focus()
            }
        })
    },
    destroyed(){
        GlobalKey.unregister('quicksearch')
    },
    computed:{
        
        keywordFilter(){
            return this.$store.state.inventory_keyword
        },
        filterCategory:{
            get(){
                return this.$store.state.capry.config.inventory_filterCategory
            },
            set(value){
                this.$store.dispatch('capry.config.update', {key: 'inventory_filterCategory', value})
            }
        },
        filteredCapacities(){
            return this.capacities.filter(data =>  data.search && data.search(this.keywordFilter)).filter(capacity => {
                return !this.filterCategory ? true : capacity.hasCategory(this.filterCategory)
            })
        },
        isLoading(){
            return this.capacities.length == 0
        },
        capacities(){
            return this.$store.state.capry.routes
        },
        cards(){
            return R.pipe(
                R.groupBy(R.prop('cable_system')),
                R.map(data => Object.assign({}, data[0],{
                    items: data
                }))
            )(this.capacities)
        },
        card(){
            return R.head(R.values(this.cards)) || {}
        }
    },
    methods: {
        handleOffering(offerings){
            this.offerings = offerings
        },
        handleKeydown(ev){
            console.log(ev.keycode)
        },
        currentTabIs(value){
            return { 'is-active': value === this.currentTab }
        },
        toggleCategory(category){
            this.categories[category] = !this.categories[category] 
        },
        updateSearch(ev){
            const value = ev.currentTarget.value
            this.$store.commit('UPDATE_INVENTORY_KEYWORD', value)
        },
        resetSearch(){
            this.$store.commit('UPDATE_INVENTORY_KEYWORD', '')
        },
    },
    filters:{
        formatDate(value){
            return moment(value).format('YYYY-MM-DD H:m A')
        }
    }
}
</script>
