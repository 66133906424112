<template>
    <section>
        <div class="hero bg-tm-orange is-fullheight">
            <div v-if="hasNotification" class="notification animated  faster" :class="[notification.type, animation]" style="position:fixed; width:100vw;">
                <button class="delete"></button>
                {{ notification.message }}
            </div>
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-4" style=" max-width:100%;">
                            <checking @success="$emit('success')" @reset="handleReset" @error="onError" v-if="!show" />
                            <div class="card animated faster" :class="{'zoomIn' : show , 'hide' : !show}">
                                <div class="card-content has-text-white">
                                    <p class="title is-4">
                                    Password-less Login
                                    </p>
                                    <p class="is-6 has-text-grey">Enter your Login ID and we will send verification link to your email.</p>
                                    <form @submit.prevent="handleLogin">
                                        <input class="input" type="text"  v-model="form.staff_id" placeholder="Login ID"/>
                                        <hr>
                                        <button type="submit" class="button has-no-border bg-tm-orange is-fullwidth has-text-white is-medium" :class="{'is-loading': isLoading}"  >LOGIN</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="position:fixed; bottom: 0;padding: 5px;" class="is-7 has-text-white">
            <small>Build: {{ build }}</small>        
        </div>
    </section>
</template>

<style lang="scss">

$tm-orange : #FF6600;
$tm-orange-light : #F68B28;
$tm-blue : #003399;
$tm-blue-light: #358CCB;
$tm-red : #CC0033;
$grey-light: #B5B5B5;

$background-grey: #E5E5E5;
$primary: $tm-orange;


.hide{
    display: none;
}

.bg-tm-orange {
    background-color: $tm-orange !important;
}
</style>


<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Checking from './Checking'
export default {
    props: ['app'],
    mixins: [validationMixin],
    components: {
        Checking
    },
    data(){
        return {
            form: {
                staff_id: '',
                callback_url: '',
                app: this.app
            },
            isLoading: false,
            notification: {},
            animation: false,
        }
    },
    validations: {
        form: {
            staff_id: { required }
        }
    },
    computed:{
        show(){
            return this.$store.getters.login_status === 'logout'
        },
        build(){
            return process.env.BUILDTIME
        },
        hasNotification(){
            return this.notification.message != undefined
        },
        currentUrl(){
            return window.location.href.replace(/\/+#.*/, '')
        },
        sso(){
            return this.$route.query.sso
        }
    },
    watch:{
        sso(sso){
             if(sso){
                this.$store.commit('UPDATE_TEMP_TOKEN', { token:sso, phrase: 'Checking...'})
                window.location = this.currentUrl
            }
        }
    },
    methods:{
        handleReset(){
            this.$store.dispatch('login.logout')
        },
        onError(message){
            this.notify({
                message,
                type: 'is-danger'
            })
            this.handleReset()
        },
        handleLogin(){
            this.$v.form.$touch()
            if (this.$v.form.$error) {
                this.notify({
                    message: 'Please provide valid Login ID',
                    type: 'is-danger'
                })
                return
            }

            this.isLoading = true
            this.$store.dispatch('login.attempt', this.form).then(data => {
                this.isLoading = false;
                this.notify({
                    message: data.message,
                    type: 'is-info'
                })
            }).catch( (err) => {
                const message = (err.response||{ data: { }}).data.message || `${err}`;
                setTimeout(() => {
                    
                    this.isLoading = false;
                    this.notify({
                        message,
                        type: 'is-danger'
                    })
                }, 400)
            })
        },
        notify(data){
            if(this.notification.timer){
                clearTimeout(this.notification.timer)
                clearTimeout(this.notification.animation)
            }

            const timer = setTimeout(() => {
                this.notification = {}
            }, 2500)

            const animation = setTimeout(() => {
                this.animation = 'slideOutUp'
            }, 2000)

            this.notification = Object.assign({}, data, {
                timer,
                animation
            });
            this.animation = 'slideInDown'
        }
    },
    mounted(){
        this.$emit('mounted')
        this.form.callback_url = this.currentUrl
    }
}
</script>