<template>
  <div v-if="utilization">
    <breakdown v-if="cable" :cable="cable" @close="cable = false" />
    <route-view v-if="route" :route="route" @close="route = false" />
    <table-view :utilization="utilization" @view="viewBreakdown" @route="viewRoute" :details="details" />
    <debugger :data="utilization" />
  </div>
  <div v-else class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        Preparing the report...
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './../common/Icon'
import Debugger from './../common/debugger'
import Breakdown from './UtilizationSummary/Breakdown'
import TableView from './UtilizationSummary/TableView'
import RouteView from './UtilizationSummary/RouteView'

export default {
  props:{
    details:{
      type: Boolean,
      default: false
    },
    utilization: {
      type: Object,
      default: () => ({})
    }
  },
  components:{
    Icon,
    Debugger,
    Breakdown,
    RouteView,
    TableView,
  },
  data(){
    return {
      cable : false,
      route : false,
    }
  },
  methods:{
    viewBreakdown(cable){
      this.cable = cable
    },
    viewRoute(route){
      this.route = route
    }
  }
}
</script>