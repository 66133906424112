<template>
  <div>
      <div class="columns">
          <div class="column is-6 is-offset-3">
              <div class="card">
                  <div class="card-content">
                      <h2 class="title is-4">Order Confirmation</h2>
                      <div class="field">
                          <label class="checkbox " :class="hasError('confirm')">
                          <input name="confirm" v-model="confirmed_order" v-validate="'required'" type="checkbox" >
                              Customer has <b>confirmed order</b> and has <b>submitted SOFCA form</b>
                          </label>
                      </div>
                      <div class="field">
                          <div class="control">
                              <div class="buttons">
                                  <button class="button is-primary" @click.prevent="nextStep(true)">Complete Step</button>
                                  <button :disabled="confirmed_order" class="button has-text-grey" @click.prevent="nextStep(false)">Skip (just to check capacity)</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      confirmed_order: false,
    }
  },
  methods:{
    hasError(name){
        return { 
            'is-danger' : this.errors.first(name),
            'has-text-danger' : this.errors.first(name),
        }
    },
    async nextStep(complete_step_1){
        if(!complete_step_1){
            this.$emit('nextStep', false)
        }else{
            await this.$validator.validateAll()
            
            if(this.$validator.errors.any()){
                this.$emit('error')
            }else{
                this.$emit('nextStep', true)
            }
        }
    },
  }
}
</script>