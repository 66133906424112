const R = require('ramda')
const Route  = require('./Route').Route

const slug = (str) => str.replace(/[^a-zA-Z0-9]+/g,'-').replace(/--+/g,'-').replace(/(^-|-$)+/g,'')
const parseKey = R.pipe(capacity => { 
    const route = new Route(capacity.landing_points)
    return `${capacity.cable_system}-${route.label}`
}, slug)

const toGbps = require('./CapacityCalc').toGbps
const toQty = require('./CapacityCalc').toQty

const getTotalCapacityInGbps = R.pipe(R.map(({
    qty,
    unit
}) => toGbps(qty, unit)), R.sum)


const isReservation = capacity => capacity.meta.isReservation
const filterReservation = R.filter(data => R.not(isReservation(data)))

const calculateTotalCapacity = (capacities) => {
    return getTotalCapacityInGbps(capacities)
}

const getCapacityBreakdown = R.pipe(
    R.map(data => ({
        unit: data.unit,
        bw: data.unit == 'miu' ? data.qty : toGbps(data.qty, data.unit)
    })),
    R.groupBy(R.prop('unit')),
    R.map(R.pipe(R.map(R.prop('bw')),R.sum))
)

const toSummary = (capacities) => {
    const first = R.head(capacities)
    const route = new Route(first.landing_points)
    const summary = capacityCategories(capacities)
    const cable_system = first.cable_system
    const capacity_breakdown = getCapacityBreakdown(filterReservation(capacities))
    const _categories = R.pluck('category', summary).join(',')
    const _types = R.pluck('type', summary).join(',')
    const _index = JSON.stringify(R.flatten([
        cable_system,
        route.label,
        _types,
        _categories,
        R.keys(capacity_breakdown)
    ]))

    return {
        id: first.HashKey,
        label: `${first.cable_system} : ${route.label}`,
        cable_system,
        route,
        total_capacity: calculateTotalCapacity(capacities), 
        capacity_breakdown,
        items: filterReservation(capacities),
        reservations: R.filter(isReservation, capacities),
        summary,
        _index,
        isSufficient: (bw, type, consumed) => {
            return R.pipe(
                R.filter(data => data.type === type.toLowerCase()),
                R.map(_summary => _summary.isSufficient(bw, consumed)),
                R.sum,
                data => data > 0
            )(summary)
        },
        hasType:(type) => {
            return _types.indexOf(type) > -1
        },
        hasCategory: (category) => {
            return _categories.indexOf(category) > -1
        },
        search(keyword) {
            const regstr = keyword.trim().split(/[\%\s]/).map(part => `(?=.*${part})`).join('')
            const reg = new RegExp(`^${regstr}.*`, "i")
            return _index.match(reg)
        }
    }
}

const capacityCategories = (items) => {
    const process = R.pipe(
        R.groupBy(data => `${data.category}-${data.type}-${data.unit}`),
        R.map(data => {
            const first = R.head(data);
            const qty = R.pipe(
                filterReservation,
                R.pluck("qty"),
                R.sum
            )(data)

            const reservedQty = R.pipe(
                R.filter(isReservation),
                R.pluck("qty"),
                R.sum,
                data => data * -1
            )(data)

            const label = `${first.category}-${first.unit}-${first.type}`.toUpperCase();
            return {
                label,
                category: first.category,
                type: first.type.toLowerCase(),
                unit: first.unit,
                qty,
                reservedQty,
                total_capacity: toGbps(qty, first.unit),
                isType: (type) => first.type === type,
                isSufficient: (bw, consumed = {}, ignored = false) => {
                    return toQty(bw)[first.unit] <= qty
                },
                consumedQty: function (bw) {
                    return toQty(bw)[first.unit]
                },
                getItems(){
                    return filterReservation(items)
                }
            };
        }),
        R.values
    );

    return process(items);
}

module.exports.summarize = R.pipe(
    R.groupBy(parseKey),
    R.map(toSummary),
    R.values,
    R.sortBy(R.prop('cable_system'))
)

module.exports.toQty = toQty