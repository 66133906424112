<template>
    <div class="section" v-if="reservation" >
        <div style="padding-bottom: 20px;">
<div class="notification animated faster is-radiusless" style="position: fixed; top: 0px; left:0px; z-index:99; width: 100%;"
    :class="notificationAnim">
    <div class="container">
        <button class="delete" @click="hideErrorNotification" style="float:right"></button>
        {{ notification.message }}
    </div>
</div>

<div class="modal " :class="{'is-active': showCompleteForm}">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="card">
            <div class="card-content">
                <div class="field">
                    <label class="label">TM Service No</label>
                    <div class="control">
                        <input class="input" v-model="form.service_no" type="text" v-validate="'required'" name="service_no"
                            :class="hasError('service_no')">
                    </div>
                    <p class="help">TIPS: Correct TM Service No will help during Termination process.</p>
                </div>
            </div>
            <div class="card-footer">
                <a class="card-footer-item" @click="cancelComplete">Cancel</a>
                <a class="card-footer-item" @click="completeActivation"><b>Complete</b></a>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" @click="cancelComplete" aria-label="close"></button>
</div>
<div style="padding-bottom:40px;">
    <div class="card">
        <div class="card-content">
            <steps :current_step="current_step" :confirmed_order="true" />
        </div>
    </div>
</div>
<div v-if="isLoading" class="section">
      <div class="notification">Loading...</div>
    </div>
    <div v-if="!isLoading">
        <h1 class="title is-5">Salesforce ID : {{reservation.salesforce_id}} <span class="tag is-pulled-right" :class="statusClass(reservation.status)">{{(reservation.status || '').toUpperCase()}}</span>
</h1>
<div class="columns">
    <div class="column is-3">
        <div class="card">
            <div class="card-content content">
                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Customer
                    </span> <br>
                    {{ reservation.customer }}
                </p>

                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Contact Person
                    </span> <br>
                    {{ reservation.contact_person }}
                </p>

                <p>
                    <span class="is-pulled-right">
                        <a class="button is-small is-rounded" style="margin-top:1em;" @click="convertType">Convert type</a>
                    </span>
                    <span class="is-size-7 has-text-grey-lig">
                        Asset type
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.type }}</span>
                </p>

                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Bandwidth
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.bandwidth }}G</span>
                </p>

                <p v-if="reservation.service_no">
                    <span class="is-size-7 has-text-grey-lig">
                        Service No.
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.service_no }}</span>
                </p>

                <p>
                    <span class="is-size-7 has-text-grey-lig">
                        Aging
                    </span> <br>
                    <span class="is-capitalized">{{ reservation.aging | days}} </span>
                </p>

            </div>
        </div>
        <p style="margin-top:10px;">
            <span class="is-size-7 has-text-grey-light">Reserved by <b>{{reservation.reserved_by}}</b></span>
        </p>
    </div>

    <div class="column">
        <div v-for="(c,i) in reservation.capacities" :key="i" class="card">
            <div class="card-content">
                <h3 class="title is-7 has-text-grey-light is-marginless">{{ c.cable_system}}</h3>
                <h2>
                    <router-link :to="`/inventory/show/${c.inventory_id}`" class="title is-5">{{ c.route.sortedLabel
                        }}</router-link>
                </h2>

                <div class="field is-grouped is-grouped-multiline" style="margin-top:10px;">
                    <div class="control">
                        <div class="tags has-addons">
                            <span class="tag is-dark">Required Capacity</span>
                            <span class="tag is-info">{{ c.capacity.consumedQty }} {{ c.capacity.unitLabel }}</span>
                        </div>
                    </div>

                    <div class="control">
                        <div class="tags has-addons">
                            <span class="tag is-dark">Category</span>
                            <span class="tag is-info">{{ c.capacity.category.toUpperCase() }}</span>
                        </div>
                    </div>

                    <div class="control">
                        <div class="tags has-addons">
                            <span class="tag is-dark">Type</span>
                            <span class="tag is-info">{{ c.capacity.type.toUpperCase() }}</span>
                        </div>
                    </div>
                </div>

                <div v-if="c.capacity.selected_options" class="content" style="margin-top:10px;">
                    <p><span class="heading">{{  c.capacity.category == 'offnet' ? "Providers" : "BTN CCT IDS"}}</span></p>
                    <ul>
                        <li v-for="(total,label) in groupSelected(c.capacity.selected_options)" :key="label">
                            <span class="tags has-addons">
                                <span class="tag ">{{ label }}</span>
                                <span v-if="c.capacity.category == 'offnet'" class="tag has-background-grey-light has-text-white">{{  total }} {{ c.capacity.unitLabel }}</span>
                            </span>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <br/>
        <div v-if="$store.getters.capry_usercan('manage_reservation')" style="padding-bottom:10px;">
            <div class="level">
                <div class="level-start">
                    <div v-if="reservation.status == 'open'" class="buttons">
                        <button @click.prevent="openCompleteForm" class="button is-primary is-multiline">Provisioning Completed</button>
                        <button @click.prevent="cancelReservation" class="button has-text-grey-light">Cancel Reservation</button>
                    </div>
                </div>
                <div class="level-end">
                    <div class="buttons is-right">
                        <button v-if="reservation.exceed && reservation.status != 'completed'" @click.prevent="renewReservation" class="button is-primary">Renew Reservation</button>
                        <button v-if="reservation.status == 'completed'" class="button is-primary is-outlined" @click.prevent="returnCapacity">Return Capacity (SOON)</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <a @click.prevent="showHistory = !showHistory" class="button is-size-7">{{ showHistory? "Hide" :
            "Show"}} history</a> -->
        <div class="notification has-background-white-bis" v-if="showHistory == true" style="margin-top:20px;">
            <h3 class="title is-5">Audit trails</h3>
            <table class="table is-fullwidth is-narrow is-striped is-hoverable">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>By</th>
                        <th>Event</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(log,i) in reservation.logs" :key="i">
                        <td>{{ log.date }}</td>
                        <td>{{ log.by }}</td>
                        <td>{{ log.event }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
    </div>
        </div>
        <div v-if="checkConvert" class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-body" style="width:100%;">
                <div class="container">
                    <div class="box">
                        <step-2 @success="success" @error="showNotification" @cancel="checkConvert = false" :convert-from="reservation" :confirmed_order="true" :convert-to="convertToType" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import * as R from 'ramda'
    import Step2 from './Step2'
    import Steps from './Steps.vue'
    export default {
        components: {
            Steps,
            Step2,
        },
        data() {
            return {
                isLoading: true,
                showCompleteForm: false,
                showHistory: true,
                form: {
                    service_no: ''
                },
                notification: {
                    status: false,
                    message: '',
                    timer: false
                },
                checkConvert: false
            }
        },
        mounted() {
            this.loadReservation()
        },
        methods: {
            loadReservation(){
                return this.$store.dispatch('capry.reservations.view', this.salesforce_id).then(res => {
                    this.isLoading = false
                })
            },
            success({msg,salesforce_id}){
                this.showNotification(msg, 'is-success')
                this.loadReservation().then(() => {
                    this.checkConvert = false
                })
            },
            
            convertType(){
                this.checkConvert = true
            },
            groupSelected(list){
                return R.pipe(
                    R.groupBy(R.identity),
                    R.map(data => data.length)
                )(list || [])
            },
            renewReservation(){
                this.$store.dispatch('capry.reservations.renew', this.reservation).then(() => {
                    alert("Reservation renewed")
                }).then(() => {
                    this.$store.dispatch('capry.reservations.view', this.salesforce_id)
                })
            },
            statusClass(status) {
                return {
                    'is-success': status == 'completed',
                    'is-info': status == 'open',
                }
            },
            cancelReservation() {
                if (confirm('Are you sure to cancel this reservation and release the capacity?')) {
                    this.$store.dispatch('capry.reservations.cancel', this.reservation).catch(err => {
                        alert(err.response.data.message)
                    }).then(() => {
                        this.$store.dispatch('capry.reservations.view', this.salesforce_id)
                    })
                }
            },
            returnCapacity() {
                alert('Not yet')
                console.log(new Date(), 'returnCapacity')
                if (confirm('Are you sure to return this reservation\'s capacity?')) {
                    // this.showNotification("Returning capacity...", "is-info")
                    // this.$store.dispatch('capry.reservations.returned', {
                    //     salesforce_id: this.reservation.salesforce_id,
                    //     service_no: this.form.service_no
                    // }).then(res => {
                    //     this.showNotification("Capacity Returned!", "is-success")
                    //     this.$store.dispatch('capry.reservations.view', this.salesforce_id)
                    // }).catch(err => {
                    //     console.error(err.response.data.message)
                    //     this.showNotification(err.response.data.message || err)
                    // })
                }
            },
            openCompleteForm() {
                if (confirm(
                        'Are you sure to complete this step? Capacity will be activated and deducted from inventory')) {
                    this.completeActivation()
                }
                // this.showCompleteForm = true;
            },
            cancelComplete() {
                this.showCompleteForm = false;
            },
            async completeActivation() {
                this.showCompleteForm = false;
                this.showNotification("Completing...", "is-info")
                this.$store.dispatch('capry.reservations.complete', {
                    salesforce_id: this.reservation.salesforce_id,
                    service_no: this.form.service_no
                }).then(res => {
                    this.showNotification("Completed!", "is-success")
                    this.$store.dispatch('capry.reservations.view', this.salesforce_id)
                }).catch(err => {
                    this.showNotification(err.response.data.message || err)
                })

            },
            hideErrorNotification() {
                this.notification = {
                    status: 'hide'
                }
            },
            showNotification(msg, type) {
                console.log("SHOW NOTIFICATION", msg)
                if (this.notification.timer) {
                    clearTimeout(this.notification.timer)
                }
                this.notification = {
                    status: 'show',
                    message: msg || 'Validation error. Please check the form',
                    timer: setTimeout(() => {
                        this.hideErrorNotification()
                    }, 3000),
                    type
                }
            },
            hasError(name) {
                return {
                    'is-danger': this.errors.first(name)
                }
            },
        },
        computed: {
            convertToType(){
                return this.reservation.type == 'asset' ? 'stock' : 'asset'
            },
            current_step() {
                switch (this.reservation.status) {
                    case 'open':
                        return 3
                    case 'completed':
                        return 5
                    case 'cancelled':
                        return 0
                }
            },
            notificationAnim() {
                if (!this.notification.status) {
                    return {
                        'hide': true
                    }
                }

                if (this.notification.status == 'show') {
                    return {
                        'slideInDown': true,
                        [this.notification.type || 'is-danger']: true
                    }
                }

                if (this.notification.status == 'hide') {
                    return {
                        'slideOutUp': true,
                        [this.notification.type || 'is-danger']: true
                    }
                }
            },
            salesforce_id() {
                return this.$route.params.id
            },
            reservation() {
                return this.$store.state.capry.currentReservation
            }
        },
        filters:{
            days(v){
                return v > 1 ? `${v} days`:`${v} day`
            }
        }
    }
</script>