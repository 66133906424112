<template>
    <div>
        <loading-screen v-if="loading" />
        <div v-if="false" class="container level" style="padding-top:80px;">
            <div class="level-start"></div>
            <div class="level-end">
                <router-link to="/dashboard-new" class="button is-small">Switch to new Dashboard</router-link>
            </div>
        </div>
        <simi-itf @loaded="loaded" />
        <apollo v-if="$store.getters.userScope(['apollo','developer'])" id="apollo" />  
    </div>
</template>

<script>

import LoadingScreen from './standalone/LoadingScreen.vue'

export default {
    components:{
        SimiItf : () => import(/* webpackChunkName: "simiift" */'./../../ITF/webcomponent/SimiItf'),
        Apollo: () => import(/* webpackChunkName: "apollo" */'./../../ITF/webcomponent/Apollo'),
        LoadingScreen,
    },
    data(){
        return {
            loading: true
        }
    },
    methods:{
        loaded(){
            this.loading = false
        }
    }
}
</script>
