<template>
    <div class="modal is-active" v-if="selectedOffering">
            <div class="modal-background" ></div>
            <div class="modal-content">
                <div class="is-pulled-right">
                    <a @click="$emit('close')" class="delete" style="position: relative; top:5px; right: 5px;"></a>
                </div>
                <div class="box">
                    <offering-modal-option class="box" v-for="(offering, i) in offeringCables" :key="i" :offering="offering"/>
                </div>
            </div>
        </div>
</template>

<script>
import * as R from 'ramda'
import OfferingModalOption from './OfferingModalOption'
export default {
    props:['selectedOffering'],
    components:{
        OfferingModalOption,
    },
    computed:{
        offeringCables(){
            return this.selectedOffering.routes
        }
    },
}
</script>