<template>
  <div>
    <div class="level">
      <div class="level-start">
        <a @click="show = !show" class="button is-small has-icons-left">
          <i class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
          </i>
        </a>
      </div>
    </div>
    <div v-if="show" class="card card-content" id="big-filter">
      <div class="columns">
        <div class="column is-4">
          <div class="menu">
            <span v-show="hasFilter('cable')" class="is-pulled-right"><a @click="clearFilter('cable')" class="delete"></a></span>
            <p class="menu-label">Cable Systems</p>
            <ul class="menu-list card" style="height:200px; overflow-y: scroll">
              <li v-for="cable in cableSystems" :class="{'is-strike has-text-grey-lighter':isExclude('cable', cable)}" :key="cable">
                <a @click="toggleFilter('cable', cable)">{{ cable }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="column is-4">
          <div class="menu">
            <span v-show="hasFilter('category')" class="is-pulled-right"><a @click="clearFilter('category')" class="delete"></a></span>
            <p class="menu-label">Category</p>
            <ul class="menu-list card" style="height:200px; overflow-y: scroll">
              <li v-for="category in categories" :key="category" :class="{'is-strike has-text-grey-lighter':isExclude('category', category)}">
                <a @click="toggleFilter('category',category)">{{ category }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="column is-4">
          <div class="menu">
            <span v-show="hasFilter('type')" class="is-pulled-right"><a @click="clearFilter('type')" class="delete"></a></span>
            <p class="menu-label">Capacity type</p>
            <ul class="menu-list card" style="height:200px; overflow-y: scroll">
              <li v-for="type in types" :key="type" :class="{'is-strike has-text-grey-lighter':isExclude('type', type)}">
                <a @click="toggleFilter('type',type)">{{ type }}</a>
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div class="level">
        <div class="level-start"></div>
        <div class="level-end">
          <a @click="show = false" class="button is-small is-dark">Hide filter</a>
        </div>
      </div>
    </div>
    <utilization-graph :utilizationData="filteredData" />
  </div>
</template>


<script>
import * as R from 'ramda'
import UtilizationGraph from './UtilizationGraph'
const CapacityCalc = require('./../../Models/CapacityCalc')

const calculateUtilization = list => {

  const getTotalEquiped = R.pipe(
    R.map(R.pick(['equiped_qty', 'unit'])),
    R.map(pair => ({
      ...pair,
      inGb: CapacityCalc.toGbps(pair.equiped_qty, pair.unit)
    })),
    R.pluck('inGb'),
    R.sum
  )

  const getTotalRemaining = R.pipe(R.pluck('total_capacity'),R.sum)
  const miu_records = R.filter(data => data.unit == 'miu', list)
  const gb_records = R.filter(data => data.unit != 'miu', list)

  const miu_total_equiped = R.pipe(R.pluck('equiped_qty'),R.sum)(miu_records)
  const miu_total_remaining = getTotalRemaining(miu_records)
  const gb_total_equiped = getTotalEquiped(gb_records)
  const gb_total_remaining = getTotalRemaining(gb_records)

  return {
    miu: {
      total_equiped: miu_total_equiped,
      total_remaining: miu_total_remaining,
      utilization: (miu_total_equiped - miu_total_remaining)/miu_total_equiped,
      activated: miu_total_equiped - miu_total_remaining,
      records: miu_records,
    },
    gb:{
      total_equiped: gb_total_equiped,
      total_remaining: gb_total_remaining,
      utilization: (gb_total_equiped - gb_total_remaining)/gb_total_equiped,
      activated: gb_total_equiped - gb_total_remaining,
      records: gb_records
    }
  }
}

export default {
  props: ['rawdata'],
  components:{
    UtilizationGraph,
  },
  data(){
    return {
      excludes: {
        cable: {},
        category: {},
        type:{}
      },
      categories:[ 'offnet', 'onnet', 'apollo'],
      types: ['asset','stock'],
      show: false,
      format:'table'
    }
  },
  watch:{
    filteredData(){
      this.$emit('datachanged', this.filteredData)
      this.$emit('missing', this.missingEquiped)
    }
  },
  mounted(){
    this.$emit('datachanged', this.filteredData)
    this.$emit('missing', this.missingEquiped)
  },
  computed:{
    missingEquiped(){
      return R.pipe(
        R.omit(this.getFilters('cable')),
        R.map(R.pipe(
          R.filter(data => this.getFilters('category').indexOf(data.category) == -1),
          R.filter(data => this.getFilters('type').indexOf(data.type) == -1),
          R.filter(data => !data.equiped_qty),
          R.groupBy(R.prop('PK')),
          R.map(data => ({
            PK: R.head(data).PK,
            types: R.pluck('label', data)
          })),
          R.values,
        )),
      )(this.rawdata)
    },
    cableSystems(){
      return R.sortBy(R.identity,R.keys(this.rawdata))
    },
		sortedData(){
			return R.pipe(
				R.mapObjIndexed((data,key) => ({
					cable: key,
					rank: R.sum([data.gb.utilization || 0, data.miu.utilization || 0]),
					...data,
				})),
				R.values,
				R.sortBy(R.prop('rank')),
				R.indexBy(R.prop('cable')),
			)(this.filteredData)
		},
    filteredData(){
      return R.pipe(
        R.omit(this.getFilters('cable')),
        R.map(R.pipe(
          R.filter(data => this.getFilters('category').indexOf(data.category) == -1),
          R.filter(data => this.getFilters('type').indexOf(data.type) == -1),
          calculateUtilization
        )),
      )(this.rawdata)
    }
  },
  methods:{
    getFilters(type){
      return R.pipe(
        R.filter(data => data),
        R.keys
      )(this.excludes[type])
    },
    hasFilter(type){
      return this.getFilters(type).length > 0; 
    },
    clearFilter(type){
      this.excludes[type] = {}
    },
    toggleFilter(type, value){
      this.$set(this.excludes[type],value, !this.excludes[type][value])
    },
    isExclude(type, value){
      return this.excludes[type][value] == true
    }
  },
  filters:{
    toPercent(value){
      if(isNaN(value) || !isFinite(value)){
        return '-'
      }

      return `${(value * 100).toFixed(2)}%`
    },
    toGB(value){
      return `${value.toFixed(2).replace('.00','')} Gbps`
    }
  }
}
</script>

<style lang="scss">
@import url('~bulma-popover/css/bulma-popover.css');

.is-strike{
  text-decoration: line-through;
}

.is-strike a{
  color: currentColor;
}
</style>