<template>
    <div class="section">
        <loading-screen  v-if="loading" />
        <div class="level">
            <div class="level-left">
                <div class="level">
                    <div class="level-item">
                        <h1 class="title is-3">Reservations</h1>
                    </div>
                    <div class="level-item">
                        
                    </div>
                </div>
            </div>
            <div v-if="$store.getters.capry_usercan('manage_reservation')" class="level-right">
                <button @click.prevent="handleNewReservation" class="button is-primary">New Reservation</button>
            </div>
        </div>
        <reservation-stats :reservations="reservations" @updateFilter="updateFilter"/>
        <div class="card">
            <div class="card ">
                <div class="column">
                    <div class="level">
                        <div class="level-start">
                            <div class="tags has-addons">
                                <div class="tag">Filter</div>
                                <div class="tag" :class="tagColor">{{ status || 'all' }}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <table class="table is-fullwidth is-hover">
                <thead>
                    <tr>
                        <th>Salesforce ID</th>
                        <th>Customer</th>
                        <!-- <th>Contact Person</th> -->
                        <th>Reserved By</th>
                        <th>Type</th>
                        <th>Bandwidth</th>
                        <th>Aging</th>
                        <th>status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="filteredReservations.length == 0">
                        <td colspan="7">
                            <div class="hero">
                                <div class="hero-body is-fullwidth has-text-centered"><span class="title is-4">No record</span></div>
                            </div>
                        </td>
                    </tr>
                    <tr v-for="(reservation,i) in filteredReservations" :key="i">
                        <th>
                            <router-link :to="{ name: 'reservation.v2.show', params:{ id: reservation.salesforce_id }}">{{ reservation.salesforce_id}}</router-link>
                        </th>
                        <td>
                            {{ reservation.customer }}
                            <span class="help">ctc: {{ reservation.contact_person }}</span>
                        </td>
                        <!-- <td>{{ reservation.contact_person }}</td> -->
                        <td>{{ reservation.reserved_by }}</td>
                        <td class="is-capitalized">{{ reservation.type }}</td>
                        <td>
                            {{ reservation.bandwidth }}G
                            <div>
                                <span class="help">{{reservation.capacities.length}} segments</span>
                            </div>
                        </td>
                        <td class="has-text-centered">
                            <span class="tooltip" :data-tooltip="`reserved on ${getCreationDate(reservation)}`">{{ reservation.aging }} day{{ reservation.aging > 1 ? 's' : '' }} </span>
                        </td>
                        <td>
                            <router-link class="tag" :class="{
                                'is-info': reservation.status == 'open',
                                'is-success': reservation.status == 'completed',
                                'is-link': reservation.status == 'confirmed',
                            }"  :to="{ name: 'reservation.v2.show', params:{ id: reservation.salesforce_id }}">
                                {{ reservation.status }}
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>


<script>
require('bulma-switch')
import ReservationStats from './ReservationStats'
import LoadingScreen from './../LoadingScreen.vue'
import { setTimeout } from 'timers';
import moment from 'moment'
export default {
    components:{
        LoadingScreen,
        ReservationStats,
    },
    data(){
        return {
            loading: true,
            showAll: false,
            status: 'open',
        }
    },
    mounted(){
        this.$store.dispatch('capry.reservations.load').then(() => {
            this.loading = false    
        })
    },
    computed:{
        tagColor(){
            const colors = {
                all: 'has-background-grey-lighter',
                cancelled: 'has-background-grey has-text-white',
                completed:'is-success',
                open: 'is-info',
                exceed: 'is-danger'
            }

            return colors[this.status]
        },
        reservations(){
            return this.$store.state.capry.reservations
        },
        filteredReservations(){
            if(!this.status){
                return this.reservations
            }

            if(this.status == 'exceed'){
                return this.reservations.filter(reservation => reservation.status =='open' && reservation.aging > 30)
            }

            return this.reservations.filter(reservation => reservation.status == this.status)
        }
    },
    methods:{
        handleNewReservation(){
            this.$router.push({name: 'reservation.v2.create'})
        },
        getCreationDate(reservation){
            return moment(reservation.creationDate).format('YYYY-MM-DD')
        },
        updateFilter(status){
            this.status = status
        }
    }
}
</script>

