<template>
    <div>
        <div class="level">
            <div class="level-start">
                <h1 class="title">{{ offering.name }}</h1>
            </div>
        </div>
        <div v-if="zoom" class="modal is-active">
            <div class="modal-background" @click="zoom = false"></div>
            <div class="modal-content" style="margin:20px; width: 90%;">
                <div class="box" style="width:100%">
                    <vue-mermaid
                :nodes="routesData"
                type="graph TD"
            ></vue-mermaid>
                </div>
            </div>
        </div>
        <div class="box" >
            <span class="is-pulled-right">
                <a @click="zoom = true" class="button is-small is-text">
                    <span class="icon is-small">
                        <i class="">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M15 3h6v6M14 10l6.1-6.1M9 21H3v-6M10 14l-6.1 6.1"/></svg>
                        </i>
                    </span>
                </a>
            </span>
            <vue-mermaid
                :nodes="routesData"
                type="graph TD"
            ></vue-mermaid>
        </div>
        <div class="columns">
            <div class="column">
                <div class="box has-text-centered" style="height:100%">
                    <div>
                        <span class="heading has-text-left">Max Offerred Capacity</span>
                        <span class="title is-3">{{ offering.capacity }} GB</span>
                        <span class="help">&nbsp;</span>
                    </div>
                </div>
            </div>

            <div class="column">
                <div class="box has-text-centered" style="height:100%">
                    <div>
                        <span class="heading has-text-left">Total Routes</span>
                        <span class="title is-3">{{ offering.totalRoutes }}</span>
                        <span class="help">routes</span>
                    </div>
                </div>
            </div>

            <div class="column">
                <div class="box has-text-centered" style="height:100%">
                    <div>
                        <span class="heading has-text-left">Total Landing Point</span>
                        <span class="title is-3">{{ points.length }}</span>
                        <span class="help">landing points</span>
                    </div>
                </div>
            </div>

            <div class="column">
                <div class="box has-text-centered" style="height:100%">
                    <div>
                        <span class="heading has-text-left">Total Possible Paths</span>
                        <span class="title is-3">{{ offering.routes.length }}</span>
                        <span class="help">paths</span>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="columns is-multiline">
            <div class="column is-12">
                <h3 class="title is-4">Route Details</h3>
            </div>
            <div class="column is-12">
                <route-detail-card v-for="(route,i) in offering.groupRoutes" :options="route.options" :route="route.route" :key="i" :pathfinder="pathfinder" />
            </div>
        </div>
    </div>
</template>

<script>
const R = require('ramda')
const hash = require('sha1')
import RouteDetailCard from './RouteDetailCard'

export default {
    components:{
        RouteDetailCard,  
    },
    props:['offering', 'segments', 'pathfinder'],
    data(){
        return {
            zoom: false,
        }
    },
    computed: {
        distance(){ // used for mermaid
            const distance = R.pipe(
                R.values,
                R.mergeAll,
                R.map(data => data.meta),
                R.values,
                R.map(data => {
                    if(!data.route){
                        return {}
                    }
                    return data.route.landing_points.map(point => {
                        const route = [data.cable_system, point.pop, point.cls].filter(data => data).join(", ")
                        return {
                            [route] : point.dist
                        }
                    })
                }),
                R.flatten,
                R.mergeAll,
            )(this.segments)

            return distance
        },
        points(){ //used for mermaid
            return R.pipe(R.pluck('path'),R.flatten,R.uniq)(this.offering.routes)
        },
        routesData(){
            const distance = this.distance

            const locs = R.pipe(
                R.flatten,
                R.uniq,
                R.sortBy(point => distance[point]),
                list => {
                    return [[{
                        [list[0]]:-100
                    }], R.remove(0,1,list).map(loc => ({
                        [loc]: 999999
                    }))]
                },
                R.flatten,
                R.mergeAll,
            )(this.offering.points)

            const data = R.pipe(
                R.map(point => {
                    return R.keys(this.segments[point]).map(point2 => [point, point2])
                }),
                R.flatten,
                R.splitEvery(2),
                R.map(R.sortBy(point => {
                    return (locs[point] || distance[point] || 0) + point
                })),
                R.uniq,
                R.map(pair => {
                    const capacity = this.segments[pair[0]][pair[1]].capacity
                    return {
                        id: hash(pair[0]),
                        text: `"${pair[0]}"`,
                        link: `-- ${capacity.toFixed(2)}GB ---`,
                        next: [hash(pair[1])],
                    }
                }),
                list => (this.points.map(point => {
                   return {
                        id: hash(point),
                        text: `"${point}"`,
                        edgeType: 'round',
                    } 
                })).concat(list),
            )(this.points)

            return data
        }
    }
}
</script>
