<template>
  <div>
    <div class="has-text-right">
      <a @click="debug = !debug">          
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="icon is-small has-text-grey-lighter" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke="none" d="M0 0h24v24H0z"/>
          <path d="M9 9v-1a3 3 0 016 0v1M8 9h8a6 6 0 011 3v3a5 5 0 01-10 0v-3a6 6 0 011-3M3 13L7 13M17 13L21 13M12 20L12 14M4 19L7.35 17M20 19L16.65 17M4 7L7.75 9.4M20 7L16.25 9.4"/>
        </svg>
      </a>
    </div>
    <json-tree v-if="debug" :data="data" />
  </div>
</template>

<script>
import JsonTree from 'vue-json-tree'

export default {
  props: ['data'],
  components:{
    JsonTree,
  },
  data(){
    return {
      debug: false
    }
  }
}
</script>
