<template>
    <div class="container" style="padding-top:40px;">
        <div class="section">
            <h1>MIGRATION TOOLS</h1>
            <div class="columns">
                <div class="column">
                    <input type="file" @change="onFileChange" ref="file">

                    <button class="button" @click="handleProcess">Process</button>
                
                    <div class="">
                        <p>Unprocess: {{ unprocessed.length}}</p>
                        <div class="tags is-multiline">
                            <a @click="showRecord=rec" v-for="(rec,i) in wb" :key="i" class="tag" :class="{
                                'is-info' : processed[`${rec.PK}-${rec.SK}`] == 'picked',
                                'is-success' : processed[`${rec.PK}-${rec.SK}`] == 'saved',
                            }">{{i + 1}}</a>
                        </div>
                        <pre>{{wb}}</pre>
                    </div>
                </div>

                <div class="column">
                    <h1>Syncs</h1>
                    <ul>
                        <li><a  @click="loadCableSystems" class='button' :class="{
                            'is-loading': loadings.cable_system
                        }">Cable system from airtable</a></li>
                    </ul>

                    <div class="card">
                        <div class="card-content">
                            <input type="text" class="input" v-model="PK">
                            <button @click="summarize(PK)" class="button">SUMMARIZE</button>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        
    </div>
</template>


<script>
import * as R from 'ramda'
const XLSX = require('xlsx')

export default {
    data(){
        return {
            file: false,
            filename: '',
            wb: [],
            showRecord: false,
            processed: {},
            loadings:{
                cable_system: false
            },
            PK: '',
        }
    },
    computed:{
        unprocessed(){
            return this.wb.filter(data => !this.processed[`${data.PK}-${data.SK}`])
        }
    },
    methods:{
        loadCableSystems(){
            this.loadings.cable_system = true
            this.$store.dispatch('capry.syncSubmarineCables').then(res => {
                this.loadings.cable_system = false
            }).catch((err) => {
                alert(err)
                this.loadings.cable_system = false
            })
        },
        getBatch(){
            const choosed = R.take(10, this.unprocessed)
            choosed.forEach(data => {
                this.$set(this.processed, `${data.PK}-${data.SK}`,'picked')
            })
            
            if(choosed.length == 0){
                throw "done!"
            }
            console.log("batch fetched")
            return choosed
        },
        handleProcess(){
            console.log("handle process...")
            this.processData().then(this.handleProcess).catch(err => alert(err))
        },
        processData(){
            console.log("process data...")
            return Promise.all(this.getBatch().map(data => {
                return this.$store.dispatch('capry.migrate', data).then(res => {
                    this.processed[`${data.PK}-${data.SK}`] = 'saved'
                })
            }))
        },
        readExcel(file){
            return new Promise(function (resolve, reject) {
                var rABS = true;
                var reader = new FileReader()
                reader.onload = function (e) {
                    var data = e.target.result;
                    if (!rABS) data = new Uint8Array(data);
                    var workbook = XLSX.read(data, {
                        type: rABS ? 'binary' : 'array'
                    });

                    resolve(workbook)
                };
                if (rABS) reader.readAsBinaryString(file);
                else reader.readAsArrayBuffer(f);
            })
        },
        onFileChange(ev){
            ev.stopPropagation()
            ev.preventDefault()
            
            this.file = R.head(ev.target.files)
            this.filename = this.file.name
            
            this.readExcel(this.file).then(wb => {
                const ws = wb.Sheets[R.head(wb.SheetNames)];
                const data = XLSX.utils.sheet_to_json(ws)

                
                const process = R.pipe(
                    R.map(d => {
                        const parseData = key => {
                            return JSON.parse((d[key]||'false').replace(/\[\]/g,'false'))
                        }

                        let reservation = parseData("reservation (S)")

                        if(reservation){
                            try{
                                const selected_options = JSON.parse(reservation.selected_options)
                                reservation = Object.assign({},reservation,{
                                    selected_options
                                })
                            }catch(err){

                            }
                        }

                        return Object.assign({},d,{
                            "capacities (S)": parseData("capacities (S)"),
                            "logs (S)": parseData("logs (S)"),
                            "landing_points (S)": parseData("landing_points (S)"),
                            "route (S)": parseData("route (S)"),
                            "capacity_breakdown (S)": parseData("capacity_breakdown (S)"),
                            "summary (S)": parseData("summary (S)"),
                            "reservation (S)": reservation,
                        })
                    }), 
                    R.map(R.pipe(
                            R.toPairs, 
                            R.map(data => {
                                return [data[0].replace(/ \([A-Z]\)/,''),data[1]]
                            }),
                            R.indexBy(R.head),
                            R.map(data => JSON.parse(JSON.stringify(data[1]).replace(new RegExp('â', 'g'), '→')))
                        ),
                    ),
                    R.map(R.pipe(
                        data => Object.assign({},data,{
                            btn_cct_id: data.category == 'apollo' ? data.GS2 : false
                        }),
                        R.filter(data => data),
                    ))
                )

                this.wb = process(data)

                
            })
            
        },
        summarize(PK){
            this.$store.dispatch('capry.summarize', {PK})
        }
    }
}
</script>

