<template>
    <div>
        <a @click="handleExport" class="button is-small tooltip" data-tooltip="Download as excel">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24" style="fill:currentColor;">
                <path d="M20 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.1a1.1 1.1 0 0 0-.31-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9v-.06zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3zm-4.71-4.71l-.29.3V12a1 1 0 0 0-2 0v2.59l-.29-.3a1 1 0 0 0-1.42 1.42l2 2a1 1 0 0 0 .33.21.94.94 0 0 0 .76 0 1 1 0 0 0 .33-.21l2-2a1 1 0 0 0-1.42-1.42z"/>
            </svg>
        </a>
    </div>
</template>


<script>
import * as R from 'ramda'
import XLSX from 'xlsx'

export default {
    props:['data'],
    computed:{
        users(){
            return R.indexBy(R.prop('staff_id'),this.$store.state.om.processors)
        }
    },
    methods:{
        async handleExport(){
            return this.exportExcel(R.values(this.data))
        },
        
        exportExcel(datas){
            console.log(datas)
            const ws = XLSX.utils.json_to_sheet(datas)
            const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Export");
			XLSX.writeFile(wb, "export.xlsx");
        },

        getProcessorName(staff_id){
            return (this.users[staff_id] || {name: staff_id}).name
        }
    }
}
</script>

