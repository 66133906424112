const container = {

}
export default {
    register(key, handler) {
        container[key] = handler
        document.addEventListener('keydown', container[key])
    },
    unregister(key) {
        document.removeEventListener('keydown', container[key])
    }
}