'use strict'

import * as R from 'ramda'
import { toQty } from './CapacityCalc'

export const RouteCapacity = route => {
    const summary = R.map(summary => {
        return Object.assign({}, summary, {
            isSufficient: (bw, consumed = {}, ignored = false) => {
                const qty = toQty(bw)[summary.unit]
                return qty && qty <= summary.reservableQty
            },
            consumedQty: function (bw) {
                return toQty(bw)[summary.unit]
            },
            isType(type){
                return summary.type == type
            }
        })
    }, route.summary);

    return Object.assign({}, route, {
        id: route.PK,
        hasCategory(category) {
            return R.pipe(R.values, R.pluck('category'), data => data.indexOf(category) > -1)(route.summary)
        },
        search(keyword) {
            const regstr = keyword.trim().split(/[\%\s]/).map(part => `(?=.*${part})`).join('')
            const reg = new RegExp(`^${regstr}.*`, "i")
            return JSON.stringify(route).match(reg)
        },
        hasType(type) {
            return R.pipe(R.values, R.pluck('type'), data => data.indexOf(type) > -1)(route.summary)
        },
        isSufficient: (bw, type, consumed) => {
            return R.pipe(
                R.filter(data => data.type.toLowerCase() === type.toLowerCase()),
                R.map(_summary => _summary.isSufficient(bw, consumed) || false),
                R.sum,
                data => data > 0
            )(summary)
        },
        toQty:(bw) => {
            return toQty(bw)  
        },
        consumedQty: function (bw) {
            toQty(bw)[R.head(summary).unit]
        },
        summary
    })
}