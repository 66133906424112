<template>
  <div v-if="routes">
    <div class="table-container">
      <vue-flowy :chart="flow"></vue-flowy>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import {VueFlowy, FlowChart} from 'vue-flowy'
import hash from 'object-hash'
export default {
  components:{
    VueFlowy,
  },
  props:{
    routes: Array,
    default(){
      return []
    }
  },
  mounted(){
    this.drawFlow()
  },
  beforeDestroy(){
    this.flow.destroy()
  },
  data(){
    return {
      flow: new FlowChart({ direction: 'TB'}),
      points: [],
      selectedPoint: false,
    }
  },
  computed:{
    allSegments(){
      return R.pipe(
        R.map( d => ({
          from: hash(d.route.landing_points[0]),
          to: hash(d.route.landing_points[1])
        }))
      )(this.routes)
    },
    allPoints(){
      return R.pipe(
        R.map(d => d.route.landing_points),
        R.flatten,
        R.sortBy(d => d.dist),
        R.uniq,
        R.map( d => ({
          ...d,
          hash:hash(d),
          label: [d.pop,d.cls].filter(d => d).join(', ')
        })),
      )(this.routes ||[])
    },
    pointMaps(){
      return R.indexBy(d => d.id, this.points)
    },
  },
  methods:{
    clickNode(point, i){
      console.log("CLICK", point, i)
    },
    drawFlow(){
      this.flow = new FlowChart({ direction: 'TB'})
      this.points = R.map((point,i) => {
        const pointNode = this.flow.addElement(point.hash, { label: point.label})
        pointNode.on('click',() => {
          this.clickNode(point,i)
        })
        return pointNode
      }, this.allPoints)

      R.map(segment => {
        if(this.pointMaps[segment.from] && this.pointMaps[segment.to]){
          this.pointMaps[segment.from].leadsTo(this.pointMaps[segment.to])
        }
      }, this.allSegments)
      
    },
  }
}
</script>