'use strict'

import axios from 'axios'
import * as R from 'ramda'
import moment from 'moment'
import LocalStorage from './../../utils/LocalStorage'
import { byCapacity } from './../Models/Reservation'
import { RouteCapacity } from './../Models/RouteCapacity'

let mode = process.env.NODE_ENV ?? 'development';

const api = (path) => {
    console.log('CAPRY API in', mode, 'mode')
    // if (mode == 'production') {
    //     return `${process.env.API_URL_PROD}${path}`
    // }

    return `${process.env.API_URL_PROD}${path}`
}

export default {
    state: {
        user: false,
        cables: [],
        capacities: [],
        routes: [],
        currentRoute: false,
        miu_routes: [],
        reservations: [],
        currentReservation: false,
        // mode: process.env.NODE_ENV == 'development' ? 'development' : 'production',
        mode: process.env.NODE_ENV ?? 'development',
        config: {
            inventory_filterCategory: false
        },
        content: {},
        offering: []
    },
    getters: {
        'capry_runningMode'(state) {
            return state.mode
        },
        'capry_apiUrl'(state) {
            return () => api('/')
        },
        'capry_cableNames'(state) {
            return R.pluck('name', state.cables)
        },
        'capry_capacitySummary'(state) {
            return [];
            const summary = require('./../Models/CapacitySummary.js').summarize(state.capacities)
            return summary
        },
        'capry_getCapacityReservations'(state) {
            return byCapacity(state.reservations)
        },
        'capry_usercan'(state) {
            return (action) => {
                const actions = {
                    'manage_capacity': ['developer', 'cnd', 'apollo'],
                    'manage_reservation': ['developer', 'bcs', 'apollo']
                }

                const overlapped = R.pipe(R.flatten, R.groupBy(R.identity), R.filter(data => data.length > 1), R.keys)([state.user.scope, actions[action]])
                return overlapped.length > 0
            }
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        UPDATE_CONFIG(state, { key, value }) {
            state.config[key] = value
        },
        SET_CABLES(state, cables) {
            state.cables = cables
        },
        SET_ROUTES(state, routes) {
            state.routes = routes
        },
        SET_CURRENT_ROUTE(state, route) {
            state.currentRoute = route
        },
        SET_CAPACITIES(state, capacities) {
            state.capacities = capacities
        },
        ADD_CAPACITY(state, capacity) {
            state.capacities.push(capacity)
        },
        SET_MODE(state, _mode) {
            state.mode = _mode
            mode = _mode
        },
        MIU_ROUTES(state, routes) {
            state.miu_routes = routes
        },
        SET_RESERVATIONS(state, reservations) {
            state.reservations = reservations
        },
        SET_CURRENT_RESERVATION(state, reservation) {
            state.currentReservation = reservation
        },
        UPDATE_RESERVATIONS(state, reservation) {
            R.pipe(
                R.filter(data => data.salesforce_id == reservation.salesforce_id),
                data => console.log(data) || data,
                list => {
                    list[0] = reservation
                    return list
                },
                data => console.log(data) || data,
            )(state.reservations)
        },
        UPDATE_CONTENT(state, content) {
            state.content = R.mergeAll([state.content, content])
        },
        SET_OFFERING(state, offering) {
            state.offering = offering
        }
    },
    actions: {
        'capry.config.update'(context, config) {
            context.commit('UPDATE_CONFIG', config)
        },
        'capry.load.miuroutes'(context) {
            context.commit('MIU_ROUTES', LocalStorage.get.item('capry.miu_routes') || [])
            return axios.get(api('/miu-calculator')).then(res => {
                LocalStorage.set('capry.miu_routes', res.data.cables)
                context.commit('MIU_ROUTES', res.data.cables)
                return res.data.cables
            })
        },
        'capry.mode.toggle'(context, mode) {
            if (mode) {
                context.commit('SET_MODE', mode)
                LocalStorage.set('capry.mode', mode);
                return;
            }

            mode = context.state.mode == 'production' ? 'training' : 'production';
            context.commit('SET_MODE', mode)
            LocalStorage.set('capry.mode', mode);
        },
        'capry.init'(context, user) {
            // context.commit('SET_MODE', LocalStorage.get.item('capry.mode') || 'staging')
            context.commit('SET_USER', user)
            context.dispatch('capry.loadSubmarineCables')
            context.dispatch('capry.reservations.load')

        },
        'capry.loadSubmarineCables'(context) {
            return axios.get(api('/capry/submarine-cables')).then(res => {
                context.commit('SET_CABLES', res.data.cables)
            })
        },
        'capry.syncSubmarineCables'(context) {
            return axios.post(api('/capry/submarine-cables')).then(res => {
                context.commit('SET_CABLES', res.data.cables)
            })
        },
        'capry.loadRoutes'(context) {
            let cached_data = (LocalStorage.get.item('capry.routes') || []).map(RouteCapacity)
            cached_data.as_of = LocalStorage.get.item('capry.routes.as_of')
            context.commit('SET_ROUTES', cached_data)

            const url = '/capry/capacities'
            return axios.get(api(url)).then(res => {
                const data = res.data.routes.map(RouteCapacity)
                data.as_of = moment()
                context.commit('SET_ROUTES', data)
                LocalStorage.set('capry.routes', data)
                LocalStorage.set('capry.routes.as_of', data.as_of)
                return data
            })
        },
        'capry.loadRoute'(context, id) {
            const url = `/capry/capacities/${id}`
            return axios.get(api(url)).then(res => {
                context.commit('SET_CURRENT_ROUTE', RouteCapacity(res.data.route))
            })
        },
        'capry.segmentinfo.load'(context, key) {
            return axios.get(api(`/capry/contents/${key.PK}`)).then(res => {
                context.commit('UPDATE_CONTENT', {
                    [res.data.PK]: res.data.contents
                })
            })
        },
        'capry.segmentinfo.update'(context, data) {
            console.log("Update SEGMENT INFO", data)
            return axios.post(api('/capry/contents'), data).then(res => {
                context.commit('UPDATE_CONTENT', {
                    [res.data.PK]: res.data.contents || {}
                })
            })
        },
        'capry.saveCapacity'(context, data) {
            return axios.post(api(`/capry/capacities`), data).then(async res => {
                const capacity = res.data.capacity
                context.commit('ADD_CAPACITY', capacity)
                return res
            })
        },
        'capry.reservations.submit'(context, form) {
            return axios.post(api('/capry/reservations'), form).then(res => {
                context.dispatch('capry.reservations.load')
                return res
            })
        },
        'capry.reservations.convert'(context, form) {
            return axios.post(api(`/capry/reservations/${form.salesforce_id}`), form).then(res => {
                context.dispatch('capry.reservations.load')
                return res
            })
        },
        'capry.reservations.load'(context) {
            return axios.get(api('/capry/reservations')).then(res => {
                context.commit('SET_RESERVATIONS', res.data.reservations)
                return res.data.reservations
            })
        },
        async 'capry.reservation.query'(context, id) {
            const reservation = await axios.get(api(`/capry/reservations/${id}`)).then(res => {
                return res.data.reservation
            })

            return reservation
        },
        'capry.reservations.view'(context, id) {
            return axios.get(api(`/capry/reservations/${id}`)).then(res => {
                context.commit('SET_CURRENT_RESERVATION', res.data.reservation)
                return res.data.reservation
            })
        },
        'capry.reservations.cancel'(context, reservation) {
            return axios.delete(api(`/capry/reservations/${reservation.salesforce_id}`)).then(res => {
                alert('cancelled!')
                context.dispatch('capry.reservations.load')
            })
        },
        'capry.reservations.returned'(context, reservation) {
            return axios.patch(api(`/capry/reservations/${reservation.salesforce_id}`)).then(res => {
                alert('released!')
                context.dispatch('capry.reservations.load')
            })
        },
        'capry.reservations.renew'(context, reservation) {
            return axios.post(api(`/capry/reservations/${reservation.salesforce_id}`)).then(() => {
                context.dispatch('capry.reservations.load')
            })
        },
        'capry.reservations.complete'(context, data) {
            return axios.put(api(`/capry/reservations/${data.salesforce_id}`), data).then(res => {
                context.dispatch('capry.reservations.load')
            })
        },
        'capry.dev.sync'(context) {
            return axios.get(api('/capry/capacities/sync')).then(res => {
                alert('done!')
            })
        },
        'capry.migrate'(context, data) {
            return axios.post(api('/capry/capacities/migrate'), { data }).then(res => {
                console.log(res.data)
                return res
            })
        },
        'capry.summarize'(context, data) {
            return axios.post(api('/capry/capacities/summarize'), {
                data
            }).then(res => {
                console.log(res.data)
                return res
            })
        },

        'capry.content.update'(context, data) {
            return axios.post(api('/capry/contents'), data).then(res => {
                context.commit('UPDATE_CONTENT', {
                    [res.data.PK]: res.data.contents
                })
            })
        },
        'capry.content.load'(context, PK) {
            return axios.get(api(`/capry/contents/${PK}`)).then(res => {
                context.commit('UPDATE_CONTENT', {
                    [res.data.PK]: res.data.contents
                })
            })
        },
        'capry.packages.save'(context, offering) {
            console.log("save offering", offering)
            return axios.post(api(`/capry/offering`), { offering }).then(res => {
                console.log(res.data)
            })
        },
        'capry.packages.all'(context) {
            return axios.get(api(`/capry/offering`)).then(res => {
                context.commit('SET_OFFERING', res.data.offering)
                return res.data.offering
            })
        },

        'capry.utilization.load'(context) {
            return axios.get(api('/capry/utilization')).then(res => {
                return res.data
            })
        }

    }
}