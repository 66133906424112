<template>
   <div>
     <div>
        <apexcharts v-if="showGraph" :height="height || 650" type="bar" :options="chartOptions || {}" :series="series || {}"></apexcharts>
     </div>
   </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
const R = require('rambda')

export default {
  components: {
    apexcharts: VueApexCharts
  },
  props: ['cables','height','horizontal'],
  data: function () {
    return {
      show: false 
    }
  },
  computed: {
    showGraph () {
      return this.series.length > 0
    },
    chartOptions () {
      return {
        chart: {
          id: 'utilization-chart',
          width: '100%',
          stacked: true,
          stackType: '100%'
        },
        plotOptions: {
          bar: {
            horizontal: this.horizontal,
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px'
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45
          }
        },
        xaxis: {
          categories: this.cableNames(),
          labels:{
            trim: false
          }
        },
        legend: {
          position: 'top'
        },
        responsive: [{
          breakpoint: 700,
          options: {
            chart: {
              height: this.height || 800
            },
            legend: {
              position: 'top'
            },
            dataLabels: {
              style: {
                fontSize: '14px'
              },
              offsetX: -10
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top'
                }
              }
            }
          }
        }]
      }
    },
    series () {
      return this.getSeriesData()
    }
  },
  methods: {
    getSeriesData () {
      const utilized = R.pluck('utilization', this.cables).map(data => parseFloat(data) || 0)
      return [
        {
          name: 'Current Utilization',
          data: utilized
        },
        {
          name: 'Unutilized Capacity',
          data: R.map(data => 1 - data, utilized)
        }
      ]
    },
    cableNames () {
      return R.pipe(R.filter(cable => cable.cable != ''),R.map(cable => `${cable.cable} (${cable.total_capacity.toLocaleString()}Gbps)`),R.values)(this.cables)
    }
  }
}
</script>
