<template>
    <div class="card">
        <header class="card-header has-background-dark ">
        <p class="card-header-title has-text-white">
            {{ currentPointName }} [{{ selectedRoutes.length }}]
        </p>
        <a href="#" class="card-header-icon" aria-label="more options">
            <span class="icon">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
        </a>
        </header>
        <div class="card-contents">
        <table v-if="capacities" class="table is-hoverable" width="100%">
            <thead>
            <tr>
                <th>Route</th>
                <th class="has-text-right" width="100">Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(route,i) in selectedRoutes" :key="i">
                <td>
                <div @click="handleSelectRoute(route)" style="cursor:pointer">
                    <span class="has-text-weight-bold" style="margin-bottom: 5px;"> {{ routeDisplay(route) }} </span>
                    <div class="tags">
                    <span v-for="(cable, i) in getCables(route)" :key="i" class="tag is-info has-text-weight-bold" style="height:auto; padding: 3px 8px 2px;"><small
                        style="font-size: 11px;">{{ cable }}</small></span>
                    </div>
                </div>
                </td>
                <th class="has-text-right">
                <h3 v-if="getTotalCapacity(route)" class="title is-size-4">
                    <span v-if="getTotalCapacity(route).unit === 'G'">{{ getTotalCapacity(route).total }}G</span>
                    <span v-else>{{ getTotalCapacity(route).total }}mil <br/><small class="is-size-7" style="position:relative; top:-0.7em;">MIU*km</small></span>
                </h3>
                <h3 v-else class="title is-size-4 has-text-grey">-</h3>
                </th>
            </tr>
            </tbody>
        </table>
        <div v-else class="has-text-centered"> <i class="fa fa-loading"></i> loading data...</div>
        </div>
    </div>
</template>

<script>
const R = require('rambda')

export default {
  props: ['currentPointName', 'selectedRoutes', 'locations', 'capacities', 'selectRoute'],
  methods: {
    routeDisplay (routes) {
      const getDisplayName = (loc) => this.locations[loc]
      const theOtherLeg = R.pipe(R.filter(data => data !== this.currentPoint), R.head, getDisplayName)(routes)
      return `${this.currentPointName} → ${theOtherLeg}`
    },
    getTotalCapacity (route) {
      return this.capacities.totalCapacity(route)
    },
    handleSelectRoute (route) {
      alert('fooo')
      this.selectRoute(route)
    },
    getCables (route) {
      return this.capacities.cables(route)
    }
  }
}
</script>

