<template>
    <div>
        <div class="columns">
            <div class="column">
                <h1 class="title is-3">Cost &amp; Revenue Summary ({{ contractYearRange }})</h1>
                <div class="tile is-ancestor is-parent">
                    <div class="tile is-vertical">
                        <div class="tile">
                            <div class="tile is-parent">
                                <div class="card" style="width:100%;">
                                    <div class="card-content">
                                        <div class="columns">
                                            <div class="column">
                                                <div class="box">
                                                    <h1 class="title is-6 has-text-grey is-marginless">REVENUE</h1>
                                                    <h2 class="title is-4 has-text-info">{{ totalRevenue  | currency}}</h2>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="box">
                                                    <h1 class="title is-6 has-text-grey is-marginless">COST</h1>
                                                    <h2 class="title is-4 has-text-danger">{{ totalCost  | currency}}</h2>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <div class="box">
                                                    <h1 class="title is-6 has-text-grey is-marginless">{{ profitLoss > 0 ? "PROFIT" : "LOSS" }}</h1>
                                                    <h2 class="title is-4" :class="{
                                                        'has-text-success': profitLoss > 0,
                                                        'has-text-danger' : profitLoss < 0
                                                    }">{{ profitLoss  | currency}}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-content" >
                                            <summarywidget id="revenue" group="summary" :getYears="getYears" :title="totalRevenue | currency" subtitle="REVENUE" subtitle2="COST" :color="['#008FFB','#FF0000']" :revenueCalc="revenueFormula" :costCalc="costFormula"   />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tile is-3">
                                <div class="tile is-vertical">
                                    <div class="tile is-parent">
                                        <div class="tile is-child" >
                                            <div class="card" style="width:100%; height:100%;">
                                                <div class="card-content">
                                                    <h2 class="title is-4">Revenue By Phases</h2>
                                                    <apexchart type=pie :options="donutOptions"  :series="revenueDistributions" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tile is-parent">
                                        <div class="tile is-child" >
                                            <div class="card" style="width:100%; height:100%;">
                                                <div class="card-content">
                                                    <h2 class="title is-4">Cost By Phases</h2>
                                                    <apexchart type=pie :options="donutOptions" :series="costDistributions" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> 
                        <div class="tile is-parent">
                    <div class="tile is-child">
                                        <div class="is-fullwidth" style="width:100%;">
                                            <div v-if="showDetailSummary" class="card" style="overflow:auto">
                                                <table class="table is-bordered is-fullwidth is-hoverable is-narrow">
                                                    <thead class="has-background-light">
                                                        <tr>
                                                            <th rowspan="2" valign="middle" style="vertical-align:middle">Phase</th>
                                                            <th colspan="4" class="has-text-centered">Revenue</th>
                                                            <th class="has-text-centered">Cost</th>
                                                        </tr>
                                                        <tr>
                                                        
                                                            <th>IRU</th>
                                                            <th>IRU O&amp;M</th>
                                                            <th>Leasing</th>
                                                            <th>Total revenue</th>
                                                            <th>Total cost</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="phase in phases" :key="phase">
                                                            <th>{{ phase }}</th>
                                                            
                                                            <td>{{ getRevenueIRU(phase) | currency }}</td>
                                                            <td>{{ getRevenueONM(phase) | currency }}</td>
                                                            <td>{{ getRevenueLeasing(phase) | currency }}</td>
                                                            <th>{{ getTotalRevenue(phase) | currency }}</th>
                                                            <th>{{ getCost(phase) | currency }}</th>      
                                                        </tr>
                                                    </tbody>
                                                    <tfoot class="has-background-light">
                                                        <tr>
                                                            <th colspan="4" class="has-text-right">TOTAL</th>
                                                            <!-- <th>{{ getRevenueIRU() | currency }}</th>
                                                            <th>{{ getRevenueONM() | currency }}</th>
                                                            <th>{{ getRevenueLeasing() | currency }}</th> -->
                                                            <th>{{ getTotalRevenue() | currency }}</th>
                                                            <th>{{ getCost() | currency }}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div class="cards" style="padding:5px 10px;">
                                                <a href="javascript:void" @click.prevent="toggleSummaryDetail">{{ showDetailSummary ? 'Show less' : 'Show more'}}</a>
                                            </div>
                                        </div>
                                    </div>
                </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment-timezone';
moment().tz("Asia/Kuala_Lumpur");
import SummaryWidget from './SummaryWidget'

export default {
    props: ['state', 'getters'],
    components: {
        apexchart: VueApexCharts,
        summarywidget:SummaryWidget ,
    },
    data() {
        return {
            showDetailSummary: false
        };
    },
    computed: {
        donutOptions(){
            return {
                labels: this.phases,
                chart:{
                    type: 'donut',
                },
                legend: {
                    position: 'bottom'
                }
            }
        },
        revenueDistributions(){
            return this.phases.map(phase =>this.getTotalRevenue(phase))
        },
        costDistributions(){
            return this.phases.map(phase =>this.getCost(phase))
        },
        phases() {
            return this.getters.apollo_phases;
        },
        totalCost() {
            return this.getters.apollo_totalCost();
        },
        totalRevenue() {
            return this.getters.apollo_totalRevenue();
        },
        contractYearRange() {
            return this.getters.apollo_contractYearRange.join(' - ');
        },
        getYears() {
            const years = this.getters.apollo_contractYearRange;
            return Array.from(Array(years[1] - years[0] + 1).keys()).map(
                i => parseInt(years[0]) + i
            );
        },
        profitLoss(){
            return this.totalRevenue - this.totalCost
        }
    },
    methods: {
        costFormula(year){
            return this.getCost(false, year)
        },
        revenueFormula(year){
            return this.getTotalRevenue(false, year)  
        },
        toggleSummaryDetail() {
            this.showDetailSummary = !this.showDetailSummary;
        },
        getCost(phase, year) {
            const daterange = year
                ? [moment(year, 'YYYY'), moment(year, 'YYYY').add(1, 'year')]
                : false;
            return this.getters.apollo_totalCost(contract =>contract.phaseIs(phase), daterange);
        },
        getRevenueIRU(phase) {
            return this.getters.apollo_totalRevenue(
                contract => contract.isIncome() && contract.isIRU() && contract.phaseIs(phase),
                contract => contract.totalOtc()
            );
        },
        getRevenueONM(phase) {
            return this.getters.apollo_totalRevenue(
                contract => contract.isIncome() && contract.isIRU() && contract.phaseIs(phase),
                contract => contract.totalArc()
            );
        },
        getRevenueLeasing(phase) {
            return this.getters.apollo_totalRevenue(
                contract => contract.isIncome() && !contract.isIRU() && contract.phaseIs(phase),
                contract => contract.totalMrc()
            );
        },
        getTotalRevenue(phase, year) {
            const daterange = year
                ? [moment(year, 'YYYY'), moment(year, 'YYYY').add(1, 'year')]
                : false;
            return this.getters.apollo_totalRevenue(
                contract => contract.phaseIs(phase),
                contract => contract.totalRevenue(daterange)
            );
        }
    },
    filters: {
        currency(value) {
            return value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
        }
    }
};
</script>
