<template>
        <div style="width:100%; height:100%;">
            <apexchart type=line :options="chartRevenueOptions" :series="getSeries" />
        </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import * as R from 'ramda'

export default {
    data(){
        return {
            height:0
        }
    },
    mounted(){
        // this.height = 400
    },
    components: {
        apexchart: VueApexCharts
    },
    props: [
        'getYears',
        'title',
        'revenueCalc',
        'costCalc',
        'id',
        'group',
        'color'
    ],
    computed: {
        getSeries() {
            return [
                {
                    name: 'REVENUE',
                    data: this.getYears.map(year => {
                        return [year, this.revenueCalc(year)];
                    }),
                },
                {
                    name: 'COST',
                    data: this.getYears.map(year => {
                        return [year, this.costCalc(year)];
                    }),   
                }
            ];
        },
        chartRevenueOptions() {
            return {
                chart: {
                    id: this.id,
                    group: this.group,
                    // type: 'area',
                    // height: this.height,
                    sparkline: {
                        enabled: false
                    }
                },
                stroke: {
                    curve: 'straight'
                },
                fill: {
                    opacity: 1
                },
                labels: this.getYears,
                colors: R.flatten([this.color]),
                tooltip: {
                    y: {
                        formatter: this.$options.filters.currency
                    }
                },
                yaxis:{
                    labels:{
                        formatter: this.$options.filters.currency,
                    },
                    title:{
                        text: 'USD'
                    }
                },
                xaxis:{
                    title: {
                        text: 'Year'
                    },
                    tickAmount: 'dataPoints',
                },
                dataLabels:{
                    enabled: false
                }
            };
        }
    },
    filters: {
        currency(value) {
            return value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
        }
    }
};
</script>

