<template>
    <div class="notification" style="height:100%">
        <h2 class="title is-5">Option #{{optionId + 1}}</h2>
        <div class="columns is-multiline">
            <div class="column is-12">
                <div class="columns">
                    <div class="column">
                        <div class="box">
                            <span class="heading">Landing Points</span>
                            <span class="title is-4">{{ paths.length }}</span>
                        </div>        
                    </div>
                    <div class="column">
                        <div class="box">
                            <span class="heading">Offerable</span>
                            <span class="title is-4">{{ option.capacity.toFixed(2) }} GB</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="timeline">
                    <div v-for="(path,i) in paths" :key="i" class="timeline-item" :class="{ 'noLine': i == 0}" >
                        <div class="timeline-marker" ></div>
                        <div class="timeline-content" >
                            <p class="heading">{{ path }}</p>
                            <p class="help has-text-grey">{{ getSegmentCapacity(i).toFixed(2) | capacityUnit }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import * as R from 'ramda'

export default {
    props:['option','optionId','pathfinder'],
    computed:{
        paths(){
            return this.option.path
        },
        firstPath(){
            return R.head(this.option.path)
        }
    },
    methods:{
        getSegmentCapacity(id){
            return this.pathfinder.getSegmentCapacity(this.option.path[id],this.option.path[id+1])
        }
    },
    filters:{
        capacityUnit(value){
            if(value == 0){
                return ''
            }

            return `${value} GB`
        }
    }
}
</script>

<style lang="scss">
@import "./../../../utils/styles/tm.scss";
@import "~bulma-timeline";

.timeline-item:first-child::before{
    margin-top:20px;
}

.timeline-item:last-child::before{
    height: 30px;
}
</style>


