<template>
  <div v-if="capacities" class="container">
    <route-modal
      :modalVisible="modalVisible"
      :closeModal="closeModal"
      :currentRouteCapacity="currentRouteCapacity"
      :title="title"
      :capacities="capacities"
      :last_updated="last_updated"
    />
    <div :class="{'noscroll': modalVisible}">
      <div class="hero slide-page" id="submarine_routes">
        <section class="section">
          <div class="containerss">
            <div class="columns">
              <div class="column has-text-right"><span class="is-size-7 has-text-grey"> Information as of {{ last_updated }}</span></div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="columns">
                  <div class="column is-8">
                    <map-view
                      :pointSelected="pointSelected"
                      :currentPoint="currentPoint"
                      :registerRoute="registerRoute"
                    />
                    <location-selection
                      :locations="locations"
                      :currentLocation="currentPoint"
                      :selectLocation="pointSelected"
                    />
                  </div>
                  <div class="column">
                    <div v-if="currentPoint" class="card animated faster fadeInUp"  :key="currentPoint">
                      <header class="card-header has-background-dark">
                        <p
                          class="card-header-title has-text-white"
                        >{{ currentPointName }} [{{ selectedRoutes.length }}]</p>
                        <a href="#" class="card-header-icon" aria-label="more options">
                          <span class="icon">
                            <i class="fas fa-angle-down" aria-hidden="true"></i>
                          </span>
                        </a>
                      </header>
                      <div class="card-contents">
                        <table v-if="capacities" class="table is-hoverable" width="100%">
                          <thead>
                            <tr>
                              <th>Route</th>
                              <th class="has-text-right" width="100">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="animated faster fadeInLeft"  :style="{'animation-delay' : `${i*0.1}s`}" v-for="(route,i) in selectedRoutes" :key="routeDisplay(route)">
                              <td>
                                <div @click="selectRoute(route)" style="cursor:pointer">
                                  <span
                                    class="has-text-weight-bold"
                                    style="margin-bottom: 5px;"
                                  >{{ routeDisplay(route) }}</span>
                                  <div class="tags">
                                    <span
                                      v-for="(cable, i) in getCables(route)"
                                      :key="i"
                                      class="tag is-info has-text-weight-bold"
                                      style="height:auto; padding: 3px 8px 2px;"
                                    >
                                      <small style="font-size: 11px;">{{ cable }}</small>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <th class="has-text-right">
                                <h3 v-if="getTotalCapacity(route)" class="title is-size-4">
                                  <span
                                    v-if="getTotalCapacity(route).unit === 'G'"
                                  >{{ getTotalCapacity(route).total }}G</span>
                                  <span v-else>
                                    {{ getTotalCapacity(route).total }}mil
                                    <br>
                                    <small
                                      class="is-size-7"
                                      style="position:relative; top:-0.7em;"
                                    >MIU*km</small>
                                  </span>
                                </h3>
                                <h3 v-else class="title is-size-4 has-text-grey">-</h3>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                        <div v-else class="has-text-centered">
                          <i class="fa fa-loading"></i> loading data...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="hero slide-page has-background-light" id="submarine_utilization">
        <section class="section">
          <div class="card">
            <div class="card-content">
              <h1 class="title is-3 has-text-black">Total Submarine Capacity</h1>
              <div class="columns is-multiline is-desktop">
                <div class="column is-12">
                  <chart-utilization :cables="internationalCables"/>
                </div>
                <div class="column is-12">
                  <div class="level">
                    <div class="level-start"></div>
                    <div class="level-end">
                      <excel-export :data="internationalCables" /> 
                    </div>
                  </div>
                  <div class="card" style="overflow:auto; -webkit-overflow-scrolling: touch;">
                    <table class="table" style="width:100%;">
                      <thead>
                        <tr>
                          <th>Cable</th>
                          <!-- <th class="has-text-right">Investment amount (MYR)</th> -->
                          <th class="has-text-right">Current utilization (%)</th>
                          <th class="has-text-right">Remaining capacity (Gbps)</th>
                          <th class="has-text-right">Total capacity (Gbps)</th>
                          <th class="has-text-right">Total Activated (Gbps)</th>
                          <th class="has-text-right">&nbsp;</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(cable, i) in internationalCables"
                          :key="i"
                          v-if="cable.cable !== ''"
                        >
                          <td>
                            <span class="tooltip is-tooltip-right" :data-tooltip="cable.cable_full_name">
                            {{ cable.cable_full_name }}
                            </span>
                          </td>
                          <!-- <td class="has-text-right">{{ cable.investment_cost }}</td> -->
                          <td class="has-text-right">{{ cable.utilization | toPercent }}</td>
                          <td class="has-text-right">{{ cable.remaining_capacity | formatCapacity}}</td>
                          <td class="has-text-right">{{ cable.total_capacity | formatCapacity }}</td>
                          <td class="has-text-right">{{ (cable.total_capacity - cable.remaining_capacity) | formatCapacity }}</td>
                          <td>
                            <span v-if="getCableDef(cable.cable)" class="tooltip is-tooltip-left is-tooltip-multiline is-preline" :data-tooltip="getCableDef(cable.cable)">
                              <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
                              </svg>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


      <div class="hero slide-page has-background-light" id="submarine_utilization">
        <section class="section">
          <div class="card">
            <div class="card-content">
              <h1 class="title is-3 has-text-black">TM Backhaul Capacity</h1>
              <div class="columns is-multiline is-desktop">
                <div class="column is-12">
                  <chart-utilization :horizontal="true" :height="200" :cables="backhaulCapacities"/>
                </div>
                <div class="column is-12">
                  <div class="level">
                    <div class="level-start"></div>
                    <div class="level-end">
                      <excel-export :data="backhaulCapacities" /> 
                    </div>
                  </div>
                  <div class="card" style="overflow:auto; -webkit-overflow-scrolling: touch;">
                    <table class="table" style="width:100%;">
                      <thead>
                        <tr>
                          <th>Cable</th>
                          <!-- <th class="has-text-right">Investment amount (MYR)</th> -->
                          <th class="has-text-right">Current utilization (%)</th>
                          <th class="has-text-right">Remaining capacity (Gbps)</th>
                          <th class="has-text-right">Total capacity (Gbps)</th>
                          <th class="has-text-right">Total Activated (Gbps)</th>
                          <th class="has-text-right">&nbsp;</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(cable, i) in backhaulCapacities"
                          :key="i"
                          v-if="cable.cable !== ''"
                        >
                          <td>
                            <span class="tooltip is-tooltip-right" :data-tooltip="cable.cable_full_name">
                            {{ cable.cable_full_name }}
                            </span>
                          </td>
                          <!-- <td class="has-text-right">{{ cable.investment_cost }}</td> -->
                          <td class="has-text-right">{{ cable.utilization | toPercent }}</td>
                          <td class="has-text-right">{{ cable.remaining_capacity | formatCapacity}}</td>
                          <td class="has-text-right">{{ cable.total_capacity | formatCapacity }}</td>
                          <td class="has-text-right">{{ (cable.total_capacity - cable.remaining_capacity) | formatCapacity }}</td>
                          <td>
                            <span v-if="getCableDef(cable.cable)" class="tooltip is-tooltip-left is-tooltip-multiline is-preline" :data-tooltip="getCableDef(cable.cable)">
                              <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
                              </svg>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div style="height:200px;"></div>
    </div>
  </div>
</template>

<script>
import ExcelExport from './ExcelExport.vue'
import MapView from './MapView'
import ChartUtilization from './ChartUtilization 2'
import LocationSelection from './LocationSelection'
import capacitySourceData from './services/CapacitySourceData.js'
import RouteModal from './partials/RouteModal'
import RouteCableList from './partials/RouteCableList'
import moment from 'moment-timezone'

moment().tz("Asia/Kuala_Lumpur");
const R = require('ramda')



export default {
  components: { MapView, LocationSelection, ChartUtilization, RouteModal, RouteCableList, ExcelExport},
  data () {
    return {
      currentPoint: false,
      routes: {},
      capacities: false,
      currentRouteCapacity: false,
      modalVisible: false,
      title: '',
      locations: {
        us: 'USA',
        jpn: 'JAPAN',
        hk: 'HONG KONG',
        tha: 'THAILAND',
        cam: 'CAMBODIA',
        eu: 'EUROPE',
        uae: 'UAE',
        ind: 'INDIA',
        sa: 'SOUTH AFRICA',
        my: 'MALAYSIA',
        sg: 'SINGAPORE',
        phi: 'PHILIPPINES',
        ina: 'INDONESIA',
        tai: 'TAIWAN',
        kor: 'KOREA',
        chi: 'CHINA',
      },
    }
  },
  computed: {
    allCables(){
      return this.capacities.allCables()
    },
    capacitiesAllCables(){
      return R.values(this.capacities.allCables())
    },
    currentPointName () {
      return this.locations[this.currentPoint]
    },
    selectedRoutes () {
      return R.pipe(R.values, R.filter(R.includes(this.currentPoint)))(this.routes)
    },
    last_updated () {
      return moment(this.capacities.metadata.as_of).format('D MMM YYYY')
    },
    backhaulCapacities(){
      const cables =  R.filter(data => data.cable_category == 'backhaul', this.allCables)
      return cables
    },
    internationalCables(){
      const cables =  R.filter(data => data.cable_category != 'backhaul', this.allCables)

      const remaining_capacity = R.pipe(R.pluck('remaining_capacity'),R.values,R.sum)(cables)
      const total_capacity = R.pipe(R.pluck('total_capacity'),R.values,R.sum)(cables)

      const total = {
        total: {
          cable: 'TOTAL',
          cable_full_name: 'TOTAL',
          utilization: (total_capacity -  remaining_capacity) / total_capacity,
          remaining_capacity,
          total_capacity,
        }
      }

      return R.mergeAll([cables, total])
    }
  },
  methods: {
    getCableDef(cable){
      const descriptions = {
        AAG: `i. For SC investment, the total capacity is based  on total link capacity
ii. For upgrade capacity, SIMI is using cumulative total segment capacity for each segment that TM invested
iii. Initial investment 63.6Gbps (converted from 24,813.9 MIU) is based on longest segment in AAG (MY-SLO).`,
        APCN2: `i. For SC investment, the total capacity is based  on total link capacity
ii. For upgrade capacity, SIMI is using cumulative total segment capacity For each segment that TM invested`,
        JUSCN: `i. For SC investment, the total capacity is based  on total link capacity
ii. For upgrade capacity, SIMI is using cumulative total segment capacity for each segment that TM invested
iii. Initial investment & IRU (swap) = 6.1 Gbps (converted from 39.11 MIU) is based on MIU conversion calculation of JUSCN cable system.`,
        SAFE: `The conversion of MIU*km to Gbps were based on the longest distance formula i.e. PEN-MLK`,
      }
      return descriptions[cable]
    },
    cableUtilization (cable) {
      var calculatedUtilization = false

      if (cable.total_capacity && cable.remaining_capacity) {
        calculatedUtilization = (cable.total_capacity - cable.remaining_capacity) / cable.total_capacity
      }

      if (calculatedUtilization && calculatedUtilization !== cable.utilization) {
        // console.log('UTILIZATION NOT MATCHED', cable.cable, calculatedUtilization, cable.utilization)
      }

      return calculatedUtilization || cable.utilization
    },
    pointSelected (point) {
      this.currentPoint = point
    },
    registerRoute (routes) {
      this.routes[routes.join('-')] = routes
    },
    routeDisplay (routes) {
      const getDisplayName = (loc) => this.locations[loc]
      const theOtherLeg = R.pipe(R.filter(data => data !== this.currentPoint), R.head, getDisplayName)(routes)
      return `${this.currentPointName} → ${theOtherLeg}`
    },
    loadData () {
      this.$emit('loading')
      // this.$q.loading.show()
      capacitySourceData.load().then(capacities => {
        // this.$q.loasding.hide()
        this.$emit('loaded')
        this.capacities = capacities
      })
    },
    getCables (route) {
      return this.capacities.cables(route)
    },
    getTotalCapacity (route) {
      return this.capacities.totalCapacity(route)
    },
    selectRoute (route) {
      this.title = `${this.locations[route[0]]} - ${this.locations[route[1]]}`
      this.currentRouteCapacity = this.capacities.find(route)
      this.modalVisible = true
    },
    closeModal () {
      this.modalVisible = false
      this.currentRouteCapacity = []
    }
  },
  filters: {
    toPercent (value) {
      const percent = (value * 100).toFixed(1)
      return `${percent}%`
    },
    ringgit (value) {
      const money = Math.round(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'MYR'
      }).replace('MYR', '')
      return money
    },
    formatCapacity(value){
      return parseFloat(value).toLocaleString('en', { maximumFractionDigits: 2 })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>



<style>
.is-preline.is-tooltip-multiline::before{
  white-space: pre-line !important;
}

.swipeEdge{
  /* background: red; */
  width: 50px;
  position: fixed;
  height: 100%;
}

.noscroll{
  overflow:hidden;
  position:fixed;
  opacity: 0;
}

@media screen and (min-width: 1024px) {
  .pages{
    max-height: 100vh;
    overflow-y: scroll;
    border: 1px solid gray;
    /* scroll-snap-type: mandatory; */
    /* scroll-snap-points-y: repeat(3rem);
    scroll-snap-type: y proximity; */
  }

  .hero {
    scroll-snap-align: start;
  }
}

</style>
