<template>
    <div>
        <loading-screen v-if="loading" />
        <div style="padding-top:80px;"></div>
            <div class="container">
                <div class="section">
                    <map-routes v-if="false" />
                    <!-- <utilization-report /> -->
                    <utilization-summary v-if="rawdata" :utilization="utilization" :details="true" />
                </div>
            </div>
        <div style="height:200px;"></div>
    </div>
</template>

<script>

import * as R from 'ramda'
import reporting from './../../reporting'
import LoadingScreen from './../standalone/LoadingScreen.vue'

export default {
    components:{
        LoadingScreen,
        UtilizationReport: () => import(/* webpackChunkName: "utilization-report" */ './UtilizationReportNew'),
        MapRoutes: () => import(/* webpackChunkName: "map-routes" */ './MapRoutes'),
        UtilizationSummary: () => import('./UtilizationSummary')
    },
    data(){
        return {
            rawdata: false,
            miu_routes: false
        }
    },
    mounted(){
        this.loadData()
    },
    computed:{
        loading(){
            return !this.rawdata
        },
        utilization(){
            return reporting(this.rawdata, this.miu_routes)
        },
        commonRoutesSummary(){
            if(!this.rawdata){
                return {}
            }

            return this.utilization.commonRoutesSummary
        }
    },
    methods:{
        loadData(){
            Promise.all([
                this.$store.dispatch('capry.utilization.load'),
                this.$store.dispatch('capry.load.miuroutes')
            ]).then(([res,miu_routes]) => {
                this.rawdata = res.data
                this.miu_routes = miu_routes
            })
        }
    }
}
</script>
