<template>
  <div>
    <nav v-if="$store.getters.userScope('developer')" class="navbar is-fixed-bottom" style="border-top: solid 1px #ccc; ">
      <span class="navbar-item is-size-7">{{ $store.getters.capry_apiUrl() }}</span>
    </nav>
    <nav class="navbar is-fixed-top capry_nav" :class="{'is-warning': !isProduction}" role="navigation" aria-label="main navigation" style="border-bottom: solid 1px #ccc;" >
      <div class="container">
        <div class="navbar-brand">
        <router-link class="navbar-item" to="/" style="border:none;">
          <svg width="181" height="32" viewBox="0 0 181 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#capryLogoClip)">
              <rect width="181" height="49"/>
              <mask id="maskLogo1" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="46" height="49">
              <path d="M45.127 11.9269C42.2523 7.50125 37.9992 4.14643 33.0257 2.38168C28.0522 0.616931 22.6357 0.540645 17.6145 2.16463C12.5933 3.78862 8.2473 7.02232 5.24919 11.3653C2.25108 15.7082 0.768002 20.9183 1.02948 26.1891C1.29095 31.46 3.2824 36.4977 6.69562 40.5226C10.1088 44.5475 14.7535 47.3352 19.9108 48.4542C25.0681 49.5733 30.4505 48.9613 35.2249 46.7129C39.9993 44.4646 43.8995 40.7053 46.322 36.0169L40.6098 33.0654C38.8363 36.4978 35.9809 39.25 32.4856 40.896C28.9903 42.542 25.0499 42.99 21.2742 42.1707C17.4986 41.3515 14.0982 39.3106 11.5994 36.364C9.10063 33.4174 7.64269 29.7293 7.45127 25.8706C7.25984 22.0118 8.3456 18.1975 10.5405 15.0181C12.7354 11.8386 15.9171 9.47122 19.5931 8.28231C23.2691 7.09339 27.2345 7.14924 30.8756 8.44121C34.5167 9.73318 37.6304 12.1892 39.7349 15.4292L45.127 11.9269Z" fill="black"/>
              <path d="M18.5497 37.8062C21.5125 39.2986 24.9011 39.7175 28.1382 38.9916C31.3752 38.2656 34.2604 36.4398 36.3022 33.825C38.3439 31.2103 39.4159 27.9685 39.3354 24.652C39.2549 21.3355 38.027 18.1496 35.8608 15.6369C33.6946 13.1243 30.7243 11.4405 27.4558 10.8724C24.1874 10.3044 20.823 10.8872 17.9361 12.5215C15.0492 14.1559 12.8182 16.7407 11.6234 19.8355C10.4286 22.9303 10.3439 26.3437 11.3836 29.494L17.7834 27.3818C17.2324 25.7121 17.2773 23.903 17.9106 22.2627C18.5438 20.6225 19.7262 19.2525 21.2563 18.3863C22.7864 17.5201 24.5695 17.2112 26.3018 17.5123C28.0341 17.8134 29.6084 18.7058 30.7564 20.0375C31.9045 21.3692 32.5553 23.0578 32.598 24.8155C32.6406 26.5733 32.0725 28.2914 30.9904 29.6772C29.9082 31.063 28.3791 32.0308 26.6634 32.4155C24.9478 32.8003 23.1518 32.5783 21.5815 31.7873L18.5497 37.8062Z" fill="black"/>
              <rect x="26.7017" y="34.9355" width="7.83378" height="13.9764" transform="rotate(-38.0821 26.7017 34.9355)" fill="black"/>
              <rect x="17.7695" y="30.1038" width="5.28608" height="15.8582" fill="black"/>
              </mask>
              <g mask="url(#maskLogo1)">
              <path d="M28.1141 20.5103L62.4511 25.645L61.6983 49.7326L12.645 50.4098L23.3088 36.5763L28.1141 20.5103Z" fill="#358CCB"/>
              <path d="M18.4156 27.87L50.7131 19.8592L14.6519 -9.08374L0.224471 0.396547L-7.93018 29.9983L18.4156 27.87Z" fill="#FF6600"/>
              <path d="M26.0531 21.4034L13.2724 -8.60025L9.13232 -14.7915L52.2892 -20.9827L75.7495 -12.373L68.8073 15.5237L26.0531 21.4034Z" fill="#F68B28"/>
              <path d="M-10.7925 32.6047L-12.6157 32.3084L27.5373 21.5437V48.7682L10.7634 50.6032L-7.67862 44.1218L-10.7925 32.6047Z" fill="#CC0033"/>
              <path d="M25.3159 23.2264L25.8177 20.5102L63.7046 5.38013L60.3973 45.937L30.8443 18.1529L25.3159 23.2264Z" fill="#003399"/>
              </g>
              <path d="M62.8031 30.6985H58.1077C55.839 30.6985 54.0872 30.0954 52.8523 28.8892C51.6174 27.6831 51 25.9456 51 23.6769V10.0215C51 7.75282 51.6174 6.01538 52.8523 4.80923C54.0872 3.60308 55.839 3 58.1077 3H62.8031C65.0431 3 66.7805 3.61744 68.0154 4.85231C69.279 6.05846 69.9108 7.78154 69.9108 10.0215V12.0462C69.9108 12.6779 69.5949 12.9938 68.9631 12.9938H65.3446C64.7415 12.9938 64.44 12.6779 64.44 12.0462V10.28C64.44 9.36102 64.239 8.71487 63.8369 8.34154C63.4636 7.93948 62.8174 7.73846 61.8985 7.73846H58.9692C58.079 7.73846 57.4328 7.93948 57.0308 8.34154C56.6574 8.71487 56.4708 9.36102 56.4708 10.28V23.4185C56.4708 24.3374 56.6574 24.9979 57.0308 25.4C57.4328 25.7733 58.079 25.96 58.9692 25.96H61.8985C62.8174 25.96 63.4636 25.7733 63.8369 25.4C64.239 24.9979 64.44 24.3374 64.44 23.4185V21.6523C64.44 21.0205 64.7415 20.7046 65.3446 20.7046H68.9631C69.5949 20.7046 69.9108 21.0205 69.9108 21.6523V23.6769C69.9108 25.9169 69.279 27.6544 68.0154 28.8892C66.7805 30.0954 65.0431 30.6985 62.8031 30.6985Z" fill="#CC0033"/>
              <path d="M77.496 30.6985H73.3606C72.8437 30.6985 72.6714 30.3969 72.8437 29.7938L79.6499 3.90461C79.8509 3.30154 80.2816 3 80.9422 3H86.6714C87.3893 3 87.8201 3.30154 87.9637 3.90461L94.7699 29.7938C94.9422 30.3969 94.7699 30.6985 94.2529 30.6985H90.1175C89.8016 30.6985 89.5863 30.641 89.4714 30.5261C89.3565 30.3826 89.2704 30.1385 89.2129 29.7938L88.0068 25.0554H79.6068L78.4006 29.7938C78.3432 30.1385 78.2427 30.3826 78.0991 30.5261C77.9842 30.641 77.7832 30.6985 77.496 30.6985ZM83.7422 8.21231L80.6406 20.6615H87.016L83.9145 8.21231H83.7422Z" fill="#CC0033"/>
              <path d="M103.154 30.6985H99.4928C98.8898 30.6985 98.5882 30.3969 98.5882 29.7938V3.90461C98.5882 3.30154 98.8898 3 99.4928 3H110.004C112.244 3 113.981 3.61744 115.216 4.85231C116.451 6.05846 117.068 7.78154 117.068 10.0215V14.1569C117.068 16.3969 116.451 18.1344 115.216 19.3692C113.981 20.5754 112.244 21.1785 110.004 21.1785H104.576C104.231 21.1785 104.059 21.3364 104.059 21.6523V29.7938C104.059 30.3969 103.757 30.6985 103.154 30.6985ZM104.533 16.3969H109.099C110.018 16.3969 110.664 16.2103 111.037 15.8369C111.411 15.4349 111.597 14.7887 111.597 13.8985V10.28C111.597 9.36102 111.411 8.71487 111.037 8.34154C110.664 7.93948 110.018 7.73846 109.099 7.73846H104.533C104.217 7.73846 104.059 7.89641 104.059 8.21231V15.9231C104.059 16.239 104.217 16.3969 104.533 16.3969Z" fill="#CC0033"/>
              <path d="M124.317 44.8276H120.655C120.052 44.8276 119.75 44.5261 119.75 43.923V18.0338C119.75 17.4307 120.052 17.1292 120.655 17.1292H130.907C133.147 17.1292 134.899 17.7466 136.163 18.9815C137.426 20.2163 138.058 21.9538 138.058 24.1938V27.5538C138.058 30.7989 136.78 32.9527 134.224 34.0153V34.1876L138.834 43.7938C139.092 44.483 138.848 44.8276 138.101 44.8276H134.612C134.095 44.8276 133.722 44.7702 133.492 44.6553C133.262 44.5404 133.076 44.2963 132.932 43.923L128.624 34.6184H125.738C125.394 34.6184 125.221 34.7763 125.221 35.0922V43.923C125.221 44.5261 124.92 44.8276 124.317 44.8276ZM125.695 30.2245H129.917C130.836 30.2245 131.51 30.0379 131.941 29.6645C132.372 29.2625 132.587 28.6307 132.587 27.7691V24.4091C132.587 23.5189 132.372 22.8727 131.941 22.4707C131.51 22.0686 130.836 21.8676 129.917 21.8676H125.695C125.379 21.8676 125.221 22.0256 125.221 22.3415V29.7507C125.221 30.0666 125.379 30.2245 125.695 30.2245Z" fill="#CC0033"/>
              <path d="M153.474 44.8276H149.813C149.21 44.8276 148.908 44.5261 148.908 43.923V34.7045L141.025 18.0768C140.91 17.8184 140.91 17.603 141.025 17.4307C141.169 17.2297 141.37 17.1292 141.628 17.1292H146.194C146.654 17.1292 146.998 17.4307 147.228 18.0338L151.536 28.5015H151.837L156.059 18.0338C156.26 17.4307 156.605 17.1292 157.093 17.1292H161.659C161.946 17.1292 162.147 17.2153 162.262 17.3876C162.406 17.5599 162.406 17.7897 162.262 18.0768L154.379 34.7045V43.923C154.379 44.5261 154.077 44.8276 153.474 44.8276Z" fill="#CC0033"/>
              <path d="M122.189 12.9509H120.948C120.793 12.9509 120.742 12.8604 120.793 12.6795L122.835 4.91274C122.895 4.73182 123.025 4.64136 123.223 4.64136H124.942C125.157 4.64136 125.286 4.73182 125.329 4.91274L127.371 12.6795C127.423 12.8604 127.371 12.9509 127.216 12.9509H125.975C125.881 12.9509 125.816 12.9337 125.782 12.8992C125.747 12.8561 125.721 12.7829 125.704 12.6795L125.342 11.258H122.822L122.46 12.6795C122.443 12.7829 122.413 12.8561 122.37 12.8992C122.335 12.9337 122.275 12.9509 122.189 12.9509ZM124.063 6.20505L123.132 9.93982H125.045L124.115 6.20505H124.063Z" fill="#FF6600"/>
              <path d="M134.513 12.9509H133.104C132.424 12.9509 131.898 12.77 131.528 12.4081C131.157 12.0463 130.972 11.525 130.972 10.8444V6.74782C130.972 6.0672 131.157 5.54597 131.528 5.18413C131.898 4.82228 132.424 4.64136 133.104 4.64136H134.513C135.185 4.64136 135.706 4.82659 136.077 5.19705C136.456 5.5589 136.645 6.07582 136.645 6.74782V7.3552C136.645 7.54474 136.551 7.63951 136.361 7.63951H135.275C135.095 7.63951 135.004 7.54474 135.004 7.3552V6.82536C135.004 6.54966 134.944 6.35582 134.823 6.24382C134.711 6.1232 134.517 6.0629 134.242 6.0629H133.363C133.096 6.0629 132.902 6.1232 132.781 6.24382C132.669 6.35582 132.613 6.54966 132.613 6.82536V10.7669C132.613 11.0426 132.669 11.2407 132.781 11.3614C132.902 11.4734 133.096 11.5294 133.363 11.5294H134.242C134.517 11.5294 134.711 11.4734 134.823 11.3614C134.944 11.2407 135.004 11.0426 135.004 10.7669V10.237C135.004 10.0475 135.095 9.95274 135.275 9.95274H136.361C136.551 9.95274 136.645 10.0475 136.645 10.237V10.8444C136.645 11.5164 136.456 12.0377 136.077 12.4081C135.706 12.77 135.185 12.9509 134.513 12.9509Z" fill="#FF6600"/>
              <path d="M142.345 4.91274V12.6795C142.345 12.8604 142.255 12.9509 142.074 12.9509H140.976C140.795 12.9509 140.704 12.8604 140.704 12.6795V4.91274C140.704 4.73182 140.795 4.64136 140.976 4.64136H142.074C142.255 4.64136 142.345 4.73182 142.345 4.91274Z" fill="#FF6600"/>
              <path d="M149.241 12.9509H148.156C147.966 12.9509 147.871 12.8604 147.871 12.6795V6.20505C147.871 6.11028 147.824 6.0629 147.729 6.0629H146.256C146.075 6.0629 145.985 5.97243 145.985 5.79151V4.91274C145.985 4.73182 146.075 4.64136 146.256 4.64136H151.141C151.322 4.64136 151.412 4.73182 151.412 4.91274V5.79151C151.412 5.97243 151.322 6.0629 151.141 6.0629H149.668C149.564 6.0629 149.513 6.11028 149.513 6.20505V12.6795C149.513 12.8604 149.422 12.9509 149.241 12.9509Z" fill="#FF6600"/>
              <path d="M158.034 12.9509H156.935C156.754 12.9509 156.664 12.8604 156.664 12.6795V9.91397L154.299 4.92566C154.264 4.84813 154.264 4.78351 154.299 4.73182C154.342 4.67151 154.402 4.64136 154.48 4.64136H155.85C155.987 4.64136 156.091 4.73182 156.16 4.91274L157.452 8.05305H157.542L158.809 4.91274C158.869 4.73182 158.973 4.64136 159.119 4.64136H160.489C160.575 4.64136 160.635 4.6672 160.67 4.7189C160.713 4.77059 160.713 4.83951 160.67 4.92566L158.305 9.91397V12.6795C158.305 12.8604 158.214 12.9509 158.034 12.9509Z" fill="#FF6600"/>
              <path d="M55.484 36.9618V44.7286C55.484 44.9095 55.3935 45 55.2126 45H54.1142C53.9332 45 53.8428 44.9095 53.8428 44.7286V36.9618C53.8428 36.7809 53.9332 36.6904 54.1142 36.6904H55.2126C55.3935 36.6904 55.484 36.7809 55.484 36.9618Z" fill="#FF6600"/>
              <path d="M61.1521 45H60.0795C59.8985 45 59.8081 44.9095 59.8081 44.7286V36.9618C59.8081 36.7809 59.8985 36.6904 60.0795 36.6904H61.0487C61.2296 36.6904 61.3502 36.7507 61.4105 36.8714L64.021 41.7692H64.0727V36.9618C64.0727 36.7809 64.1632 36.6904 64.3441 36.6904H65.4167C65.5976 36.6904 65.6881 36.7809 65.6881 36.9618V44.7286C65.6881 44.9095 65.5976 45 65.4167 45H64.4733C64.2838 45 64.1459 44.9138 64.0598 44.7415L61.4752 39.9341H61.4235V44.7286C61.4235 44.9095 61.333 45 61.1521 45Z" fill="#FF6600"/>
              <path d="M73.338 45H71.6451C71.4642 45 71.3565 44.9095 71.322 44.7286L69.319 36.9877C69.2587 36.7895 69.3448 36.6904 69.5774 36.6904H70.7922C70.9645 36.6904 71.0679 36.7809 71.1024 36.9618L72.4593 43.32H72.511L73.8679 36.9618C73.9023 36.7809 74.0057 36.6904 74.178 36.6904H75.3928C75.6082 36.6904 75.6943 36.7895 75.6513 36.9877L73.6482 44.7286C73.6051 44.9095 73.5017 45 73.338 45Z" fill="#FF6600"/>
              <path d="M83.7732 45H79.5474C79.3665 45 79.276 44.9095 79.276 44.7286V36.9618C79.276 36.7809 79.3665 36.6904 79.5474 36.6904H83.7732C83.9455 36.6904 84.0317 36.7809 84.0317 36.9618V37.8406C84.0317 38.0215 83.9455 38.112 83.7732 38.112H81.0723C80.9689 38.112 80.9172 38.1594 80.9172 38.2541V39.9341C80.9172 40.0289 80.9689 40.0763 81.0723 40.0763H83.2821C83.4631 40.0763 83.5535 40.1667 83.5535 40.3477V41.2264C83.5535 41.4074 83.4631 41.4978 83.2821 41.4978H81.0723C80.9689 41.4978 80.9172 41.5452 80.9172 41.64V43.4363C80.9172 43.531 80.9689 43.5784 81.0723 43.5784H83.7732C83.9455 43.5784 84.0317 43.6689 84.0317 43.8498V44.7286C84.0317 44.9095 83.9455 45 83.7732 45Z" fill="#FF6600"/>
              <path d="M89.3885 45H88.3159C88.135 45 88.0445 44.9095 88.0445 44.7286V36.9618C88.0445 36.7809 88.135 36.6904 88.3159 36.6904H89.2851C89.466 36.6904 89.5867 36.7507 89.647 36.8714L92.2574 41.7692H92.3091V36.9618C92.3091 36.7809 92.3996 36.6904 92.5805 36.6904H93.6531C93.834 36.6904 93.9245 36.7809 93.9245 36.9618V44.7286C93.9245 44.9095 93.834 45 93.6531 45H92.7097C92.5202 45 92.3824 44.9138 92.2962 44.7415L89.7116 39.9341H89.6599V44.7286C89.6599 44.9095 89.5694 45 89.3885 45Z" fill="#FF6600"/>
              <path d="M100.76 45H99.6748C99.4852 45 99.3905 44.9095 99.3905 44.7286V38.2541C99.3905 38.1594 99.3431 38.112 99.2483 38.112H97.7751C97.5942 38.112 97.5037 38.0215 97.5037 37.8406V36.9618C97.5037 36.7809 97.5942 36.6904 97.7751 36.6904H102.66C102.841 36.6904 102.931 36.7809 102.931 36.9618V37.8406C102.931 38.0215 102.841 38.112 102.66 38.112H101.187C101.083 38.112 101.032 38.1594 101.032 38.2541V44.7286C101.032 44.9095 100.941 45 100.76 45Z" fill="#FF6600"/>
              <path d="M108.764 43.5784H109.682C109.958 43.5784 110.151 43.5224 110.263 43.4104C110.384 43.2898 110.444 43.0917 110.444 42.816V38.8744C110.444 38.5987 110.384 38.4049 110.263 38.2929C110.151 38.1723 109.958 38.112 109.682 38.112H108.764C108.489 38.112 108.295 38.1723 108.183 38.2929C108.071 38.4049 108.015 38.5987 108.015 38.8744V42.816C108.015 43.0917 108.071 43.2898 108.183 43.4104C108.295 43.5224 108.489 43.5784 108.764 43.5784ZM109.953 45H108.506C107.825 45 107.3 44.819 106.929 44.4572C106.559 44.0954 106.374 43.5741 106.374 42.8935V38.7969C106.374 38.1163 106.559 37.595 106.929 37.2332C107.3 36.8714 107.825 36.6904 108.506 36.6904H109.953C110.634 36.6904 111.159 36.8714 111.53 37.2332C111.9 37.595 112.086 38.1163 112.086 38.7969V42.8935C112.086 43.5741 111.9 44.0954 111.53 44.4572C111.159 44.819 110.634 45 109.953 45Z" fill="#FF6600"/>
              </g>
              <defs>
              <clipPath id="capryLogoClip">
              <rect width="181" height="49" fill="white"/>
              </clipPath>
              </defs>
              </svg>

        </router-link>
        <div class="navbar-item" v-if="!isProduction">    
          <span class="tag is-danger">development</span>
        </div>
        <div class="navbar-item" v-else>    
          <span class="tag is-success">production</span>
        </div>
        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          @click="toggleMenu"
          :class="{'is-active' : showMenu}"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div  id="navbarExampleTransparentExample" class="navbar-menu" :class="{'is-active' : showMenu}">
        <div @click="showMenu = false" class="navbar-start">
          <router-link class="navbar-item" to="/dashboard">Dashboard</router-link>
          
          <router-link class="navbar-item" to="/inventory">Inventory</router-link>
          <router-link class="navbar-item" to="/reservations">Reservations</router-link>
          <!-- <router-link v-if="isDeveloper" class="navbar-item" to="/reservations-new">Reservations (New Process)</router-link> -->
          <router-link class="navbar-item" to="/path-finder">Path Finder (β) </router-link>
          <router-link v-if="$store.getters.userScope(['cnd','developer'])" class="navbar-item" to="/cip-dashboard">All Segment Report &nbsp;<span class="tag">CIP ONLY</span> </router-link>
          <!-- <span class="navbar-item"><span class="tag is-uppercase" :class="{
            'is-warning' : mode != 'production',
            'is-danger' : mode == 'production'
          }">{{ mode }} MODE</span></span> -->
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="navbar-item has-dropdown is-hoverable">
              <span>
                Welcome &nbsp; <b> {{ user }}</b>
              </span>
              <div class="navbar-dropdown is-boxed">
                <!-- <a href="javascript:void(0)" v-if="$store.getters.userScope('developer')" @click.prevent="$emit('toggleMode', 'production')" class="navbar-item">Activate Production Mode</a> -->
                <!-- <a href="javascript:void(0)" v-if="$store.getters.userScope('developer')" @click.prevent="$emit('toggleMode', 'staging')" class="navbar-item">Activate staging Mode</a> -->
                <a @click.prevent="logout" class="navbar-item" href="javascript:void(0)">
                  Logout
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data(){
    return {
      showMenu: false
    }
  },
  computed:{
    mode(){
      return this.$store.state.capry.mode
    },
    user(){
      return this.$store.state.login.user.name;
    },
    isLocal(){
      return !process.env;
    },
    isProduction(){
      return this.$store.state.capry.mode == 'production'
    },
    isDeveloper(){
      return this.$store.getters.userScope('developer')
    }
  },
  methods:{
    syncData(){
      this.$store.dispatch('capry.dev.sync')
    },
    logout(){
      this.$emit('logout')
    },
    toggleMenu(){
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style lang="scss">
$tm-orange: #003399;
$tm-orange-light: #f68b28;
$tm-blue: #003399;
$tm-blue-light: #358ccb;
$tm-red: #cc0033;
$grey-light: #b5b5b5;
$capry-orange: #f60;

$background-grey: #e5e5e5;
$primary: $capry-orange;
// @import "bulma";
@import "bulma/sass/utilities/initial-variables.sass";

.capry_nav{
  border-top: solid 4px $primary
}

.navbar-start a.navbar-item{
    color: $grey-light;
}

.router-link-active{
  color: $primary !important;
  border-bottom: solid 2px $primary;
  padding-bottom: 5px;
}
</style>
