<template>
    <div class="card">
        <div class="card-content">
    
            <div class="level ">
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Category</p>
                        <p class="title is-5">{{ category.category.toUpperCase() }}</p> 
                    </div>
                </div>
    
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Type</p>
                        <p class="title is-5">{{ category.type.toUpperCase() }}</p>
                    </div>
                </div>
    
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Reservable Qty</p>
                        <p class="title is-5">{{ category.reservableQty | formatNumber }}</p>
                    </div>
                </div>
    
                <div class="level-item has-text-centered">
                    <div>
                        <p class="heading">Inteface</p>
                        <p class="title is-5 is-capitalized">{{ category.unit }}</p>  
                    </div>
                </div>
    
            </div>
        </div>
        <div class="card-content has-background-light is-size-7 has-text-left">
            <div v-if="editable && !editMode" class="is-pulled-right">
                <a  @click="toggleEdit" class="edit-button button is-text" style="position:relative;top:-20px;right:-20px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#000" stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-linejoin="arcs"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
                </a>
            </div>
            <div v-if="!editMode" class="x">
                <ul>
                    <li class="level-left">
                        <span class="icon" style="margin-right:10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                        </span>
                        <span v-if="!info.equiped_qty" class="has-text-danger">
                            Total equiped qty is not specified
                        </span>
                        <span v-else>
                            <strong>{{info.equiped_qty}} {{category.unit == 'miu' ? 'Miu' : `x ${category.unit}`}}</strong> was equiped
                        </span>  
                    </li>
                    <li class="level-left">
                        <span class="icon" style="margin-right:10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 2a10 10 0 1 0 10 10H12V2zM21.18 8.02c-1-2.3-2.85-4.17-5.16-5.18"/></svg>
                        </span>
                        <span v-if="!category.qty">
                            No remaining capacity
                        </span>
                        <span v-else>
                            <strong>
                                {{category.qty}} {{category.unit == 'miu' ? 'Miu' : `x ${category.unit}`}}
                                <span v-if="category.unit != 'miu'">({{ category.total_capacity | formatNumber }} Gbps)</span>
                            </strong> capacity remaining
                        </span>  
                    </li>
                    <li class="level-left">
                        <span class="icon" style="margin-right:10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                        </span>
                        
                        <span v-if="!category.reservedQty">
                            No reservation
                        </span>
                        <span v-else>
                            <strong>{{category.reservedQty}} {{category.unit == 'miu' ? 'Miu' : `x ${category.unit}`}}</strong> capacity reserved
                        </span>  
                    </li>
                    <li class="level-left">
                        <span class="icon" style="margin-right:10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                        </span>
                        
                        <span v-if="!info.latency">
                            Calculated latency information not specified
                        </span>
                        <span v-else>
                            Calculated latency is <strong>{{ info.latency }} ms</strong>
                        </span>  
                    </li>
                    <li class="level-left"  :class="{'has-text-danger': !info.upgrade.year }" >
                        <span class="icon" style="margin-right:10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        </span>

                        <span v-if="!info.upgrade.year">
                            RFS/Last upgrade date not specified
                        </span>

                        <span v-else>
                            {{ lastUpgrade.fromNow() }} since last upgrade/RFS ({{lastUpgrade.format('MMM YYYY')}})
                        </span>
                    </li>
                    <li class="level-left">
                        <span class="icon" style="margin-right:10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg>
                        </span>
                        <span v-if="!info.notes">No notes</span>
                        <div v-else class="popover is-popover-top">
                            <span class="popover-trigger is-small is-text">
                                {{ info.notes | summary }}
                            </span>
                            <div class="popover-content" style="white-space: pre;">{{ info.notes }}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else>
                <div class="box">
                    <label class="heading">Equiped Quantity</label>
                    <div class="field has-addons">
                        <div class="control">
                            <input type="text" v-model="form.equiped_qty" class="input">
                        </div>
                        <div class="control">
                            <span class="button is-static">{{ category.unit == 'miu' ? 'Miu' : ` x ${category.unit}` }}</span>
                        </div>
                    </div>
    
                    <label class="heading">Latency</label>
                    <div class="field has-addons">
                        
                        <div class="control">
                            <input type="text" v-model="form.latency" class="input">
                        </div>
                        <div class="control">
                            <span class="button is-static">ms</span>
                        </div>
                    </div>

                    <span class="is-pulled-right tooltip" data-tooltip="Date to be used to calculate aging of the capacity">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg>
                    </span>
                    <label class="heading">
                        RFS / Last upgrade
                    </label>
                    <div class="field has-addons">
                        
                        <div class="control">
                            <!-- <input type="text" v-model="form.upgrade.month" class="input"> -->
                            <div class="select">
                                <select v-model="form.upgrade.month" >
                                    <option value="">Month</option>
                                    <option value="Jan">Jan</option>
                                    <option value="Feb">Feb</option>
                                    <option value="Mar">Mar</option>
                                    <option value="Apr">Apr</option>
                                    <option value="May">May</option>
                                    <option value="Jun">Jun</option>
                                    <option value="Jul">Jul</option>
                                    <option value="Aug">Aug</option>
                                    <option value="Sep">Sep</option>
                                    <option value="Oct">Oct</option>
                                    <option value="Nov">Nov</option>
                                    <option value="Dec">Dec</option>
                                </select>
                            </div>
                        </div>
                         <div class="control">
                            <!-- <input type="text" v-model="form.upgrade.year" class="input"> -->
                            <div class="select">
                                <select v-model="form.upgrade.year" >
                                    <option value="">Year</option>
                                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                </select>
                            </div>
                        </div>

                        
                    </div>
                    

                    <div class="field">
                        <div class="control">
                            <span class="heading">Notes</span>
                            <textarea class="textarea" v-model="form.notes"></textarea>
                        </div>
                    </div>
                    
                    <hr>
    
                    <div class="field">
                        <div class="buttons">
                            <button @click="updateInfo" class="button is-primary">Save</button>
                            <button @click="toggleEdit" class="button is-text">cancel</button>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</template>

<script>
const R = require('ramda')
const moment = require('moment')


export default {
    props: ['category', 'capacity','editable'],
    data() {
        return {
            editMode: false,
            form: {
                equiped_qty: 0,
                latency: 0,
                cost: 0,
                upgrade: {
                    month: '',
                    year: ''
                }
            }
        }
    },
    mounted() {
        this.loadSegmentInfo()
    },
    methods: {
        loadSegmentInfo() {
            this.$store.dispatch('capry.segmentinfo.load', this.infoKey)
        },
        toggleEdit() {
            this.editMode = !this.editMode
            this.form = Object.assign({}, this.info)
        },
        updateInfo() {
            this.editMode = false
            this.$store.dispatch('capry.segmentinfo.update', {
                PK: this.infoKey.PK,
                type: this.infoKey.SK,
                details: {
                    segmentinfo: this.form
                },
            })
        }
    },
    computed: {
        lastUpgrade(){
            const date_str = `${this.info.upgrade.month} ${this.info.upgrade.year}`
            
            return moment(date_str, 'MMM YYYY')
        },
        info(){
            const lens = R.lensPath([this.infoKey.PK, this.infoKey.SK, 'details', 'segmentinfo'])
            return Object.assign({}, this.form, R.view(lens,this.$store.state.capry.content) || {})
        },
        infoKey() {
            return {
                PK: this.capacity.PK,
                SK: `segment_info_${this.category.label}`
            }
        },
        years(){
            const thisyear = moment().format('YYYY')
            return [...Array(30).keys()].map(key => thisyear - key)
        }
    },
    filters: {
        formatNumber(value) {
            if (!value) {
                return "-"
            }
            return value.toLocaleString('en-US')
        },
        date(value) {
            return moment(value).format('DD MMM YYYY hh:mm A')
        },
        currency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        },
        formatContractStart(value) {
            return `${value.month || ''} ${value.year || ''}`
        },
        summary(value){
            if(value.length <= 10){
                return value
            }

            return R.take(10, value) + "..."
        }
    }
}
</script>

<style lang="scss">
@import url('~bulma-popover/css/bulma-popover.css');
</style>