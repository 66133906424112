<template>
    <div class="tags has-addons">
        <span class="tag is-info">{{ route[0] }}</span>
        <span class="tag is-light">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" viewBox="0 0 24 24">
                <path d="M7.77 6.76L6.23 5.48.82 12l5.41 6.52 1.54-1.28L3.42 12l4.35-5.24zM7 13h2v-2H7v2zm10-2h-2v2h2v-2zm-6 2h2v-2h-2v2zm6.77-7.52l-1.54 1.28L20.58 12l-4.35 5.24 1.54 1.28L23.18 12l-5.41-6.52z"/>
            </svg>

        </span>
        <span class="tag is-info">{{ route[1] }}</span>
                            
    </div>
</template>

<script>
export default {
    props: ['route'] 
}
</script>
