import * as R from 'ramda'

const Route = require('./Route').Route

export const cablemaps = {
    'SeaMeWe-3': 'smw3',
    'SeaMeWe-4': 'smw4',
    'SAFE/SAT-3/WASC': 'safe',
    'Eastern Africa Submarine System (EASSy)': 'eassy',
    'Japan-U.S. Cable Network (JUS)': 'juscn'
}

const expandAnyToAnyCables = (matrix, cable) => {
    return matrix.filter(data => data.cable === cablemaps[cable.cable_system]).map(m => {
        const route = new Route(m.locations.map(l => ({
            cls: `${l.name}, ${l.country}`,
            pop: ''
        })))

        const summary = cable.summary.map(_summary => {
            const total_capacity = (_summary.qty / m.miu_for_10GB * 10);
            const total_reservable_capacity = (_summary.reservableQty / m.miu_for_10GB * 10);
            return Object.assign({}, _summary, {
                total_capacity,
                total_reservable_capacity,
                miu_for_10GB: m.miu_for_10GB,
                isSufficient: (bw, consumed = {}, ignore = false) => {
                    const temp_miu = consumed[_summary.label] || 0
                    const required_miu = bw / 10 * m.miu_for_10GB
                    return required_miu + temp_miu <= _summary.reservableQty + (ignore ? required_miu : 0)
                },
                consumedQty: (bw) => (bw / 10 * m.miu_for_10GB).toFixed(2),
                original: Object.assign({}, _summary, {
                    route: cable.route
                })
            });
        })

        const _index = `${JSON.stringify(cable)},${route.label}`;

        return R.pick(["original", "id", "cable_system", "route", "total_capacity", "capacity_breakdown", "items", "summary", "label", "_index", "distance", "isSufficient", "hasType", "search"], Object.assign({}, m, cable, {
            total_capacity: cable.capacity_breakdown.miu,
            route,
            label: route.label,
            _index,
            search(keyword) {
                const regstr = keyword.trim().split(/[\%\s]/).map(part => `(?=.*${part})`).join('')
                const reg = new RegExp(`^${regstr}.*`, "i")
                return _index.match(reg)
            },
            summary,
            distance: (m.distance || 0).toFixed(2) + 'KM',
            isSufficient: (bw, type, consumed, ignore) => {
                return R.pipe(
                    R.filter(data => data.type === type.toLowerCase()),
                    R.map(data => data.isSufficient(bw, consumed, ignore)),
                    R.sum,
                    data => data > 0
                )(summary)
            },
            hasType: (type) => {
                return R.pluck('type', summary).join(',').indexOf(type) > -1
            }
        }))
    })
}

export const expandRoutes = (matrix) => (cable) => {
    if (cablemaps[cable.cable_system]) {
        return expandAnyToAnyCables(matrix, cable)
    }

    cable.summary = cable.summary.map(mapSummary)
    cable.isSufficient = (bw, type, consumed, ignore) => {
        return R.pipe(
            R.filter(data => data.type === type.toLowerCase()),
            R.map(data => data.isSufficient(bw, consumed, ignore) || 0),
            R.sum,
            data => data > 0
        )(cable.summary)
    }
    return cable  
}


const mapSummary = (_summary) => {
    if (_summary.unit !== 'miu') {
        return _summary
    }
    const total_capacity = (_summary.qty / 64 * 10);
    const total_reservable_capacity = (_summary.reservableQty / 64 * 10);
    const miu_for_10GB = 64
    return Object.assign({}, _summary, {
        total_capacity,
        total_reservable_capacity,
        miu_for_10GB,
        isSufficient: (bw, consumed = {}, ignore = false) => {
            const temp_miu = consumed[_summary.label] || 0
            const required_miu = bw / 10 * miu_for_10GB
            return required_miu + temp_miu <= _summary.reservableQty + (ignore ? required_miu : 0)
        },
        consumedQty: (bw) => (bw / 10 * 64).toFixed(2),
        original: _summary
    });
}