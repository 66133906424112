<template>
    <div>
        <div class="modal animated fadeIn faster" :class="{'is-active' : open }">
            <div @click="toggleSelection()" class="modal-background"></div>
            <div class="modal-card animated fadeInUp faster">
                <header class="modal-card-head">
                    <p class="modal-card-title">Location</p>
                </header>
                <section class="modal-card-body">
                    <div class="panel">
                        <a  v-for="(location, i ) in locations" :key="i" @click="handleSelectLocation(i)" class="panel-block" :class="{ 'has-background-primary has-text-white' : isSelected(i)}">
                            {{ location }}
                        </a>
                    </div>
                </section>
            </div>
            <button class="modal-close is-large has-background-dark" aria-label="close" @click="toggleSelection()"></button>
        </div>
        <div class="columns is-centered is-mobile is-hidden-tablet">
            <div class="column is-half">
                <button @click="toggleSelection()" class="button is-fullwidth">{{ locations[currentLocation]  || 'Select location'}}</button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
  props: ['currentLocation', 'locations', 'selectLocation'],
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggleSelection () {
      this.open = !this.open
    },
    isSelected (key) {
      return this.currentLocation === key
    },
    handleSelectLocation (key) {
      this.selectLocation(key)
      this.toggleSelection()
    }
  }
}
</script>

