<template>
    <div class="section">
        <div class="notification animated faster is-radiusless" style="position: fixed; top: 0px; left:0px; z-index:99; width: 100%;" :class="notificationAnim">
        <div class="container">
          <button class="delete" @click="hideErrorNotification" style="float:right"></button>
          {{ notification.message }}
        </div>
      </div>
        <div class="card" style="margin-bottom: 40px;">
            <div class="card-content">
                <steps @change="changeStep" :current_step="current_step" />
            </div>
        </div>
        <div v-if="current_step == 1">
            <h1 class="title is-5">New Reservation</h1>
            <div class="columns">
                <div class="column is-4">
                    <reservation-detail :rules="rules" :form="form" @error="showNotification" @success="success" />
                </div>
                <div v-if="canSelectRoute" class="column is-8">
                    <div class="card" v-if="!isLoading" style="height:100%">
                        <div class="card-content">
                            <label class="label">Capacities</label>
                            <input type="hidden" v-model="form.capacities" name="capacities" v-validate="rules.capacities">
                            <span class="is-size-7 has-text-danger">{{ errors.first('capacities') }}</span>
                            <div v-for="(row,i) in form.capacities" :key="i" class="card" :class="validClass(row)">
                                <div class="card-content">
                                    <a @click.prevent="removeCapacity(i)" class="delete is-pulled-right"></a>
                                    <p class="is-size-7">{{row.cable_system}}</p>
                                    <span class="has-text-weight-semibold">{{row.route.sortedLabel}} {{ row.distance? `(${row.distance})` : '' }}</span>
                                    <div class="is-clearfix"></div>
                                    <div style="margin-top:20px;">
                                        <div class="control" v-if="!row.isSufficient(form.bandwidth, form.type, getConsumedQty(row), true)">
                                            <span class="is-size-7">NO SUFFICIENT CAPACITY <a @click.prevent="removeCapacity(i)">[REMOVE]</a> </span>
                                        </div>
                                        <div v-else>
                                            <capacity-tag v-if="!row.selected" :consumed="getConsumedQty(row)" :id="i" @remove="removeCapacity" :capacity="row" :type="form.type" :bandwidth="form.bandwidth" />    
                                            <div v-if="row.selected !== false && row.selected.consumedQty(form.bandwidth)" class="is-size-7">
                                                <span class="field is-grouped">
                                                    <div class="control">
                                                        <div class="tags has-addons">
                                                            <div class="tag is-dark">
                                                                {{row.selected.consumedQty(form.bandwidth)}} {{row.selected.unit == 'miu'? `${row.selected.label.toUpperCase()}` : `x ${row.selected.label.toUpperCase()}` }} 
                                                            </div>
                                                            <a class="tag" @click="row.selected = false">X</a>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <item-config v-if="row.selected" :capacity="row" :qty="row.selected.consumedQty(form.bandwidth)" />
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                        </div>
                        <div class="card-content">
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <div class="control">
                                            <v-select v-model="current_capacity" class="select2" @input="clearSearch" :onSearch="search" :options="capacity_list" :filterable=false >
                                                <template slot="option" slot-scope="option">
                                                    <span class="is-size-7" v-html="highlight(option.cable_system, search_keyword)"></span><br>
                                                    <span v-html="highlight(option.route.sortedLabel, search_keyword)"></span>
                                                </template>
                                            </v-select>
                                            <span class="is-size-7">{{ capacity_list.length}} Capacities</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-1">
                                    <button v-if="current_capacity" @click.prevent="addCapacity" class="button is-small">ADD</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="current_step == 2">
            <div class="columns">
                <div class="column is-6 is-offset-3">
                    <div class="card">
                        <div class="card-content">
                            <h2 class="title is-4">Order Confirmation</h2>
                            <div class="field">
                                
                            </div>
                            <div class="field">
                                <div class="control">
                                    <div class="buttons">
                                        <button class="button is-primary" @click.prevent="nextStep(true)">Complete Step</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import VSelect from 'vue-select'
import {rules} from './../../../Models/Reservation.js'
import {toQty} from './../../../Models/CapacityCalc.js'
import { expandRoutes } from './../../../Models/RouteExpender.js'
import CapacityTag from './CapacityTag.vue'
import moment from 'moment'
import Steps from './Steps.vue'
import ItemConfig from './ItemConfig.vue'
import ReservationDetail from './ReservationDetail'

export default {
    beforeRouteEnter(to,from,next){
        next(vm => {
            if(!vm.$store.getters.capry_usercan('manage_reservation')){
                vm.$router.replace('/')
            }
        })
    },
    components:{
        VSelect,
        CapacityTag,
        Steps,
        ItemConfig,
        ReservationDetail,
    },
    data(){
        return {
            current_step: 1,
            rules,
            current_capacity: '',
            form: {
                contact_person: '',
                customer: '',
                salesforce_id: '',
                bandwidth: 0,
                reservation_type: 'leasing',
                type: 'asset',
                capacities: [],
            },
            // miu_routes:[],
            notification: {
                status: false,
                message: '',
                timer: false
            },
            search_keyword: '',
        }
    },
    computed:{
        routes(){
            return this.$store.state.capry.routes
        },
        user(){
            return this.$store.state.login.user.name  
        },
        miu_routes(){
            return this.$store.state.capry.miu_routes
        },
        notificationAnim(){
            if(!this.notification.status){
                return {'hide': true}
            }

            if(this.notification.status == 'show'){
                return {'slideInDown' : true , [this.notification.type || 'is-danger'] : true}
            }

            if(this.notification.status == 'hide'){
                return {'slideOutUp' : true, [this.notification.type || 'is-danger'] : true}
            }
        },
        canSelectRoute(){
            return this.form.bandwidth > 0 && this.form.type != ''
        },
        isLoading(){
            return this.routes.length == 0
        },
        capacities(){
            return this.routes
        },
        expanded_capacity(){
            return R.pipe(
                R.map(expandRoutes(this.miu_routes)),
                R.flatten
            )(this.capacities)
        },
        capacity_list(){
            return this.expanded_capacity
            .filter(capacity => capacity.hasType(this.form.type))
            .filter(data => this.addedRoutes.indexOf(data.route.sortedLabel) == -1) //revemo added routes
            .filter(capacity => capacity.isSufficient(this.form.bandwidth, this.form.type, this.getConsumedQty(capacity)))
            .filter(capacity => capacity.search(this.search_keyword))
        },
        addedRoutes(){  // used to filter-out capacity list 
            return this.form.capacities.map(c => c.route.sortedLabel)
        },
        consumed_qty(){ // to prepare mapping for consumed capacity.. for shared capacity... should become class object
            const calcConsumed = R.pipe(
                R.groupBy(R.prop('id')),
                R.map(R.pipe(
                    R.pluck('selected'),
                    R.filter(data => data),
                    R.groupBy(data => data.label),
                    R.map(R.pipe(
                        R.map(data => data.consumedQty(this.form.bandwidth)),
                        R.sum
                    ))
                )))
            return calcConsumed(this.form.capacities)
        }
    },
    mounted(){
        this.loadMiuRoutes()
        this.$store.dispatch('capry.loadRoutes')
    },
    methods:{
        success(msg){
            this.showNotification(msg, 'is-success')
            setTimeout(() => {
                this.$router.push({name: 'reservation.v2.show', params: { id:this.form.salesforce_id}})
            },1000)
        },
        changeStep(step){
            this.current_step = step
        },
        async nextStep(complete_step_1){
            if(!complete_step_1){
                this.current_step = 2;
            }else{
                await this.$validator.validateAll()
                    if(this.$validator.errors.any()){
                    this.showNotification()
                }else{
                    this.confirmed_order = true;
                    this.current_step = 2
                }
            }
        },
        highlight(value, keyword){
            if(keyword.length < 1){
                return value
            }
            const reg = new RegExp(`(${keyword.split(/[\s%]/).join("|")})`,"ig")
            const matches = value.match(reg)
            
            if(!matches){
                return value
            }
            return matches.filter(part => part.length > 0).reduce((output,part) => {
                const reg2 = new RegExp(`(${part})(?!\\*)`)
                return output.replace(reg2, `*$1*`)
            }, value).replace(/\*([^\*]+)\*/g, '<span class="has-background-warning has-text-dark">$1</span>' )

        },
        search(keyword){
            this.search_keyword = keyword
        },
        clearSearch(){
            this.search_keyword = ''  
        },
        getConsumedQty(capacity){
            return this.consumed_qty[capacity.id] || {}
        },
        filterFunction(option, label, search){
            return option.search(search)
        },
        loadMiuRoutes(){
            this.$store.dispatch('capry.load.miuroutes')
        },
        validClass(capacity){
            if(!capacity.isSufficient){
                return {
                    'has-background-danger': true
                }
            }
            return {
                'has-background-warning': !capacity.isSufficient(this.form.bandwidth, this.form.type, this.getConsumedQty(capacity), true) //|| !capacity.hasType(this.form.type)
            }
        },
        selectedType(type){
            return this.form.type === type
        },
        addCapacity(){
            this.form.capacities.push(Object.assign({},this.current_capacity,{
                selected: false
            }))
            this.current_capacity = ''
            this.$validator.validate('capacities')
        },
        removeCapacity(idx){
            this.form.capacities = R.remove(idx,1, this.form.capacities)
            this.$validator.validate('capacities')
        },
        selectReservationType(type){
            this.form.reservation_type = type
            const type_map = {
                'leasing' : 'asset',
                'iru' : 'stock'
            }
            this.form.type = type_map[type]
            this.$validator.validate('type')
        },
        async handleSubmit(){
            await this.$validator.validateAll();

            if(this.$validator.errors.any()){
                this.showNotification()
                return Promise.resolve()
            }else{
                return this.$store.dispatch('capry.reservations.submit', this.submission_form).then(res => {
                    this.showNotification( res.data.message , 'is-success')
                    setTimeout(() => {
                        this.$router.push({name: 'reservation.v2.show', params: { id:this.submission_form.salesforce_id}})
                    },1000)
                }).catch(err => {
                    console.log(err)
                    this.showNotification(err.response.data.message || `${err}`)
                })
            }
        },
        hasError(name){
            return { 
                'is-danger' : this.errors.first(name),
                'has-text-danger' : this.errors.first(name),
            }
        },
        hideErrorNotification(){
            this.notification = {
                status: 'hide'
            }
        },
        showNotification(msg, type){
            if(this.notification.timer){
                clearTimeout(this.notification.timer)
            }
            this.notification = {
                status: 'show',
                message: msg || 'Validation error. Please check the form',
                timer: setTimeout(() => { this.hideErrorNotification() } , 3000),
                type

            }
        }
    }
}
</script>


<style>

.is-danger .dropdown-toggle{
    border-color: rgba(255, 17, 17, 0.6)
}

.v-select , .v-select .dropdown-toggle{
    width: 100%;
}

.is-clickable{
    cursor:pointer !important;
}

.has-border-danger{
    box-shadow: 0 2px 3px rgba(255, 17, 17, 0.6), 0 0 0 1px rgba(255, 17, 17, 0.6)
}

.hide{
  display: none;
}
.capry_radio {
  display: inline-block;
  padding: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
}

.capry_radio.is-danger{
  border-color: #ff0000 !important;
}

.easeInOutBack {
    -webkit-animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550) !important;
    animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550)  !important;
}

.easeOutBack {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275) !important;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275)  !important;
}

</style>
