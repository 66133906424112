const R = require('ramda')

const BWmultiplier = {
    'STM1': 0.15625,
    'STM4': 0.625,
    'STM16': 2.5,
    'STM64': 10,
    '10Gbps': 10,
    '100Gbps': 100, 
    'miu': 0.15625,
}

module.exports.BWmultiplier = BWmultiplier

const toGbps = (qty, unit) => {
    const total = ((BWmultiplier[unit] || 1) * qty)
    return total
}

module.exports.toGbps = toGbps

module.exports.toQty = (bw) => {
    return R.pipe(R.map(mul => bw/mul), R.filter(data => data >= 1),R.map(data => Math.ceil(data)))(BWmultiplier)
}