<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <div class="is-pulled-right">
          <a @click="$emit('close')" class="delete is-large">Close</a>
        </div>
        <h1 class="title is-4">{{ cable.cableName }}</h1>
        <div class="level">
          <div class="level-item">
            <div class="box">
              <table class="table is-fullwidth">
                <tbody>
                  <tr>
                    <th class="has-text-right">Total</th>
                    <td class="has-text-right">{{ cable.allSegments.total_capacity }} Gbps</td>
                  </tr>
                  <tr>
                    <th class="has-text-right">Activated</th>
                    <td class="has-text-right">{{ cable.allSegments.activated_capacity.toFixed(3)}} Gbps</td>
                  </tr>
                  <tr>
                    <th class="has-text-right">Remaining</th>
                    <td class="has-text-right">{{ cable.allSegments.remaining_capacity}} Gbps</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="level-item">
            <apexchart type="radialBar" :options="donutOptions" :series="[cable.allSegments.utilization]" /> 
          </div>
        </div>

        <div class="field">
          <div class="control">
            <div class="field">
                <input type="checkbox" id="showAll" class="is-small switch is-rounded" checked="checked" name="showAll" v-model="showAll">
                <label for="showAll">Show all equiped capacities</label>
            </div>
          </div>
        </div>
        <div class="card">
          <table class="table is-fullwidth is-size-7 is-bordered">
            <thead>
              <tr>
                <th style="width:150px;">Segment</th>
                <th >
                  Capacities
                </th>
                <th style="width:100px;" class="has-text-right">Total Remaining (Gbps)</th>
                <th style="width:100px;" class="has-text-right">Total Equiped (Gbps)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in groupedItems" :key="item.PK">
                <td>
                  {{ item.label }}
                  <span class="help has-text-grey-light" v-if="item.based_on">Calculated based on {{ item.based_on }}</span>
                </td>
                <td>
                  <div class="field is-grouped is-grouped-multiline">
                    <div v-for="(c,i) in filteredTags(item.list)" :key="i" class="control">
                      <div class="tags has-addons">
                        <span class="tag is-info is-light">
                          {{ c.qty }} / {{ c.equiped_qty }}
                        </span>
                        <span class="tag is-light">
                          {{ c.unit }} {{ c.type}}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <th class="has-text-right">
                  {{ item.total_capacity | formatCapacity }}
                </th>
                <th class="has-text-right">
                  {{ item.total_equiped | formatCapacity }}
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">Total</th>
                <th class="has-text-right">{{ total }}</th>
                <th class="has-text-right">{{ totalEquiped }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import apexchart from 'vue-apexcharts'
export default {
  components:{
    apexchart
  },
  props:{
    cable: {
      type: Object
    }
  },
  data(){
    return {
      showAll: true,
      donutOptions: {
        type: 'radialBar',
        labels: ['Utilization'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          radialBar: {
            dataLabels:{
              name: {
                show: true,
                offsetY: -5
              },
              value:{
                fontSize: '20px',
                offsetY: 5
              }
            },
          }
        }
      }
    }
  },
  computed:{
    groupedItems(){
      return this.cable.groupedItems
    },
    total(){
      return R.pipe(R.pluck('total_capacity'), R.sum)(this.groupedItems).toFixed(3)
    },
    totalEquiped(){
      return R.pipe(R.pluck('total_equiped'), R.sum)(this.groupedItems).toFixed(3)
    }
  },
  methods:{
    filteredTags(list){
      const cond = this.showAll ? 
        ((d) => d.equiped_qty || d.qty)
        : ((d) => d.qty > 0)

      return list.filter( d => cond(d) )
    }
  },
  filters:{
    formatCapacity(v){
      return v.toFixed(3)
    }
  }
}
</script>