const R = require('ramda')

module.exports.ClsLabel = function (landing_point) {
    return R.pipe(
        data => [data.pop, data.cls].filter(data => data != 'no-cls'),
        R.filter(data => data),
        R.join(", ")
    )(landing_point)
}

module.exports.Route = function (landing_points) {
    this.label = R.pipe(R.sortBy(R.prop('cls')), R.map(R.pipe(
        data => [data.pop, data.cls].filter(data => data != 'no-cls'),
        R.filter(data => data),
        R.join(", "),
    )), R.join(' → '))(landing_points)

    this.sortedLabel = R.pipe(R.sortBy(R.prop('dist')), R.map(R.pipe(
        data => [data.pop, data.cls].filter(data => data != 'no-cls'),
        R.filter(data => data),
        R.join(", "),
    )), R.join(' → '))(landing_points)
    // this.toString = () => this.label

    this.landing_points = landing_points
}
