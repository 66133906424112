<template>
    <div class="modal animated faster " :class="{'slideInUp' : modalVisible , 'slideOutDown' : !modalVisible , 'is-active' : title != ''}">
      <div class="modal-background has-background-white"></div>
        <div class="card card-container"  style="max-width:1000px; width:100%;" >
          <div class="card" style="padding:10px 10px 0px;">
            <a href="#" @click.prevent="handleCloseModal()" style="float:left">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
              </svg>
              </a>
              <div class="level">
                <div class="level-item"><h1 class="title is-5" style="margin-bottom:10px;">{{ title }}</h1></div>
              </div>
            
          </div>
          <div class="card-content" style=" max-width:1000px; width:100%; height:100%;overflow:scroll; -webkit-overflow-scrolling: touch;">
              <chart :chartdata="currentRouteCapacity || []"  :capacities="capacities" />
              <div style="height:50px;" ></div> 
              <div class="column has-text-right"><span class="is-size-7 has-text-grey"> Information as of {{ last_updated }}</span></div>
          </div>
        </div>
    </div>
</template>


<script>
import Chart from '../Chart'

export default {
  components: { Chart },
  props: ['modalVisible', 'closeModal', 'currentRouteCapacity', 'title', 'capacities', 'last_updated'],
  data () {
    return {
    }
  },
  computed: {
    
  },
  methods: {
    handleCloseModal () {
      this.closeModal()
    }
  }
}
</script>


<style>
.card-container{
  height: 800px;
}
@media screen and (max-height: 800px) {
  .card-container{
    height: 100% !important;
  }
}
</style>

