<template>
    <div>
        <h3 class="title is-4">Apollo Info</h3>
        <div class="field">
            <label class="label">Phase</label>
            <div class="control">
                <div class="select" :class="hasError('phase')">
                    <select name="phase" v-model="form.phase" v-validate="rules.phase" >
                        <option v-for="phase in phases" :key="phase" :value="phase">{{ phase }}</option>
                    </select>
                </div>
            </div>
        </div>

        <label class="label">Bluetel Circuit ID [{{qty}}]</label>
        <div v-for="(cct_id, i) in cctRange" :key="i" class="field">
            <div class="control">
                <input :name="`btn_cct_ids_${i}`" type="text" class="input" v-model="form.btn_cct_ids[i]" v-validate="rules.btn_cct_ids" :class="hasError(`btn_cct_ids_${i}`)" :placeholder="`BTN Circuit ${i}`">
            </div>
        </div>
        <div class="buttons">
            <button @click.prevent="$emit('addCircuit')" class="button">Add Circuit</button>
        </div>
    </div>
</template>
 
<script>
import VueNumeric from 'vue-numeric'
import {apollo_rules} from './../../../Models/SubmarineCable.js'
import MonthYear from './../../comp/MonthYear.vue'
import * as R from 'ramda'
export default {
    props:['qty'],
    inject: ['$validator'],
    components: {
        MonthYear,
        VueNumeric
    },
    watch:{
        form:{
            handler(newValue){
                this.$emit('update', newValue)
            },
            deep: true
        },
        rules: apollo_rules,
        qty(val){
            this.form.btn_cct_ids = R.take(val, this.form.btn_cct_ids)
        }
    },
    data(){
        return {
            phases: ['AP1', 'AP1.5', 'AP2', 'AP3', 'AP3.5'],
            form: {
                phase: '',
                btn_cct_ids: [],
            },
            rules: apollo_rules
        }
    },
    computed:{
        cctRange(){
            if(this.qty == ''){
                return [];
            }
            
            return (Array(parseInt(this.qty)))
        }
    },
    methods:{
        hasError(name){
            return { 'is-danger' : this.errors.first(name)}
        },    
    },
    mounted(){
        this.$emit('update', this.form)
    }
}
</script>

