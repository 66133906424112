const R = require('ramda')

const findBottleneckPoints = (routes) => {

    if (routes.length <= 1) {
        return false
    }

    return R.pipe(
        R.flatten,
        R.groupBy(R.identity),
        R.map(R.length),
        R.filter(total => total == routes.length),
        R.keys
    )(routes)
}

const getSegmentPair = R.pipe(
    R.map(R.pipe(R.map(a => [a, a]), R.flatten, R.drop(1), R.dropLast(1))),
    R.flatten,
    R.splitEvery(2)
)

const getPointSegments = (routes, point) => R.pipe(
    getSegmentPair,
    R.filter(R.includes(point)),
    R.uniq,
)(routes)

const groupPointSegments = (pairs, point) => {

    const data = [
        R.groupBy(R.head, pairs)[point] || [],
        R.groupBy(R.last, pairs)[point] || [],
    ]
    return data
}

const getBottleneckSegmnets = (routes) => {
    const points = findBottleneckPoints(routes)
    if (!points) {
        return []
    }
    const x = R.pipe(
        R.map(point => getPointSegments(routes, point)),
        R.flatten,
        R.splitEvery(2),
        pairs => points.map(point => groupPointSegments(pairs, point)),
        R.reduce((all, current) => {
            return all.concat(current)
        }, []),
        R.filter(data => data.length > 0),
        R.map(R.uniq)
    )(points)
    return x
}

const getSegmentCapacity = routes => pair => {
    return routes[pair[0]][pair[1]].capacity
}

const getBottleneckCapacity = routes => bottlenecks => {
    return R.pipe(
        R.map(R.pipe(
            R.map(R.uniq),
            R.map(getSegmentCapacity(routes)),
            R.sum
        )),
        R.sortBy(R.identity),
        R.head,
        // data => data || 0
    )(bottlenecks)
}

module.exports = {
    findBottleneckPoints,
    getSegmentPair,
    getPointSegments,
    groupPointSegments,
    getSegmentCapacity,
    getBottleneckCapacity,
    getBottleneckSegmnets,
}