<template>
    <div class="card-content">
        <div class="level">
            <div class="level-start">
                <span class="title is-4 ">{{ offering.cable_abbrv }}</span>
                <!-- <span v-for="(route, i) in getRoute(offering)" :key="i" class="heading">{{ route  }}</span> -->
                
                <div v-if="getRoute(offering).length > 1" class="field" style="margin-top: 5px;">
                    <div class="control">
                        <div class="select is-small is-fullwidth">
                            <select v-model="selectedIndex">
                                <option v-for="(route, i) in getRoute(offering)" :key="i" :value="i">{{ route }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span class="is-size-7">{{ getRoute(offering)[selectedIndex] }}</span>
                </div>
            </div>
            <div class="level-start">
                <span v-for="(route,i) in getTopRoute(offering).filter((data,i) => selectedIndex == i)" :key="i" class="title is-4">{{ route.capacity }}GB</span>
            </div> 
            <div class="level-end">
                <a @click="viewDetails(getTopRoute(offering),selectedIndex)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><path d="M12 8l4 4-4 4M8 12h7"/></svg>
                </a>
            </div> 
        </div>
        <div v-if="details">
            <div v-for="(option,i) in details.options" class="box" :key="i">
                <div class="level">
                    <div class="level-start">
                        <div class="tags">
                            <span v-for="(path,j) in getPaths(option.path)" :key="j" class="tag" :class="getTagClass(path)">{{ path | removeCable(offering) }}</span>
                        </div>
                    </div>
                    <div class="level-end">
                        {{ option.capacity }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'

export default {
    props:['offering'],
    data(){
        return {
            selectedIndex: 0,
            details: false,
        }
    },
    methods:{
        getTopRoute(offering){
            return R.pipe(
                R.prop('routes'),
                R.sort(R.descend( data => parseFloat(data.capacity))),
            )(offering)
        },
        getRoute(offering){
            return R.pipe(
                this.getTopRoute,
                R.map(R.pipe(
                    R.prop('route'),
                    R.map(str => str.replace(`${offering.cable},`, '')),
                    R.join(' → '),
                ))
            )(offering)
        },
        viewDetails(data, index){
            this.details = data[index]
        },
        getPaths(paths){
            return R.pipe(
                R.map(data => [data, ' → ']),
                R.flatten,
                data => R.take(data.length - 1, data)
            )(paths)
        },
        getTagClass(path){
            if(path !== ' → '){
                return "is-grey-lighter"
            }
        }
    },
    filters:{
        removeCable(str, offering){
            return str.replace(`${offering.cable},`, '')
        }
    }
}
</script>