module.exports = {
    'Asia-America Gateway (AAG) Cable System': 'AAG',
    'APCN-2': 'APCN2',
    'Bay of Bengal Gateway (BBG)': 'BBG',
    'Batam Dumai Melaka (BDM) Cable System': 'BDM',
    'Asia Submarine-cable Express (ASE)/Cahaya Malaysia': 'CM',
    'Dumai-Melaka Cable System': 'DMCS',
    'Japan-U.S. Cable Network (JUS)': 'JUSCN',
    'Malaysia-Cambodia-Thailand (MCT) Cable': 'MCT',
    'Nugate': 'NUGATE',
    'SAFE/SAT-3/WASC': 'SAFE',
    'SeaMeWe-3': 'SMW3',
    'SeaMeWe-4': 'SMW4',
    'SeaMeWe-5': 'SMW5',
    'Asia Africa Europe-1 (AAE-1)': 'AAE-1',
    'Europe India Gateway (EIG)': 'EIG',
    'FASTER': 'FASTER',
    'IMEWE': 'IMEWE',
    'Backhaul': 'SG BH',
}