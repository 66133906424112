<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content" style="width:60vw;" >
      <div class="box">
        <button @click="$emit('close')" class="is-pulled-right delete is-large"></button>
        <h1 class="title is-4">{{ route.name }}</h1>
        <div v-if="!route.miuOnly" class="columns">
          <div class="column is-4">
            <div v-if="selectedPoint === false" class="notification">
              Click on nodes to view details
            </div>
            <div v-else>
              <table v-for="(segment,i) in breakdown" :key="i" class="card table is-fullwidth">
                <tbody>
                  <tr>
                    <th>{{ segment.label }}</th>
                  </tr>
                  <tr>
                    <td>
                      <div class="tags">
                        <span v-for="(item,j) in segment.list" :key="j" class="tag is-info">
                          {{ item.qty }} x {{ item.unit }} {{ item.type }}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>TOTAL: {{ segment.total_capacity }} Gbps</th>
                  </tr>
                </tbody>
                
              </table>
            </div>
          </div>
          <div class="column">
            <vue-flowy ref="flow" :chart="flow"></vue-flowy>
          </div>
        </div>

        <div v-if="route.miuOnly" class="columns">
          <div class="column">
            <div class="tags has-addons">
              <span class="tag is-dark">Total MIU</span>
              <span class="tag">{{ route.total_miu | formatNumber }}</span>
              
            </div>
            <table class="table is-fullwidth is-hoverable is-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th class="has-text-right" v-for="(col,i) in allPath.to" :key="`col-${i}`">{{ col}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row,i) in allPath.from" :key="`row-${i}`">
                  <th>{{ row }}</th>
                  <td class="has-text-right" v-for="(col,j) in allPath.to" :key="`col-${i}-${j}`">{{ getMatrixValue(row, col).capacity | formatNumber }} GB</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <debugger :data="route" />
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import JsonTree from 'vue-json-tree'
import {VueFlowy, FlowChart} from 'vue-flowy'
import Debugger from './../../common/debugger'

export default {
  components:{
    Debugger,
    JsonTree,
    VueFlowy,
  },
  props:{
    route: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  data(){
    return {
      debug: false,
      flow: new FlowChart({ direction: 'TB'}),
      points: [],
      selectedPoint: false,
    }
  },
  mounted(){
    this.drawFlow()
  },
  beforeDestroy(){
    this.flow.destroy()
  },
  computed:{
    allPath(){
      return {
        from: R.pipe(R.pluck('path'),R.map(d => d[0]),R.uniq)(this.route.routes),
        to: R.pipe(R.pluck('path'),R.map(d => d[1]),R.uniq)(this.route.routes),
      }
    },
    route_path(){
      return R.head(this.route.points) || []
    },
    allSegments(){
      return R.pipe(
        R.pluck('segmentCapacities'),
        R.flatten,
        R.indexBy(d => d.path.join(' ➝ '))
      )(this.route.routes)
    },
    allPoints(){
      return R.pipe(
        R.pluck('path'),
        R.values,
        R.flatten,
        R.uniq,
      )(this.allSegments)
    },
    matrix(){
      return R.xprod(this.allPoints, this.allPoints)
    },  
    routesMap(){
      return R.pipe(
        R.prop('routes'),
        R.indexBy( d => this.getMatrixKey(d.path[0], d.path[1]))
      )(this.route)
    },
    pointMaps(){
      return R.indexBy(d => d.id, this.points)
    },
    breakdown(){
      console.log("SELECTED", this.selectedPoint)
      return R.pipe(
        R.filter(d => 
          R.pipe(
            R.filter(point => d.label.indexOf(point) > -1 && d.label.indexOf(this.selectedPoint) > -1 ),
            list => list.length > 1
          )(this.allPoints)
        )
      )(this.route.groupedItems)
    }
  },
  methods:{
    getMatrixKey(row, col){
      return R.pipe(
        R.sortBy(R.identity),
        d => {
          if(d[0] == d[1]){
            return []
          }
          return d
        },
        R.join("-")
      )([row,col])
    },
    getMatrixValue(row, col){
      return this.routesMap[this.getMatrixKey(row,col)] || {}
    },
    drawFlow(){
      this.flow = new FlowChart({ direction: 'TB'})
      this.points = this.allPoints.map((point,i) => {
        const pointNode = this.flow.addElement(point)
        pointNode.on('click',() => {
          this.clickNode(point,i)
        })
        return pointNode
      })

      R.map(segment => {
        this.pointMaps[segment.path[0]].leadsTo(this.pointMaps[segment.path[1]] , { label: segment.capacity + ' Gbps'})
      }, this.allSegments)

      this.$nextTick(() => {

        if(!this.$refs.flow){
          return
        }

        const svg = this.$refs.flow.$el.getElementsByTagName('svg')[0]
        const width = svg.getAttribute('width')
        const height = svg.getAttribute('height')
        svg.removeAttribute('height')
        svg.setAttribute('style', 'max-width:100%')
        svg.setAttribute('viewBox' , `0 0 ${width} ${height}`)
      })
      
    },
    clickNode(point, i){
      this.selectedPoint = point
    }
  },
  filters:{
    fromTo(v){
      return v.join(" ➝ ");
    },
    formatNumber(v){
      return v.toLocaleString()
    }
  }
}
</script>

<style lang="scss">
.flow-chart {
  g.edgeLabel {
    g.label{
      font-size: 0.8rem !important;
    }
  }
}
</style>