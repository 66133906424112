<template>
    <div class="content" >
        <a v-if="editable" @click="toggleEdit" class="edit-button button is-text is-pulled-right" style="z-index:10;">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#000" stroke="#ffffff" stroke-width="3" stroke-linecap="round" stroke-linejoin="arcs"><path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path><polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon></svg>
        </a>
        <div v-if="content" v-html="parse(displayedContent)"></div>
        <div v-else class="notification has-text-centered">
            <span class="title is-4 has-text-grey-light">No content yet</span>
        </div>
        <div class="modal is-active" v-if="editMode">
            <div class="modal-background"></div>
            <div class="modal-card " style="width: 80vw; ">
                <div class="modal-card-body has-background-light" style="height: 80vh;">
                    <div class="columns">
                        <div class="column has-background-light">
                            <textarea class="textarea" style="min-height:calc(80vh - 120px)" v-model="newContent"></textarea>
                            <span class="help">
                                Guide for markdown can be view <a href="https://www.markdownguide.org/cheat-sheet/" target="_BLANK">here</a>
                            </span>
                        </div>
                        <div class="column">
                            <div class="box">
                                <div style="max-height:500px; min-height:calc(80vh - 170px);  overflow: auto;">
                                    <div v-html="parse(displayedContent)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttons" style="position:absolute; bottom: 20px;">
                        <a @click="saveChanges" class="button is-primary">Save changes</a>
                        <button @click="toggleEdit" class="button is-text" >Close</button>
                    </div>
                </div>
            </div>
            <button @click="toggleEdit" class="modal-close is-large" aria-label="close"></button>
        </div>
    </div>
</template>


<script>
var showdown = require('showdown');
var converter = new showdown.Converter({
    tables:true,
})

export default {
    props: ['content','editable'],
    data(){
        return {
            editMode: false,
            newContent: ''
        }
    },
    computed:{
        displayedContent(){
            return this.editMode ? this.newContent : (this.content || {}).content
        }
    },
    methods:{
        parse(value){
            return converter.makeHtml(value)
        },
        toggleEdit(){
            this.newContent = (this.content || {}).content
            this.editMode = !this.editMode
        },
        saveChanges(){
            this.$emit('onsave', this.newContent)
            this.toggleEdit()
        }
    }
}
</script>


<style scoped>

</style>

