<template>
    <div>
        <div class="columns"> 
            <div class="column" style="overflow:auto;">
                    <div class="columns">
                        <div class="column">
                        
                            <div style="height:100%;">
                                <div class="notification is-fullheight" style="height:100%">
                                <span class="has-text-dark heading">Total reservations</span>
                                <span class="has-text-dark title">{{reservations.length}}</span>
                                
                                </div>
                            </div>
                        
                    </div>
                    <div class="column">
                        
                            <div style="height:100%;">
                                <div class="notification has-background-grey-lighter is-fullheight" style="height:100%">
                                <span class="has-text-dark heading">Cancelled reservations</span>
                                <span class="has-text-dark title">{{cancelledReservation.length}}</span>
                                
                                </div>
                            </div>
                        
                    </div>
                    <div class="column">
                        
                            <div style="height:100%;">
                                <div class="notification is-success is-fullheight" style="height:100%">
                                <span class="heading">Completed reservations</span>
                                <span class="title">{{completedReservation.length}}</span>
                                
                                </div>
                            </div>
                        
                    </div>
                    <div class="column">
                        
                            <div style="height:100%;">
                                <div class="notification is-info is-fullheight" style="height:100%">
                                <span class="heading">Open reservations</span>
                                <span class="title">{{openReservations.length}}</span>
                                <span class="help">Within 30 days</span>
                                </div>
                            </div>
                        
                    </div>
                    <div class="column">
                            <div style="height:100%;">
                                <div class="notification is-danger is-fullheight" style="height:100%">
                                <span class="heading">Exceed Reservation limit</span>
                                <span class="title">{{exceedAging.length}}</span>
                                <span class="help">Exceed 30 days</span>
                                </div>
                            </div>
                        
                    </div>
                    </div>
                </div>
            
        </div>
        <div class="buttons is-right">
            <router-link to="/reservations">View reservations</router-link>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
export default {
    mounted(){
        this.$store.dispatch('capry.reservations.load')
    },
    computed:{
        reservations(){
            return this.$store.state.capry.reservations || []
        },
        openReservations(){
            return this.reservations.filter(reservation => reservation.status == 'open')
        },
        exceedAging(){
            return this.openReservations.filter(reservation => reservation.aging > 30)
        },
        completedReservation(){
            return this.reservations.filter(d => d.status == 'completed')
        },
        cancelledReservation(){
            return this.reservations.filter(d => d.status == 'cancelled')
        },
    },
    methods:{
        filterStatus(status){
            this.$emit("updateFilter", status)
        }
    }
}
</script>
