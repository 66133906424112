<script>
export default {
    props: ['scope','visibility', 'noDev'],
    computed:{
        show(){
            return this.hasScope(this.scope) || this.visibility == 'public' || (process.env.NODE_ENV == 'development' && !this.noDev)
        }
    },
    methods:{
        hasScope(scope){
            return this.$store.getters.userScope(scope)
        }
    },
    mounted(){
        if(!this.$store.state.login.user){
            this.$store.dispatch('login.init')
        }
    },
    render(createElement){
        if(this.show){
            return this.$scopedSlots.default() 
        }
    }
}
</script>

