<template>
    <div>
        <loading-screen v-if="loading" />
        <div style="padding-top:80px;"></div>
            <div class="container">
                <div class="section">
                    <map-routes />
                    <div class="card card-content">
                        <div class="notification">
                            <div class="content">
                                <p>Utilization report is calculated based on lastest data in <b>Inventory</b> section. This includes reserved capacities.</p>
                                <p>Incomplete activated reservation may cause differences in the reporting. Please ensure all reservation is completed once the capacity activated</p>
                            </div>
                        </div>     
                        <reservation-stats />   
                    </div>
                    <div class="card">
                        <div class="card-content">
                            <utilization-graph v-if="rawdata" :utilization="utilization"/>
                        </div>
                    </div>
                    <utilization-summary v-if="rawdata" :utilization="utilization" />

                </div>
            </div>
        <div style="height:200px;"></div>
    </div>
</template>

<script>

import * as R from 'ramda'
import reporting from './../../reporting'
import ReservationStats from './ReservationStats'
import UtilizationGraph from './UtilizationGraphCommonRoutes'
import LoadingScreen from './../standalone/LoadingScreen.vue'

export default {
    components:{
        LoadingScreen,
        ReservationStats,
        UtilizationGraph,
        UtilizationReport: () => import(/* webpackChunkName: "utilization-report" */ './UtilizationReportNew'),
        MapRoutes: () => import(/* webpackChunkName: "map-routes" */ './MapRoutes'),
        UtilizationSummary: () => import('./UtilizationSummary')
    },
    data(){
        return {
            rawdata: false,
            miu_routes: false
        }
    },
    mounted(){
        this.loadData()
    },
    computed:{
        loading(){
            return !this.rawdata
        },
        utilization(){
            return reporting(this.rawdata, this.miu_routes)
        },
        commonRoutesSummary(){
            if(!this.rawdata){
                return {}
            }

            return this.utilization.allSegmentsSummary
        }
    },
    methods:{
        loadData(){
            Promise.all([
                this.$store.dispatch('capry.utilization.load'),
                this.$store.dispatch('capry.load.miuroutes')
            ]).then(([res,miu_routes]) => {
                this.rawdata = res.data
                this.miu_routes = miu_routes
            })
        }
    }
}
</script>
