<template>
  <div >
    <div style="padding-top:80px;"></div>
    <div class="container">
      <div class="columns">
        <div class="column is-4">
          <div class="buttons">
            <router-link :to="cableDetailLink(cable)" v-for="cable in cables" :key="cable.cable_id" class="button">{{ cable.name }}</router-link>
          </div>
        </div>
        <div class="column">
          <router-view :all-routes="indexedRoutes"></router-view>
          <!-- <pre>{{indexedRoutes}}</pre> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import { expandRoutes } from './../../../Models/RouteExpender.js'
export default {
  computed:{
    cables(){
      return this.$store.state.capry.cables
    },
    miu_routes(){
      return this.$store.state.capry.miu_routes
    },
    routeExpander(){
      return expandRoutes(this.miu_routes)
    },
    routes(){
      return R.pipe(
        // d=>d,
        // R.filter( d=> d.cable_system == 'SeaMeWe-4'),
        R.map(this.routeExpander),
        R.flatten,
      )(this.$store.state.capry.routes)
    },
    indexedRoutes(){
      return R.groupBy(R.prop('cable_system'), this.routes)
    },
  },
  mounted(){
    this.$store.dispatch('capry.load.miuroutes')    
    this.$store.dispatch('capry.loadRoutes')
  },
  methods:{
    cableDetailLink(cable){
      return `/cables/${cable.cable_id}`
    }
  }
}
</script>