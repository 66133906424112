<template>
    <div class="modal is-active">
        <div class="modal-background" @click="$emit('close')"></div>
        <div class="modal-content" style="width:80vw;">
            <div class="card">
                <div class="columns">
                    <div class="column is-3">
                        foo
                    </div>
                    <div class="column">
                        <pre>{{ offerings }}</pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    props:['offerings']
}
</script>
