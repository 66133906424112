<template>
    <div class="container" style="padding-top:40px;">
        <router-view :store="store"></router-view>
    </div>
</template>

<script>
export default {
    props:['store']
}
</script>

