<template>
    <div v-if="reservation.status == 'confirmed'">
        <div class="buttons">
            <button @click.prevent="openCompleteForm" class="button is-primary is-multiline">Request confirmation</button>
        </div>

        <div class="modal " :class="{'is-active': showCompleteForm}">
            <div class="modal-background"></div>
            <div class="modal-content">
                <div class="card">
                    <div class="card-header">
                        <p class="card-header-title">Order Completed</p>
                    </div>
                    <div class="card-content">
                        
                        <div class="field is-grouped">
                            <div class="control is-expanded">
                                <label class="label">Contract term</label>
                                <div class="field has-addons">
                                    <div class="control is-expanded">
                                        <input class="input" v-model="form.contract_term" type="text" v-validate="'required'" name="contract_term"
                                            :class="hasError('contract_term')">
                                    </div>
                                    <div class="control">
                                        <span class="button is-static">years</span>
                                    </div>
                                </div>
                            </div>
                            <div class="control is-expanded">
                                <div class="field">
                                    <label class="label">Revenue</label>
                                    <div class="field has-addons">
                                        <div class="control ">
                                            <span class="button is-static">USD</span>
                                        </div>
                                        <div class="control is-expanded">
                                            <input class="input" v-model="form.revenue" type="text" v-validate="'required'" name="revenue"
                                                :class="hasError('revenue')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label :class="hasError('confirmation')">
                                <div class="control">
                                    <input type="checkbox" v-model="form.confirmation" name="confirmation" v-validate="'required'" > I hereby verify that customer has confirmed their order and submitted signed SOFCA
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="card-footer">
                        <a class="card-footer-item has-text-primary" @click="completeActivation"><b>Order Confirmed</b></a>
                        <a class="card-footer-item" @click="cancelComplete">Cancel</a>
                    </div>
                </div>
            </div>
            <button class="modal-close is-large" @click="cancelComplete" aria-label="close"></button>
        </div>
    </div>
</template>


<script>
export default {
    props: ['reservation'],
    data(){
        return {
            showCompleteForm: false,
            form: {
                contract_term: 0,
                revenue: 0,
            },
            notification: {
                status: false,
                message: '',
                timer: false
            },
        }
    },
    methods:{
        hasError(name) {
            return {
                'is-danger has-text-danger': this.errors.first(name)
            }
        },
        openCompleteForm() {
            if(confirm('Send confirmation request to sales?')){
                // do something to trigger email to sales
            }
            // this.showCompleteForm = true;
        },
        cancelComplete() {
            this.showCompleteForm = false;
        },
        showNotification(message,type){
            this.$emit("notification", message, type)
        },
        async completeActivation() {
            await this.$validator.validateAll();

            if(this.$validator.errors.any()){
                this.showNotification("Please complete the form")
                return;
            }
            this.showCompleteForm = false;
            this.showNotification("Completing...", "is-info")

            this.$store.dispatch('capry.reservations.complete', {
                salesforce_id: this.reservation.salesforce_id,
                revenue: this.form.revenue,
                contract_term: this.form.contract_term,
            }).then(res => {
                this.showNotification("Completed!", "is-success")
                this.$store.dispatch('capry.reservations.view', this.reservation.salesforce_id)
            }).catch(err => {
                this.showNotification(err.response.data.message || err)
            })
        },
        cancelReservation() {
            if (confirm('Are you sure to cancel this reservation and release the capacity?')) {
                this.$store.dispatch('capry.reservations.cancel', this.reservation).catch(err => {
                    alert(err.response.data.message)
                }).then(() => {
                    this.$store.dispatch('capry.reservations.view', this.salesforce_id)
                })
            }
        },
        
        
        
    }
}
</script>
