<template>
  <div>
      <div class="columns">
          <div class="column is-4" v-if="!isConvert">
              <reservation-detail :rules="rules" :form="form" @error="error" @success="success" :confirmed_order="confirmed_order" />
              <span class='is-size-7 has-text-grey-light' v-if=!confirmed_order >You are not allowed to reserve because you <a @click="changeStep(1)">not confirm customer order</a> </span>
          </div>
          <div class="column is-4" v-else>
            <conversion-detail @error="error" @success="success" @cancel="$emit('cancel')" :form="form" />
          </div>
          <div v-if="!loaded" class="column">
              <div class="hero">
                  <div class="hero-body">
                      <div class="container has-text-centered">
                          <span class="button is-loading is-fullwidth is-white"></span>
                          <span class="help">Loading...</span>
                      </div>
                  </div>
              </div>
          </div>
          <div v-else-if="canSelectRoute" class="column">
              <div class="card" v-if="!isLoading" style="height:100%">
                  <div class="card-content">
                      <label class="label">Capacities</label>
                      <input type="hidden" v-model="form.capacities" name="capacities" v-validate="rules.capacities">
                      <span class="is-size-7 has-text-danger">{{ errors.first('capacities') }}</span>
                      <div v-for="(row,i) in form.capacities" :key="i" class="card" :class="validClass(row)">
                          <div class="card-content">
                              <a v-if="!isConvert" @click.prevent="removeCapacity(i)" class="delete is-pulled-right"></a>
                              <p class="is-size-7">{{row.cable_system}}</p>
                              <span class="has-text-weight-semibold">{{row.route.sortedLabel}} {{ row.distance? `(${row.distance})` : '' }}</span>
                              <div class="is-clearfix"></div>
                              <div style="margin-top:20px;">
                                  <div class="control" v-if="!row.isSufficient(form.bandwidth, form.type, getConsumedQty(row), true)">
                                      <span class="is-size-7">INSUFFICIENT CAPACITY <a v-if="!isConvert" @click.prevent="removeCapacity(i)">[REMOVE]</a> </span>
                                  </div>
                                  <div v-else>
                                      <capacity-tag v-if="!row.selected" :consumed="getConsumedQty(row)" :id="i" @remove="removeCapacity" :capacity="row" :type="form.type" :bandwidth="form.bandwidth" />    
                                      <div v-if="row.selected !== false && row.selected.consumedQty(form.bandwidth)" class="is-size-7">
                                          <span class="field is-grouped">
                                              <div class="control">
                                                  <div class="tags has-addons">
                                                      <div class="tag is-dark">
                                                          {{row.selected.consumedQty(form.bandwidth)}} {{row.selected.unit == 'miu'? `${row.selected.label.toUpperCase()}` : `x ${row.selected.label.toUpperCase()}` }} 
                                                      </div>
                                                      <a class="tag" @click="row.selected = false">X</a>
                                                  </div>
                                              </div>
                                          </span>
                                      </div>
                                      <item-config v-if="row.selected" :capacity="row" :qty="row.selected.consumedQty(form.bandwidth)" />
                                  </div>
                              </div>
                          </div>
                      </div>                        
                  </div>
                  <div v-if="!isConvert" class="card-content">
                      <div class="columns">
                          <div class="column">
                              <div class="field">
                                  <div class="control">
                                      <v-select v-model="current_capacity" class="select2" @input="clearSearch" :onSearch="search" :options="capacity_list" :filterable=false >
                                          <template slot="option" slot-scope="option">
                                              <span class="is-size-7" v-html="highlight(option.cable_system, search_keyword)"></span><br>
                                              <span v-html="highlight(option.route.sortedLabel, search_keyword)"></span>
                                          </template>
                                      </v-select>
                                      <span class="is-size-7">{{ capacity_list.length}} Capacities</span>
                                  </div>
                              </div>
                          </div>
                          <div class="column is-1">
                              <button v-if="current_capacity" @click.prevent="addCapacity" class="button is-small">ADD</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import VSelect from 'vue-select'
import {rules} from './../../../Models/Reservation.js'
import {toQty} from './../../../Models/CapacityCalc.js'
import { expandRoutes } from './../../../Models/RouteExpender.js'
import CapacityTag from './CapacityTag.vue'
import moment from 'moment'
import ItemConfig from './ItemConfig.vue'
import ConversionDetail from './ConversionDetail'
import ReservationDetail from './../Reservations/ReservationDetail'

export default {
    props:{
      confirmed_order: Boolean,
      convertFrom: {
          type: Object,
          default(){
              return {
                capacities: []
              }
          }
      },
    },
    components:{
        VSelect,
        CapacityTag,
        ItemConfig,
        ConversionDetail,
        ReservationDetail,
    },
    data(){
        return {
            loaded:false,
            rules,
            current_capacity: '',
            form: {
                contact_person: this.convertFrom.contact_person,
                customer: this.convertFrom.customer,
                salesforce_id: this.convertFrom.salesforce_id,
                bandwidth: this.convertFrom.bandwidth || 10,
                reservation_type: this.convertFrom.reservation_type == 'leasing' ? 'iru' : 'leasing',
                type: this.convertFrom.type == "asset" ? "stock" : "asset",
                capacities: [],
            },
            search_keyword: '',
        }
    },
    watch:{
        'form.type'(value){
            this.resetSelection()
        },
    },
    computed:{
        isConvert(){
            return this.convertFrom.salesforce_id
        },
        editCapacities(){
            return this.convertFrom.capacities.map(c => {
                return Object.assign({
                    selected: false,
                },this.indexedCapacities[c.inventory_id])
            })
        },
        routes(){
            return this.$store.state.capry.routes
        },
        miu_routes(){
            return this.$store.state.capry.miu_routes
        },
        canSelectRoute(){
            return this.form.bandwidth > 0 && this.form.type != ''
        },
        isLoading(){
            return this.routes.length == 0
        },
        capacities(){
            return this.routes
        },
        indexedCapacities(){
            return R.indexBy(R.prop('id'), this.capacities)
        },
        expanded_capacity(){
            return R.pipe(
                R.map(expandRoutes(this.miu_routes)),
                R.flatten
            )(this.capacities)
        },
        capacity_list(){
            return this.expanded_capacity
            .filter(capacity => capacity.hasType(this.form.type))
            .filter(data => this.addedRoutes.indexOf(data.route.sortedLabel) == -1) //revemo added routes
            .filter(capacity => capacity.isSufficient(this.form.bandwidth, this.form.type, this.getConsumedQty(capacity)))
            .filter(capacity => capacity.search(this.search_keyword))
        },
        addedRoutes(){  // used to filter-out capacity list 
            return this.form.capacities.map(c => c.route.sortedLabel)
        },
        consumed_qty(){ // to prepare mapping for consumed capacity.. for shared capacity... should become class object
            const calcConsumed = R.pipe(
                R.groupBy(R.prop('id')),
                R.map(R.pipe(
                    R.pluck('selected'),
                    R.filter(data => data),
                    R.groupBy(data => data.label),
                    R.map(R.pipe(
                        R.map(data => data.consumedQty(this.form.bandwidth)),
                        R.sum
                    ))
                )))
            return calcConsumed(this.form.capacities)
        }
    },
    mounted(){
        this.loadMiuRoutes()
        this.$store.dispatch('capry.loadRoutes').then(() => {
            this.loaded = true
            this.form.capacities = this.editCapacities
        })
    },
    methods:{
        resetSelection(){
            this.form.capacities = this.form.capacities.map(capacity => {
                capacity.selected = false
                return capacity
            })
        },
        error(msg){
            this.$emit('error',msg)
        },
        success(msg){
            this.$emit('success', {msg, salesforce_id: this.form.salesforce_id})
        },
        changeStep(step){
            this.$emit('changeStep', step)
        },
        highlight(value, keyword){
            if(keyword.length < 1){
                return value
            }
            const reg = new RegExp(`(${keyword.split(/[\s%]/).join("|")})`,"ig")
            const matches = value.match(reg)
            
            if(!matches){
                return value
            }
            return matches.filter(part => part.length > 0).reduce((output,part) => {
                const reg2 = new RegExp(`(${part})(?!\\*)`)
                return output.replace(reg2, `*$1*`)
            }, value).replace(/\*([^\*]+)\*/g, '<span class="has-background-warning has-text-dark">$1</span>' )

        },
        search(keyword){
            this.search_keyword = keyword
        },
        clearSearch(){
            this.search_keyword = ''  
        },
        getConsumedQty(capacity){
            return this.consumed_qty[capacity.id] || {}
        },
        filterFunction(option, label, search){
            return option.search(search)
        },
        loadMiuRoutes(){
            this.$store.dispatch('capry.load.miuroutes')
        },
        validClass(capacity){
            if(!capacity.isSufficient){
                return {
                    'has-background-danger': true
                }
            }
            return {
                'has-background-warning': !capacity.isSufficient(this.form.bandwidth, this.form.type, this.getConsumedQty(capacity), true) //|| !capacity.hasType(this.form.type)
            }
        },
        selectedType(type){
            return this.form.type === type
        },
        addCapacity(){
            this.form.capacities.push(Object.assign({},this.current_capacity,{
                selected: false
            }))
            this.current_capacity = ''
            this.$validator.validate('capacities')
        },
        removeCapacity(idx){
            this.form.capacities = R.remove(idx,1, this.form.capacities)
            this.$validator.validate('capacities')
        },
        selectReservationType(type){
            this.form.reservation_type = type
            const type_map = {
                'leasing' : 'asset',
                'iru' : 'stock'
            }
            this.form.type = type_map[type]
            this.$validator.validate('type')
        },
        selectedReservationType(type){
            return {
                'is-info' : type === this.form.reservation_type,
            }
        },
    }
}
</script>
