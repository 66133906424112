'use strict'

import * as R from 'ramda'
import LocalStorage  from './../../utils/LocalStorage'
const axios = require('axios')
const jwtDecode = require('jwt-decode')
const API_URL = process.env.API_URL
const STATUS_LOGOUT = 'logout'
const STATUS_LOGIN = 'login'
const STATUS_PENDING = 'pending'

export default {
    state: {
        temp_token: null,
        jwt: null,
        user: null,
        phrase: '',
        requiresAuth: false,
        pwa_installed: false,
    },
    mutations: {
        REMOVE_JWT_TOKEN(state) {
            LocalStorage.remove('simi.jwt_token');
            axios.defaults.headers.common['Authorization'] = null;
            state.jwt = null;
        },
        UPDATE_JWT_TOKEN(state, token) {
            if (!token) {
                return
            }
            state.jwt = token;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            state.user = jwtDecode(token);
            LocalStorage.set('simi.jwt_token', token);
        },
        REMOVE_TEMP_TOKEN(state) {
            LocalStorage.remove('simi.temp_token');
            state.temp_token = null;
        },
        UPDATE_TEMP_TOKEN(state, token) {
            state.temp_token = token;
            LocalStorage.set('simi.temp_token', token);
            state.phrase = token ? token.phrase : '';
        },
        REQUIRES_AUTH(state, value) {
            state.requiresAuth = value;
        },
        SET_INSTALLED(state, flag) {
            state.pwa_installed = flag
        }
    },
    getters: {
        secureBg: state => {
            if (!state.user) {
                return;
            }
            var QRCode = require("qrcode-svg");
            var svg = new QRCode({
                content: state.user.staff_id,
                padding: 1,
                width: 50,
                height: 50,
                color: "#fcfcfc",
                background: "#ffffff",
                ecl: "M"
            }).svg().toString().replace(/[\n\r]+/g, '')
            
            return {
                background: `url('data:image/svg+xml;utf8,${svg}') repeat`
            }
        },
        isLoggedIn: state => {
            return !!state.jwt;
        },
        login_status: state => {
            if (!state.jwt && !state.temp_token) {
                return STATUS_LOGOUT;
            }

            if (!state.jwt) {
                return STATUS_PENDING;
            }

            if (state.jwt) {
                return STATUS_LOGIN;
            }
        },
        show_login: state => {
            return state.requiresAuth && !state.jwt 
        },
        userScope: (state) => (scope) => {
            if (!state.user) {
                return false
            }
            return R.intersection((state.user.scope || []), scope).length > 0 
        }
    },
    actions: {
        requiresAuth: function(context, value) {
            context.commit('REQUIRES_AUTH', value);
        },
        'installed.init': function (context) {
            context.commit(
                'SET_INSTALLED',
                LocalStorage.get.item('simi.pwa.isInstalled')
            );
        },
        'installed.update': function (context, flag) {
            LocalStorage.set('simi.pwa.isInstalled', flag);
            context.dispatch('installed.init')
        },
        'login.init': function (context) {
            context.commit(
                'UPDATE_TEMP_TOKEN',
                LocalStorage.get.item('simi.temp_token')
            );
            context.commit(
                'UPDATE_JWT_TOKEN',
                LocalStorage.get.item('simi.jwt_token')
            );
            
            //fetch latest token
            setTimeout(() => {
                axios.get(`${API_URL}/authentication/renew`).then(res => {
                    const jwt = res.data.jwt
                    
                    const oldUser = context.state.user

                    context.commit('UPDATE_JWT_TOKEN', jwt);
                    
                    if (!context.state.user.staff_id) {
                        return context.dispatch('login.logout')
                    }

                    if (JSON.stringify(context.state.user.scope) != JSON.stringify(oldUser.scope)) {
                        location.reload()
                    }

                })
            }, 1000)
            
        },
        'login.attempt': function(context, form) {
					console.log("Login attempt", API_URL)
            return axios
                .post(`${API_URL}/authentication/request`, form)
                .then(res => {
                    const { token, phrase } = res.data;
                    context.commit('UPDATE_TEMP_TOKEN', { token, phrase });
                    context.commit('REMOVE_JWT_TOKEN');
                    return res.data;
                }).catch(err => {
									console.log("ERROR", JSON.stringify(err))
									throw err
								})
        },
        'login.check': function (context) {
            const token = context.state.temp_token.token;
            
            if (!token) {
                return;
            }

            return axios
                .get(`${API_URL}/authentication/check/${token}`)
                .then(res => {
                    const { jwt, status } = res.data;

                    if (status === 'verified') {
                        context.commit('UPDATE_JWT_TOKEN', jwt);
                        context.commit('REMOVE_TEMP_TOKEN');
                    }

                    return {
                        status,
                        message: res.message
                    };
                }).catch(err => {
                    console.log(err)
                })
        },
        'login.logout': function(context) {
            context.commit('REMOVE_JWT_TOKEN');
            context.commit('REMOVE_TEMP_TOKEN');
        }
    }
};

