<template>
  <div>
    show cable {{ cableId }}
    <routes-map :key="cableId" :routes="routes" />
  </div>
</template>

<script>
import * as R from 'ramda'
import RoutesMap from './RoutesMap'
export default {
  props:{
    allRoutes: Object,
    default(){
      return {}
    }
  },
  components:{
    RoutesMap,
  },
  computed:{
    cables(){
      return this.$store.state.capry.cables
    },
    indexedCables(){
      return R.indexBy(R.prop('cable_id'), this.cables)
    },
    cableId(){
      return this.$route.params.cableId
    },
    cable(){
      return this.indexedCables[this.cableId] || {}
    },
    cableInfo(){
      return this.$store.state.capry.content[this.cableId] || {}
    },
    routes(){
      return this.allRoutes[this.cable.name] || []
    },
  },
  mounted(){
    this.loadCableInfo()
  },
  methods:{
    loadCableInfo(){
      this.$store.dispatch('capry.content.load', this.cableId)
    },
  }
}
</script>
