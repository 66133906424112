import moment from 'moment-timezone'

function Asset({ phase, supplier_cct_id, vendor, provider, description, cable_system, route, segment, start_date, end_date }) {
    this.phase = phase
    this.supplier_cct_id = supplier_cct_id
    this.vendor = vendor
    this.provider = provider
    this.description = description
    this.cable_system = cable_system
    this.route = route
    this.segment = segment
    this.services = [];
    this.start_date = moment(start_date);
    this.end_date = moment(end_date);
}

Asset.prototype.phaseIs = function (phase) {
    if (!phase) {
        return true
    }
    return this.phase === phase
}

Asset.prototype.segmentIs = function (segment) {
    if (!segment) {
        return true
    }
    return this.segment.toLowerCase().indexOf(segment.toLowerCase()) > -1;
};

Asset.prototype.isActive = function (date) {
    if (!moment.isMoment(date)) {
        throw Error('Date is not a moment')
    }

    return date.isSameOrAfter(this.start_date) && date.isSameOrBefore(this.end_date)
}

Asset.prototype.addServices = function (services) {
    this.services = services
}

export default Asset;