import * as R from 'ramda'
import { mapping } from './mapping'
import PathFinder from './../Models/PathFinder'
import { toGbps } from './../Models/CapacityCalc'
import { ClsLabel } from './../Models/Route'
import { cablemaps } from './../Models/RouteExpender.js'

const getTotal = (type, list) => {
  return R.pipe(
    R.filter((data) => !isAnyMiu(data)),
    R.map(type),
    R.sum
  )(list)
}

const equipedInGB = item => {
  return toGbps(item.equiped_qty, item.unit)
}

const addToPathFinder = (miuLocations) => (pathFinder, route) => {
  if (ClsLabel(route.route.landing_points[0]) == 'any') {
    
    const locations = R.map((location) => PathFinder.miuRoute(
      `${location.locations[0].name}, ${location.locations[0].country}`,
      `${location.locations[1].name}, ${location.locations[1].country}`,
      location.distance,
      location.miu_for_10GB
    ), miuLocations)

    // console.log('ADD MIU PATH', `${route.miu} miu`, `${locations.length} locations`)
    return pathFinder.addMiu('miu', route.miu, locations)
  }
  // console.log("ADD PATH: ", ClsLabel(route.route.landing_points[0]), ClsLabel(route.route.landing_points[1]), route.total_capacity)
  return pathFinder.add(ClsLabel(route.route.landing_points[0]), ClsLabel(route.route.landing_points[1]), route.total_capacity)
}

const isAnyMiu = data => data.unit == 'miu' && data.route.label == "any → any"

const getTotalAnyMiu = (attr) => R.pipe(
  R.filter(isAnyMiu),
  R.pluck(attr),
  R.sum
)

const registerPathFinder = (type, getTotalAnyMiuFunc, miuLocations) => (capacities) => {
  let pathFinder = PathFinder()

  return R.pipe(
    R.groupBy(d => d.PK),
    R.map(list => {
      return {
        ...R.pick(['route'], R.head(list)),
        miu: getTotalAnyMiuFunc(list),
        total_capacity: getTotal(type, list), // TODO: if miu should calculate based on package
      }
    }),
    R.values,
    R.reduce(addToPathFinder(miuLocations), pathFinder)
  )(capacities)
}
  
const getPossibleRoutes = route => R.xprod(
  R.flatten([route.from]),
  R.flatten([route.to])
)

const addPackage = (pathFinder, route) => {
  // console.log("ADD PACKAGE", route.name, R.head(getPossibleRoutes(route)))
  return pathFinder.addPackage(route.name, getPossibleRoutes(route), route.excludes || [])
}

const packageRouteMapper = packages => route => {
  return {
    ...route,
    segmentCapacities: R.pipe(
      R.mapObjIndexed((x, i) => {
        i = parseInt(i)
        return {
          path: [route.path[i], route.path[i + 1]],
          capacity: packages.getSegmentCapacity(route.path[i], route.path[i + 1]),
          breakdown: route
        }
      }),
      R.values,
      R.filter(d => d.path[1])
    )(route.path)
  }
}

const registerReportingRoutes = (reportingRoutes) => (pathFinder) => {
  const packages = R.reduce(addPackage, pathFinder, reportingRoutes)
  const addPackageRoutesInfo = packageRouteMapper(packages)

  const packageForRoute = R.pipe(
    route => packages.getPackage(route.name, route.excludes || []),
    routePackage => ({
      ...routePackage, 
      routes: R.map(addPackageRoutesInfo, routePackage.routes)
    })
  )

  return reportingRoutes.map(packageForRoute)
}

const parseListToItem = anyMiuCapacities => list => {
  const total_capacity = getTotal(R.prop('total_capacity'), list)
  const total_equiped = getTotal(equipedInGB, list)

  if (isAnyMiu(R.head(list))) {
    return {
      label: R.head(list).route.label,
      list,
      total_capacity: anyMiuCapacities.remaining_capacity,
      total_equiped: anyMiuCapacities.total_capacity, 
      based_on: anyMiuCapacities.based_on,
    }  
  }
  
  return {
    label: R.head(list).route.label,
    list,
    total_capacity,
    total_equiped,
  }
}

const mapItems = anyMiuCapacities => (items) => {
  return R.pipe(
    R.groupBy(d => d.route.label),
    R.map(parseListToItem(anyMiuCapacities)),
    R.values,
  )(items)
}


const appendEquippedInformation = (equiped) => (pkg) => ({
  ...pkg,
  capacity: parseFloat(pkg.capacity),
  equiped_capacity: parseFloat(equiped[pkg.name].capacity),
  activated_capacity: equiped[pkg.name].capacity - pkg.capacity,
  utilization: parseFloat(((1 - pkg.capacity / equiped[pkg.name].capacity) * 100).toFixed(3)),
})

const filterCapacitiesByRules = (capacities = [], rules) => R.pipe(
  R.map(item => {
    const applyAll = R.pipeWith((f, res) => {
      return res ? f(res) : res
    }, rules)
    return applyAll(item)
  }),
  R.filter(d => d),
  item => {
    // TODO: here to detect miu
    return item
  }
)(capacities)


const makeReportingRoutePackager = (reportingRoutes, capacityFunction, getTotalAnyMiuFunc, miuLocations) => R.pipe(
  registerPathFinder(capacityFunction, getTotalAnyMiuFunc, miuLocations),
  registerReportingRoutes(reportingRoutes),
)

const groupedItems = (items, offsets, anyMiuCapacities) => R.pipe(
  mapItems(anyMiuCapacities),
  R.concat(offsets)
)(items)

const summarize = (routes, offsets = [], anyMiuCapacities = {}) => {
  const total_capacity = getTotal(equipedInGB, routes) + getTotal(R.prop('total_equiped'), offsets) + anyMiuCapacities.total_capacity
  const remaining_capacity = getTotal(R.prop('total_capacity'), routes) + getTotal(R.prop('total_capacity'), offsets) + anyMiuCapacities.remaining_capacity

  const unutilized = remaining_capacity / total_capacity

  return {
    remaining_capacity,
    total_capacity,
    activated_capacity: total_capacity - remaining_capacity,
    utilization: parseFloat(((1 - unutilized) * 100).toFixed(3))
  }
}

const parseOffsetItem = d => ({
  label: d.description,
  list: [],
  total_capacity: d.remainingInGB,
  total_equiped: d.equipedInGB,
})

const totalCapacity = R.pipe(
  R.identity,
  R.map(R.pick(['capacity', 'equiped_capacity'])),
  R.reduce((acc, {
    capacity,
    equiped_capacity
  }) => {
    return {
      capacity: acc.capacity + capacity,
      equiped_capacity: acc.equiped_capacity + equiped_capacity,
    }
  }, {
    capacity: 0,
    equiped_capacity: 0
  })
)

const calculateCommonRoutesSummary = (cableData) => {
  return totalCapacity(cableData)
}

const processCapacities = (capacities, cableConfig, miuLocations = []) => {
  const offsets = R.map(parseOffsetItem, cableConfig.offsets)
  
  const commonRoutesEquiped = R.pipe(
    makeReportingRoutePackager(cableConfig.reportingRoutes, equipedInGB, getTotalAnyMiu('equiped_qty'), miuLocations),
    R.indexBy(R.prop('name'))
  )(capacities)

  const commonRoutes = R.pipe(
    makeReportingRoutePackager(cableConfig.reportingRoutes, R.prop('total_capacity'), getTotalAnyMiu('qty'), miuLocations),
    R.map(R.pipe(
      appendEquippedInformation(commonRoutesEquiped),
      d => ({
        ...d,
        miuOnly: !!cableConfig.miuOnly,
        total_miu: cableConfig.miuOnly ? R.head(d.routes).meta.total_miu : 0,
      })
    ))
  )(capacities)

  const anyMiuCapacities = {
    remaining_capacity: !cableConfig.miuOnly ? 0 : R.head(commonRoutes).capacity,
    total_capacity: !cableConfig.miuOnly ? 0 : R.head(commonRoutes).equiped_capacity,
    based_on: (R.head(commonRoutes)||{}).name
  }

  return {
    cableName: cableConfig.cableName,
    ...cableConfig.meta,
    items: capacities,
    offsets,
    commonRoutes,
    groupedItems: groupedItems(capacities, offsets, anyMiuCapacities),
    allSegments: summarize(capacities, offsets, anyMiuCapacities),
    commonRoutesSummary: calculateCommonRoutesSummary(commonRoutes)
  }
}
 
export default (allCables, miu_routes) => {
  const data = R.pipe(
    // R.pick([
    //   'juscn',
    //   'apcn2',
    //   'smw3',
    // ]),
    R.map(cableConfig => {
      const miuCable = cablemaps[cableConfig.cableName]
      const miuLocations = miuCable ? R.filter(d => d.cable == miuCable, miu_routes) : []
      
      const capacities = filterCapacitiesByRules(allCables[cableConfig.cableName], cableConfig.rules)
      return processCapacities(capacities, cableConfig, miuLocations)
    })
  )(mapping())

  const commonRoutesSummary = R.pipe(
    R.values,
    R.pluck('commonRoutesSummary'),
    totalCapacity,
  )(data)

  const allSegmentsSummary = R.pipe(
    R.values,
    R.map(data => ({
      capacity: data.allSegments.remaining_capacity,
      equiped_capacity: data.allSegments.total_capacity,
    })),
    totalCapacity,
  )(data)

  return {
    data: data,
    allSegmentsSummary: {
      ...allSegmentsSummary,
      activated_capacity: allSegmentsSummary.equiped_capacity - allSegmentsSummary.capacity,
      utilization: ((1 - allSegmentsSummary.capacity / allSegmentsSummary.equiped_capacity) * 100).toFixed(3)
    },
    commonRoutesSummary: {
      ...commonRoutesSummary,
      activated_capacity: commonRoutesSummary.equiped_capacity - commonRoutesSummary.capacity,
      utilization: ((1 - commonRoutesSummary.capacity / commonRoutesSummary.equiped_capacity) * 100).toFixed(3)
    }
  }
}