<template>
    <div>
        <vue-apex-charts v-if="series.length > 0" :height="height" :width="width" type="bar" :options=chartOptions :series=series />
    </div>
</template>


<script>
import * as R from 'ramda'
import VueApexCharts from 'vue-apexcharts'
export default {
    components:{
        VueApexCharts
    },
    props:['breakdowns', 'height','width'],
    computed:{
        chartOptions(){
            return {
                chart: {
                    sparkline: {
                        enabled: true,
                    },
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    animations:{
                        enabled: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                    
                },
                tooltip:{
                    x: {
                        show: false,
                    },
                    y: {
                        formatter: (v) => `${v}Gbps`,
                        title: {
                            formatter: (l) => `${l} interface`
                        }
                    }
                }
            }
        },
        series(){
            return R.pipe(R.toPairs, R.map(data => ({
                name: data[0],
                data: [data[1]]
            })))(this.breakdowns)
        }
    }
}
</script>
