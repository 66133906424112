const axios = require("axios");

const load = async (id, gid) => {
  var url = `https://37a0gi9tyj.execute-api.ap-southeast-1.amazonaws.com/prod/`
  return axios.post(url, {
    id,
    gid
  }).then(res => res.data)
};


module.exports = load