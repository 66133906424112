import moment from 'moment-timezone';
import * as R from 'ramda';
moment().tz("Asia/Kuala_Lumpur");

function parseMoney(value) {
    return parseFloat(value);
}

function parseDate(value) {
    if (!R.test(/[a-zA-Z]{3}-[0-9]{2}/)) {
        throw Error({
            status: 400,
            message: `${value} not follow date format MMM-YY`
        });
    }
    return moment(value, 'MMM-YY')
}

function Contract({
    id,
    phase,
    type,
    qty,
    segment,
    contract_party,
    contract_start_date,
    contract_end_date,
    otc,
    mrc,
    arc,
    notes
}) {
    this.phase = phase;
    this.type = type;
    this.qty = parseInt(qty);
    this.segment = segment;
    this.contract_party = contract_party;
    this.contract_start_date = parseDate(contract_start_date);
    this.contract_end_date = parseDate(contract_end_date);
    this.contract_term =
        this.contract_end_date.diff(this.contract_start_date, 'month') + 1;
    this.otc = parseMoney(otc);
    this.mrc = parseMoney(mrc);
    this.arc = parseMoney(arc);
    this.notes = notes;
    this.id = id;
    this.capacity = this.qty * 10;
}


Contract.prototype.getTerm = function (daterange) {
    if (!daterange) {
        return this.contract_term
    }
    let term;
    const [start, end] = daterange;
    if (end.isAfter(this.contract_end_date)) {
        term =this.contract_end_date.diff(start,'month') + 1
    } else {
        const start_point = this.contract_start_date.isBefore(start) ? start : this.contract_start_date
        term = end.diff(start_point, 'month') + 1
    }

    if (term < 0) {
        return 0
    }

    return term > this.contract_term ? this.contract_term : term;
};

Contract.prototype.isIncome = function () {
    return this.type == 'income'
}


Contract.prototype.totalCost = function(daterange) {
    if (this.type == 'income') {
        return 0;
    }

    const term = this.getTerm(daterange); 
    return this.qty * term * this.mrc;
};

Contract.prototype.totalMrc = function (daterange) {
    const term = this.getTerm(daterange);
    return this.qty * term * this.mrc;
};

Contract.prototype.totalArc = function (daterange) {
    const term = this.getTerm(daterange)
    return this.qty * (term / 12) * this.arc;
};

Contract.prototype.totalOtc = function (daterange) {
    const isFirstYear = () => this.contract_start_date.format('YYYY') == daterange[0].format('YYYY');
    if (!daterange || isFirstYear()) {
        return this.otc * this.qty;
    }

    return 0
};

Contract.prototype.isIRU = function() {
    return this.segment.toLowerCase().indexOf('iru') > -1;
};

Contract.prototype.segmentIs = function (segment) {
    if (!segment) {
        return true;
    }
    
    return this.segment.toLowerCase().indexOf(segment.toLowerCase()) > -1;
}

Contract.prototype.isActive = function (date) {
    if (!moment.isMoment(date)) {
        throw Error({status: 400, message: 'Date is not a moment'})
    }

    return date.isSameOrAfter(this.contract_start_date) && date.isSameOrBefore(this.contract_end_date)
}

Contract.prototype.phaseIs = function(phase) {
    if (!phase) {
        return true;
    }

    return this.phase === phase;
};

Contract.prototype.totalRevenue = function(daterange) {
    if (this.type !== 'income') {
        return 0;
    }
    return this.totalArc(daterange) + this.totalMrc(daterange) + this.totalOtc(daterange);
};

export default Contract;
