<template>
    <div>
        <list />
    </div>
</template>

<script>
export default {
    components: {
        List: () => import('./List.vue')
    }
}
</script>
