<template>
    <div>
        <div class="modal is-active" v-if="showDetails !== false">
            <div class="modal-background" @click="showDetails = false" ></div>
                <div class="modal-card animated zoomIn faster" style="min-width: 60vw;">
                    <div class="card ">
                        <div class="card-header">
                            <div class="card-header-title">
                                Contracts and Services
                            </div>
                        </div>
                    <div style="width:100%; overflow: auto; -webkit-overflow-scrolling: touch;">
                        <div class="card-content" style="overflow:auto; height:90vh;">
                            <h3 class="title is-4">Contracts</h3>
                            <table class="table is-bordered is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Customer</th>
                                        <th>Phase</th>
                                        <th>Capacity</th>
                                        <th>Contract start date</th>
                                        <th>Contract end date</th>
                                        <th>MRC</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contract, i) in showDetails.contracts" :key="i">
                                        <td>{{ contract.contract_party }}</td>
                                        <td>{{ contract.phase }}</td>
                                        <td>{{ contract.capacity }}</td>
                                        <td>{{ contract.contract_start_date.format('MMM-YYYY')}}</td>
                                        <td>{{ contract.contract_end_date.format('MMM-YYYY')}}</td>
                                        <td>{{ contract.mrc  | currency }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <h3 class="title is-4">Services</h3>
                            <table class="table is-bordered is-fullwidth">
                                <thead>
                                    <tr>
                                        <th>Customer</th>
                                        <th>Phase</th>
                                        <th>Supplier CCT ID</th>
                                        <th>TM CCT ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(service, i) in showDetails.services" :key="i">
                                        <td>{{ service.customer }}</td>
                                        <td>{{ service.phase }}</td>
                                        <td>{{ service.supplier_cct_id }}</td>
                                        <td>{{ service.tm_cct_id }}</td>     
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <h2 class="title is-3">Contracts and Services Comparision</h2>
        <table class="table is-bordered is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Phase</th>
                        <th>Total contract</th>
                        <th>Total service contract</th>
                        <th>Total service</th>
                        <th>OK</th>
                    </tr>
                </thead>
                <tbody v-for="(customer, customer_name) in mismatched" :key="customer_name">
                    <tr style="cursor:pointer;" @click="viewDetails(phase)" v-for="(phase, phase_label) in customer" :key="phase_label" :class="{'has-background-danger has-text-white' : !phase.ok}">
                        <td>{{customer_name}}</td>
                        <td>{{phase_label}}</td>
                        <td>{{ phase.contracts.length}}</td>
                        <td>{{ phase.total_qty_in_contract * 10 }} Gbps</td>
                        <td>{{ phase.total_services * 10}} Gbps</td>
                        <td>{{ phase.ok }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2">TOTAL CAPACITY</td>
                        <td>{{ totalContracts }}</td>
                        <td colspan="3">{{ totalServices * 10}} Gbps</td>
                    </tr>
                </tfoot>
        </table>
        <hr>
        <h2 class="title is-3">Future-date contracts</h2>
        <table class="table is-bordered is-fullwidth is-hoverable">
            <thead>
                <tr>
                    <th>Customer</th>
                    <th>Phase</th>
                    <th>Segment</th>
                    <th>Capacity</th>
                    <th>planned start date</th>
                    <th>MRC</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(contract, i) in futureContracts" :key="i">
                    <td>{{ contract.contract_party}}</td>
                    <td>{{ contract.phase }}</td>
                    <td>{{ contract.segment }}</td>
                    <td>{{ contract.capacity }} Gbps</td>
                    <td>{{ contract.contract_start_date.format('MMM-YYYY')}}</td>
                    <td>{{ contract.mrc | currency }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="3">TOTAL CAPACITY</th>
                    <th colspan="3">{{ futureContractsCapacity }} Gbps</th>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import moment from 'moment-timezone'
moment().tz("Asia/Kuala_Lumpur");
import * as R from 'ramda'
export default {
    props: ['state'],
    data(){
        return {
            showDetails: false
        }
    },
    computed:{
        totalServices(){
            return R.pipe(R.values, R.map(R.values),R.flatten,R.map(data => data.total_services),R.sum)(this.mismatched)
        },
        futureContractsCapacity(){
            return R.pipe(R.pluck('capacity'),R.sum)(this.futureContracts)
        },
        futureContracts(){
            return R.pipe(
                R.filter(data => data.contract_party.toLowerCase() !== 'bluetel'),
                R.filter(data => data.contract_start_date.isAfter(moment())),
            )(this.state.contracts)
        },
        totalContracts(){
            return R.pipe(R.values, R.map(R.values),R.flatten,R.map(data => data.contracts.length),R.sum)(this.mismatched)
        },
        serviceMappings(){
            return R.pipe(
                R.groupBy(data => `${data.customer}-${data.phase}`)
            )(this.state.services)
        },
        serviceMappingsCount(){
            return R.pipe(R.map( data => data.length))(this.serviceMappings)
        },
        mismatched(){
            const customer_data = R.pipe(
                R.filter(data => data.contract_party.toLowerCase() !== 'bluetel'),
                R.filter(data => data.contract_start_date.isBefore(moment())),
                R.groupBy(R.prop('contract_party')),
                R.map(
                    R.pipe(
                        R.groupBy(R.prop('phase')),
                        R.map(R.pipe(
                            contracts => ({
                                total_qty_in_contract: R.pipe(R.pluck('qty'),R.sum)(contracts),
                                total_services: (this.serviceMappings[`${contracts[0].contract_party}-${contracts[0].phase}`] ||[]).length,
                                services: (this.serviceMappings[`${contracts[0].contract_party}-${contracts[0].phase}`] ||[]),
                                contracts,
                            }),
                            data => Object.assign({},{
                                ok: data.total_qty_in_contract == data.total_services
                            },data)
                        ))
                    )
                )
            )(this.state.contracts)
            return customer_data;
        }
    },
    methods:{
        viewDetails(data){
            this.showDetails = data
        }
    },
    filters:{
        currency(value){
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD'})
        }
    }
}
</script>

