<template>
    <div class="">
        <div class="modal " :class="{'is-active': show}">
            <div class="modal-background" @click="show = false"></div>
            <div class="card" style="overflow:auto; max-height:80%">
                <div class="" >
                    <table class="table is-fullwidth">
                        <thead>
                            <tr>
                                <th>Phase</th>
                                <th>Type</th>
                                <th>Qty</th>
                                <th>Segment</th>
                                <th>Contract Party</th>
                                <th>Contract Start date</th>
                                <th>Contract End Date</th>
                                <th>OTC</th>
                                <th>MRC</th>
                                <th>ARC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data,i) in sources" :key="i">
                                <td>{{ data.phase}}</td>
                                <td>{{ data.type}}</td>
                                <td>{{ data.qty }}</td>
                                <td>{{ data.segment }}</td>
                                <td>{{ data.contract_party}}</td>
                                <td>{{ data.contract_start_date.format('MMM YYYY')}}</td>
                                <td>{{ data.contract_end_date.format('MMM YYYY')}}</td>
                                <td>{{ data.otc | currency}}</td>
                                <td>{{ data.mrc | currency}}</td>
                                <td>{{ data.arc | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <hr>
        <h3 class="title is-3">Contributions</h3>
        <div class="card">
            <table class="table is-fullwidth is-hoverable">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th v-for="year in years" :key=year>{{ year }}</th>
                        <th>Total to date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>Revenue from Customer</th>
                        <td class="is-clickable" @click="showSource(revFromCustomerFilter, year)" v-for="year in years" :key=year>{{ getRevFromCustomer(year) | currency }}</td>
                        <td>{{getRevFromCustomer() | currency}}</td>
                    </tr>
                    <tr>
                        <th>IRU sales to BTN</th>
                        <td class="is-clickable" @click="showSource(revFromIRUFilter, year)" v-for="year in years" :key=year>{{ getRevFromIRU(year) | currency }}</td>
                        <td>{{getRevFromIRU() | currency}}</td>
                    </tr>
                    <tr>
                        <th>O&amp;M Charges</th>
                        <td class="is-clickable" @click="showSource(revOnmFilter, year)" v-for="year in years" :key=year>{{ getRevOnm(year) | currency }}</td>
                        <td>{{getRevOnm() | currency}}</td>
                    </tr>
                    <tr class="has-background-light">
                        <th>TOTAL REVENUE</th>
                        <th v-for="year in years" :key=year>{{ getTotalRev(year) | currency }}</th>
                        <th>{{getTotalRev() | currency}}</th>
                    </tr>
                    <tr>
                        <th>Leasing Cost S1</th>
                        <td class="is-clickable" @click="showSource(s1Filter, year)" v-for="year in years" :key=year>{{ getLeasingCostS1(year) | currency }}</td>
                        <td>{{getLeasingCostS1() | currency}}</td>
                    </tr>
                    <tr>
                        <th>Leasing Cost S2</th>
                        <td class="is-clickable" @click="showSource(s2Filter, year)" v-for="year in years" :key=year>{{ getLeasingCostS2(year) | currency }}</td>
                        <td>{{getLeasingCostS2() | currency}}</td>
                    </tr>
                    <tr>
                        <th>Leasing Cost S3</th>
                        <td class="is-clickable" @click="showSource(s3Filter, year)" v-for="year in years" :key=year>{{ getLeasingCostS3(year) | currency }}</td>
                        <td>{{getLeasingCostS3() | currency}}</td>
                    </tr>
                    <tr class="has-background-light">
                        <th>TOTAL COST</th>
                        <th v-for="year in years" :key=year>{{ getLeasingCostS1(year) + getLeasingCostS2(year) + getLeasingCostS3(year) | currency }}</th>
                        <th>{{getLeasingCostS1() + getLeasingCostS2()+getLeasingCostS3() | currency}}</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment-timezone'

const isIncome = R.filter(contract => contract.type == 'income')
const isCustomer = R.filter(contract => contract.contract_party.toLowerCase() !== 'bluetel')
export default {
    props:['state','getters'],
    data(){
        return {
            show: false,
            sources: []
        }
    },
    computed:{
        years(){
            const total_year = moment().add(1,'year').format('YYYY') - 2013
            return [...Array(total_year).keys()].map(i => 2013 + i);
        },
        contracts(){
            return this.state.contracts;
        },
        revFromCustomer(){
            return R.filter(this.s1Filter,this.contracts)
        }
    },
    methods:{
        showSource(filter, year){
            const daterange = this.getDateRange(year)
            this.sources = this.contracts.filter(filter).filter(contract => contract.getTerm(daterange) > 0)
            this.show = true
        },
        getDateRange(year){
            if(year){
                return [
                    moment(`Jan ${year}`,'MMM YYYY'),
                    moment(`Dec ${year}`, 'MMM YYYY'),
                ]
            }else{
                return [
                    moment('Jan 2013','MMM YYYY'),
                    moment(`Dec ${moment().format('YYYY')}`, 'MMM YYYY')
                ]
            }

        },
        revFromCustomerFilter(contract){
            return  contract.contract_party.toLowerCase() != 'bluetel' && contract.isIncome();
        },
        getRevFromCustomer(year){
            const daterange = this.getDateRange(year)
            return this.getters.apollo_totalRevenue(
                this.revFromCustomerFilter,
                contract =>  contract.totalMrc(daterange)
            );
        },
        revFromIRUFilter(contract){
            return contract.contract_party.toLowerCase() == 'bluetel' && contract.isIncome()
        },
        getRevFromIRU(year){
            const daterange = this.getDateRange(year)
            return this.getters.apollo_totalRevenue(
                this.revFromIRUFilter,
                contract =>  contract.totalMrc(daterange) + contract.totalOtc(daterange)
            );
        },
        revOnmFilter(contract){
            return contract.contract_party.toLowerCase() == 'bluetel' && contract.isIncome()
        },
        getRevOnm(year){
            const daterange = this.getDateRange(year)
            return this.getters.apollo_totalRevenue(
                this.revOnmFilter,
                contract =>  contract.totalArc(daterange)
            );
        },
        getTotalRev(year){
            const daterange = this.getDateRange(year)
            return this.getters.apollo_totalRevenue(
                contract => contract.isIncome(),
                contract =>  contract.totalRevenue(daterange)
            );
        },
        s1Filter(contract){
            return !contract.isIncome() && !this.s2Filter(contract) && !this.s3Filter(contract)
        },
        s2Filter(contract){
            return !contract.isIncome() && contract.segment.toLowerCase().indexOf('backhaul') > -1
        },
        s3Filter(contract){
            return !contract.isIncome() &&  contract.segment.toLowerCase().indexOf('causeway') > -1
        },
        getLeasingCostS1(year){
            const daterange = this.getDateRange(year)
            return this.getters.apollo_totalCost( this.s1Filter , daterange);
        },
        getLeasingCostS2(year){
            const daterange = this.getDateRange(year)
            return this.getters.apollo_totalCost( this.s2Filter , daterange);
        },
        getLeasingCostS3(year){
            const daterange = this.getDateRange(year)
            return this.getters.apollo_totalCost( this.s3Filter , daterange);
        }
    },
    filters:{
        currency(value){
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD'})
        }
    }
}
</script>


<style>
.is-clickable:hover{
    background-color:lightpink;
    cursor: pointer;
}
</style>
