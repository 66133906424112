const R = require('ramda')
const Bottleneck = require('./Bottleneck')

const expand = (route, maps) => {
    const lastKey = R.last(route)
    const routeMapping = maps[lastKey]
    
    if (!routeMapping) {
        return false
    }

    const childs = R.keys(routeMapping).filter(child => route.indexOf(child) == -1)

    if (childs.length == 0) {
        return [route]
    }

    return childs.map(child => route.concat([child]))
}

const getMinCapacity = (route, maps) => R.pipe(
    route => R.range(0, route.length).map(id => {
        return maps[route[id]][route[id + 1]]
    }).filter(data => data),
    R.sortBy(R.prop('capacity')),
    R.head
)(route)

const getBottleneckMax = (routes, paths) => {
    const groupedRoutes = R.groupBy(data => data.meta.total_miu ? data.meta.cable_system : 'normal', routes)

    const normal_bw = R.pipe(
        R.groupBy(data => R.take(2, data.path).join('-')),
        R.map(data => {
            const capacity = R.pipe(
                R.pluck('capacity'),
                R.sum
            )(data)

            const [A, B] = R.take(2, R.head(data).path)
            return R.min(paths[A][B].capacity, capacity)      
        }),
        R.values,
        R.sum
    )(groupedRoutes['normal'] || [])

    const miu_bw = R.pipe(
        R.omit(['normal']),
        R.map(R.pipe(
            R.sort(R.descend(R.prop('capacity'))),
            R.head,
            R.prop('capacity')
        )),
        R.values,
        R.sum
    )(groupedRoutes)

    return normal_bw + miu_bw
}

const expandAll = (options, maps, count = 0) => {

    const newoptions = options.map(route => expand(route, maps)).reduce((all, cur) => {
        return all.concat(cur)
    }, [])

    if (newoptions.length == 0) {
        return options
    }

    if (JSON.stringify(options) == JSON.stringify(newoptions)) {
        return options
    }

    return expandAll(newoptions, maps, count + 1)
}

function pathOption(route, maps) {
    const {capacity, meta } = getMinCapacity(route, maps)
    const total_miu = meta && meta.total_miu

    if (total_miu && route.length > 2) {
        return false
    }

    return {
        path: route,
        segments: getSegmentPair(route),
        meta,
        capacity,
    }
}

const addPath = (pointa, pointb, capacity, meta, paths) => Object.assign({}, paths, {
    [pointa]: Object.assign({}, paths[pointa], {
        [pointb]: {
            capacity,
            meta
        }
    }),
    [pointb]: Object.assign({}, paths[pointb], {
        [pointa]: {
            capacity,
            meta
        }
    }),
})

const calculateMaxCapacity = (paths, routes)  => {
    return (MAX) => {
        const maxOfferedCapacity = getBottleneckMax(routes, paths)

        if (!MAX) {
            return maxOfferedCapacity
        }
        return maxOfferedCapacity < MAX ? maxOfferedCapacity : MAX
    }
}

const getSegmentPair = R.pipe(
    R.map(d => [d, d]),
    R.flatten,
    R.drop(1),
    R.dropLast(1),
    R.splitEvery(2)
)

function pathfinder(paths = {}, packages = {}, miu = {}) {
    return {
        segments() {
            return paths  
        },
        points() {
            return R.uniq(R.keys(paths).concat(R.keys(miu)))
        },
        addMiu(cable_system, total_miu, routes) {
            const newMiu = R.reduce((all, cur) => {
                all[cur.locations[0]] = Object.assign({}, all[cur.locations[0]], {
                    [cur.locations[1]]: {
                        distance: cur.distance,
                        cable_system: cable_system,
                        total_miu,
                        to10G : cur.to10G
                   } 
                })

                all[cur.locations[1]] = Object.assign({}, all[cur.locations[1]], {
                    [cur.locations[0]]: {
                        distance: cur.distance,
                        cable_system: cable_system,
                        total_miu,
                        to10G : cur.to10G
                    }
                })

                return all
            }, R.clone(miu), routes)
            return pathfinder(paths, packages, newMiu)
        },
        getMiu(cable_system) {
            return R.pipe(R.values,R.map(R.values),R.flatten,R.find(data => data.cable_system == cable_system),R.prop('total_miu'))(miu)
        },
        getMiuCapacity(a, b) {
            const record = miu[a][b]
            return record.total_miu / record.to10G * 10
        },
        add(pointa, pointb, capacity, meta = {}) {
            const newPaths = addPath(pointa, pointb, capacity, meta, paths)
            return pathfinder(newPaths, packages, miu)
        },
        addPackage(name, routes = []) {

            // check for miu path
            routes.forEach(route => {
                const miu_data = (miu[route[0]] || {})[route[1]]
                if (miu_data) {
                    paths = addPath(route[0],route[1],this.getMiuCapacity(route[0], route[1]), miu_data, paths)
                }
            })

            const newPackages = Object.assign({}, packages, {
                [name]: routes
            })

            return pathfinder(paths, newPackages, miu)
        },
        getSegmentCapacity(a, b) {
            return (paths[a][b] || { capacity: 0}).capacity
        },
        calculateCapacity(routes, MAX = 0) {
            const start = getBottleneckMax(routes, paths, R.take)
            const end = getBottleneckMax(routes, paths, R.takeLast)
            const maxOfferedCapacity = R.min(start, end)

            if (MAX == 0) {
                return maxOfferedCapacity
            }

            return maxOfferedCapacity < MAX ? maxOfferedCapacity : MAX
        },
        getPackage(name, excludes = []) {
            if (!packages[name]){
                return {
                    name: '',
                    routes: [],
                    points: [],
                    capacity: 0
                }
            }

            const getRoutePaths = route => this.find(route[0], route[1])
            
            let routes = R.pipe(
                R.map(getRoutePaths),
                R.flatten,
                R.uniq,
                R.sort(R.descend(R.prop('capacity')))
            )(packages[name] || [])

            if (excludes.length > 0) {
                routes = R.pipe(
                    R.map(route => Object.assign({}, route , {
                        containExclude: R.intersection(route.path, excludes).length > 0
                    })),
                    R.filter(d => d.containExclude == 0)
                )(routes)
            }

            const MaxCapacity = R.pipe(
                R.pluck('path'),
                Bottleneck.getBottleneckSegmnets,
                Bottleneck.getBottleneckCapacity(paths),
                calculateMaxCapacity(paths, routes)
            )(routes)

            const bottlenecks = R.pipe(
                R.map(d => R.pipe(
                    R.map(pair => ({
                        pair,
                        capacity: d.capacity
                    })),
                )(d.segments)),
                R.flatten,
                R.groupBy(d => d.pair.join('-')),
                R.map(list => {
                    const pair = R.head(list).pair
                    const requiredCapacity = R.pipe(
                        R.pluck('capacity'),
                        R.sum
                    )(list)
                    const segmentCapacity = this.getSegmentCapacity(pair[0], pair[1])
                    const bottleneck = requiredCapacity > segmentCapacity
                    const offerableCapacity = bottleneck ? segmentCapacity : requiredCapacity

                    return {
                        segmentCapacity,
                        requiredCapacity,
                        offerableCapacity,
                        bottleneck,
                    }
                }),
                R.filter( d => d.bottleneck)
            )(routes)

            const offerableCapacity = R.pipe(
                R.map(route => {
                    return Object.assign({}, route, {
                        bottleneck: R.pipe(
                            R.map(pair => {
                                const segment = pair.join('-')
                                return {
                                    segment,
                                    bottleneck: bottlenecks[segment]
                                }
                            }),
                            R.filter(d => d.bottleneck),
                            R.sortBy(d => d.bottleneck.offerableCapacity),
                            R.head,
                        )(route.segments)
                    })
                }),
                R.groupBy(d => d.bottleneck ? 'bottleneck' : 'none'),
                groups => {
                    const total = R.pipe(
                        R.pluck('capacity'),
                        R.sum
                    )(groups.none || [])

                    const totalBottleneck = R.pipe(
                        R.indexBy(d => d.bottleneck.segment),
                        R.map(d => d.bottleneck.bottleneck.offerableCapacity),
                        R.values,
                        R.sum,
                    )(groups.bottleneck || [])

                    return total + totalBottleneck
                }
                
            )(routes)
            
            return {
                name,
                routes,
                points: packages[name],
                capacity: parseFloat((offerableCapacity > MaxCapacity ? MaxCapacity : offerableCapacity).toFixed(3))
                // capacity: (MaxCapacity).toFixed(2)  //offerableCapacity
            }
        },
        packages() {
            return R.keys(packages)  
        },
        expand(a) {
            return expandAll([
                [a]
            ], paths).filter(data => data)
        },
        find(a, b) {
            const routes = expandAll([[a]], paths).filter(path => path &&  path.indexOf(b) > -1).map(route => R.head(R.splitWhen(data => data == b, route)).concat([b]))
            const options = routes.map(route => pathOption(route, paths)).filter(data => data)
            return options
        }

    }
}

module.exports = pathfinder

module.exports.miuRoute = (a, b, distance, to10G) => {
    return {
        locations: [a,b],
        distance,
        to10G
    }
}