const R = require('ramda')
const Validator = require('vee-validate').Validator

export const rules = {
    bandwidth: {
        required:true,
        decimal: 3,
        min_value: 0.1,
    },
    customer: {
        required: true,
    },
    contact_person: {
        required: true,
    },
    salesforce_id: {
        required: true,
        regex: /^[\w-]+$/
    },
    type: {
        required: true
    },
    capacities: {
        required: true
    }
}

const Reservation = () => {
    return {
        bandwidth: '',
        customer: '',
        contact_person: '',
        salesforce_id: '',
        type: '',
        capacities: []
    }
}

export async function validate(data) {
    const validator = new Validator(rules)
    await validator.validateAll(Object.assign(Reservation(),data))
    
    return R.pipe(R.indexBy(R.prop('field')), R.map( data => data.msg))(validator.errors.items)
}

const filterCancelled = (includeAll) => {
    if (includeAll) {
        return () => true
    }

    return (data) => data.reservation.status == 'open'
}

export function byCapacity(reservations, includeAll = false) {
    return R.pipe(
        R.map(reservation => {
            const reservationObj = R.pick([
                'contact_person',
                'bandwidth',
                'reserved_by',
                'type',
                'salesforce_id',
                'customer',
                'status'
            ], reservation)

            return R.map(capacity => {
                return Object.assign({
                    inventory_id: capacity.inventory_id
                }, {
                    capacity: capacity.capacity,
                    reservation: reservationObj,
                })
            }, reservation.capacities)
        }),
        R.flatten,
        R.filter(filterCancelled(includeAll)),
        R.groupBy(data => data.inventory_id)
    )(reservations)
}