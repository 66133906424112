<template>
    <div class="card">
        <div class="card-content">
            <input type="hidden" v-model="form.capacities" name="capacities" v-validate="rules.capacities">
            <label class="label">Bandwidth</label>
            <div class="field has-addons">
                <div class="control is-expanded ">
                    <input type="text" class="input"  v-validate="rules.bandwidth" :class="hasError('bandwidth')" name="bandwidth" v-model='form.bandwidth'>
                    <span class="is-size-7 has-text-danger">{{ errors.first('bandwidth') }}</span>
                </div>
                <div class="control">
                    <span class="button is-static">Gbps</span>
                </div>
            </div>

            
            <div class="columns">
                <div class="column">
                    <label class="label">Contract Type</label>
                    <input v-model="form.type" v-validate="rules.type" name="type" type="hidden">

                    <div class="field has-addons">
                        <div class="control is-expanded">
                            <label for="leasing" class="button is-fullwidth" :class="selectedReservationType('leasing')" @click="selectReservationType('leasing')">
                                Leasing
                                <input class="is-sr-only" type="radio" name="reservation_type" id="leasing" v-model="form.reservation_type" value="leasing">
                            </label>
                        </div>
                        <div class="control  is-expanded">
                            <label for="iru" class="button is-fullwidth " :class="selectedReservationType('iru')" @click="selectReservationType('iru')">
                                IRU
                                <input class="is-sr-only" type="radio" name="reservation_type" id="iru" v-model="form.reservation_type" value="iru">
                            </label>
                        </div>
                    </div>
                    <span class="is-size-7 has-text-danger" style="position:relative; top:-10px;">{{ errors.first('type') }}</span>
                </div>
                <div class="column">
                    <label class="label">Capacity type</label>
                    <div class="field">
                        <div class="control">
                            <input type="text" v-model="form.type" class="input is-capitalized" disabled>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="confirmed_order">
                <label class="label">Contact Person</label>
                <div class="field">
                    <div class="control">
                        <div v-if="!loaded" class="button is-white">
                            <span class="button is-loading is-white"></span>
                            Loading contact list...
                        </div>
                        <v-select v-else v-model="form.contact_person" class="select2" :options="contactpersons" data-vv-as="contact person" v-validate="rules.contact_person" :class="hasError('contact_person')" name="contact_person" >
                        </v-select>
                        <span class="is-size-7 has-text-danger">{{ errors.first('contact_person') }}</span>
                    </div>
                </div>

                <label class="label">Customer</label>
                <div class="field">
                    <div class="control">
                        <input v-validate="rules.customer" :class="hasError('customer')" name="customer" type="text" class="input" v-model="form.customer">
                        <span class="is-size-7 has-text-danger">{{ errors.first('customer') }}</span>
                    </div>
                </div>

                <label class="label">Salesforce ID</label>
                <div class="field">
                    <div class="control">
                        <input data-vv-as="Salesforce ID" v-validate="rules.salesforce_id" :class="hasError('salesforce_id')" name="salesforce_id" type="text" class="input" v-model="form.salesforce_id">
                        <span class="is-size-7 has-text-danger">{{ errors.first('salesforce_id') }}</span>
                    </div>
                </div>

                <span class="is-size-7 has-text-grey-light">Reservation will expired on <span class="has-text-grey">{{ expiredDate }}</span></span>
            
            </div>
        </div>
        <div class="card-footer">
            <div class="card-footer-item is-paddingless">
                <button v-if="loaded" @click.prevent="handleSubmit" class="button is-radiusless is-medium is-primary is-fullwidth" :class="{'is-loading': formIsLoading}" >SUBMIT RESERVATION</button>
                <button v-else class="button is-radiusless is-medium is-primary is-fullwidth is-disabled" disabled>SUBMIT RESERVATION</button>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import moment from 'moment'
import VSelect from 'vue-select'

export default {
    props:['rules', 'form', 'confirmed_order'],
    components:{
        VSelect,
    },
    data(){
        return {
            // contactpersons: []
            loading: false,
            // form: {}
        }
    },
    computed:{
        loaded(){
            return this.contactpersons.length > 0 && this.confirmed_order
        },
        contactpersons(){
            return R.pipe(
                R.map(user => `${user.staff_id} : ${user.name}`)
            )(this.$store.state['user-management'].users || [])
        },
        expiredDate(){
            return moment().add(1, 'month').format('DD MMM YYYY')
        },
        formIsLoading(){
            return this.loading;
        },
        submission_form(){
            return Object.assign({},this.form, {
                capacities: this.form.capacities.map(capacity => {
                    const unit = (capacity.selected || {}).unit || ''
                    return {
                        inventory_id: capacity.id,
                        cable_system: capacity.cable_system,
                        route: capacity.route,
                        capacity: Object.assign({}, capacity.selected, {
                            consumedQty: !capacity.selected ? 0 : capacity.selected.consumedQty(this.form.bandwidth),
                            unitLabel: unit == 'miu' ? ' MIU' : ` x ${unit}`
                        }),
                    }
                })
            })
        },
    },
    mounted(){
        if(this.contactpersons.length == 0){
            this.$store.dispatch('user-management.load')
        }
    },
    watch:{
        'form.reservation_type'(type){
            const type_map = {
                'leasing' : 'asset',
                'iru' : 'stock'
            }
            this.form.type = type_map[type]
            this.$validator.validate('type')
        }
    },
    methods:{
        selectedReservationType(type){
            return {
                'is-info' : type === this.form.reservation_type,
            }
        },
        hasError(name){
            return { 
                'is-danger' : this.errors.first(name),
                'has-text-danger' : this.errors.first(name),
            }
        }, 
        selectReservationType(type){
            return
            this.form.reservation_type = type
            const type_map = {
                'leasing' : 'asset',
                'iru' : 'stock'
            }
            this.form.type = type_map[type]
            this.$validator.validate('type')
        },
        async handleSubmit(){
            await this.$validator.validateAll();

            if(this.$validator.errors.any()){
                this.$emit('error', this.$validator.errors.items.length == 1 ? R.head(this.errors.all()) : null) 
            }else{
                this.loading = true;
                this.$store.dispatch('capry.reservations.submit', this.submission_form).then(res => {
                    this.$emit('success', res.data.message) 
                }).catch(err => {
                    this.$emit('error', err.response.data.message) 
                }).then(() => {
                    this.loading = false;
                })
            }
        }
    },
}
</script>
