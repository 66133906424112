<template>
<svg viewBox="0 0 398 257" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="map" clip-path="url(#clip0)">
<rect width="398" height="257" fill="white"/>
<map-bg />
<g id="routes">
<rect width="353.733" height="182.492" fill="black" fill-opacity="0" transform="translate(24.9049 52.1656)"/>

<path class="hide" :class="{ show: showRoute(['ph','sg']) }" id="route_ph_sg" d="M260.5 164.5C279.5 190 258.099 210.974 213.277 195.416" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['chi','hk']) }" id="route_chi_hk" d="M180.558 98.9146C193.077 72.421 247 91 234.507 129.142" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['chi','jpn']) }" id="route_chi_jpn" d="M180.5 99.5C177.057 78.1986 282.398 62.3542 296 91" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['chi','tai']) }" id="route_chi_tai" d="M180.5 98C184.5 76.5 284 74 259.5 130.5" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['jpn','kor']) }" id="route_jpn_kor" d="M277.5 88C278.683 77.9398 306.132 65.5931 298.781 92.0105" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['sg','tai']) }" id="route_sg_tai" d="M260 134.5C354.5 182.5 252 217.5 215 196" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['jpn','us']) }" id="route_jpn_us" d="M296.784 93.549C317.428 71.3356 331.873 63.7649 366.784 59.549" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['hk','us']) }" id="route_hk_us" d="M366.5 59.5C335.809 116.652 307.383 129.002 234.5 128.5" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['my','sa']) }" id="route_my_sa" d="M57.128 147.517C97.0965 199.686 131.004 208.406 208.128 187.517" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['phi','sg']) }" id="route_ph_sg" d="M260.5 164.5C279.5 190 258.099 210.974 213.277 195.416" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['ind','my']) }" id="route_ind_my" d="M136.241 148.562C160.758 170.233 176.287 177.806 207.241 186.562" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['hk','jpn']) }" id="route_hk_jpn" d="M234.759 127.562C252.626 106.06 265.698 98.7315 294.759 94.5619" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['eu','hk']) }" id="route_eu_hk" d="M58.1756 62.5318C132.929 75.5461 171.49 89.9363 234.176 128.532" stroke="#4950F8" width="3" />
<path class="hide" :class="{ show: showRoute(['eu','uae']) }" id="route_eu_uae" d="M56.3741 62.6682C83.8212 78.2098 93.9995 89.8782 103.374 115.668" stroke="#4950F8" stroke-linejoin="round"/>
<path class="hide" :class="{ show: showRoute(['jpn','my']) }" id="route_jpn_my" d="M208.637 188.656C256.141 159.601 274.958 138.338 295.637 95.6564" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['jpn','sg']) }" id="route_jpn_sg" d="M296.391 93.3119C291.529 151.598 272.652 173.917 213.391 197.312" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['hk','sg']) }" id="route_hk_sg" d="M213.523 195.85C259.172 203.106 263.603 188.281 234.523 128.85" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['hk','my']) }" id="route_hk_my" d="M234.455 129.207C231.823 153.894 225.212 167.875 208.455 186.207" stroke="#4950F8"/>
<line class="hide" :class="{ show: showRoute(['my','tha']) }" id="route_my_tha" x1="206.5" y1="160.979" x2="207.5" y2="184.979" stroke="#4950F8"/>
<line class="hide" :class="{ show: showRoute(['cam','my']) }" id="route_cam_my" x1="211.494" y1="171.076" x2="209.494" y2="184.076" stroke="#4950F8"/>
<line class="hide" :class="{ show: showRoute(['cam','tha']) }" id="route_cam_tha" x1="208.354" y1="163.646" x2="210.354" y2="165.646" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['my','uae']) }" id="route_my_uae" d="M104.286 114.59C104.286 114.59 151.731 124.634 170 137.5C191.435 152.596 206.286 185.59 206.286 185.59" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['eu','sg']) }" id="route_eu_sg" d="M57.3268 63.6216C69.4999 162.519 104.679 191.189 212.327 196.622" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['ina','sg']) }" id="route_ina_sg" d="M212.32 197.616C218.856 208.905 224.114 213.275 236.32 217.616" stroke="#4950F8"/>
<line class="hide" :class="{ show: showRoute(['my','sg']) }" id="route_my_sg" x1="208.474" y1="187.842" x2="211.474" y2="196.842" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['ina','my']) }" id="route_ina_my" d="M208.359 186.652C227.255 195.485 233.728 201.315 238.359 216.652" stroke="#4950F8"/>
<path class="hide" :class="{ show: showRoute(['jpn','tai']) }" id="route_jpn_tai" d="M260.5 134C284.738 130.793 290.684 123.446 296.651 96.6419" stroke="#4950F8"/>
</g>
<g id="labels">
<rect width="337" height="176" fill="black" fill-opacity="0" transform="translate(43 46)"/>


<g @click="pointSelect('chi')" :class="{selectedPoint: isSelected('chi')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }" id="label_chi">
<path class="point_circle" id="Rectangle" d="M185 98C185 97.4477 185.448 97 186 97H211C211.552 97 212 97.4477 212 98V105C212 105.552 211.552 106 211 106H186C185.448 106 185 105.552 185 105V98Z" fill="#999797"/>
<text id="CHINA" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-size="4" letter-spacing="0em"><tspan x="192.273" y="102.621">CHINA</tspan></text>
<circle class="point_circle"  id="Ellipse" cx="179.5" cy="101.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('kor')" :class="{selectedPoint: isSelected('kor')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }" id="label_kor">
<path transform="translate(-25,20)" class="point_circle"  id="Rectangle_2" d="M265 67C265 66.4477 265.448 66 266 66H291C291.552 66 292 66.4477 292 67V74C292 74.5523 291.552 75 291 75H266C265.448 75 265 74.5523 265 74V67Z" fill="#999797"/>
<text transform="translate(-25,20)" id="CHINA_2" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="271.496" y="71.6211">KOREA</tspan></text>

<circle class="point_circle"  id="Ellipse_2" cx="277.5" cy="90.5" r="3.5" fill="#FF7A1A"/>
</g>

<g @click="pointSelect('phi')" :class="{selectedPoint: isSelected('phi')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }" id="label_phi">
<path class="point_circle" id="Rectangle" d="M266 160C266 159.448 266.448 159 267 159H292C292.552 159 293 159.448 293 160V167C293 167.552 292.552 168 292 168H267C266.448 168 266 167.552 266 167V160Z" fill="#999797"/>
<text id="PHILIPINES" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-size="4" letter-spacing="0em"><tspan x="267.156" y="164.621">PHILIPPINES</tspan></text>
<circle class="point_circle" id="Ellipse" cx="260.5" cy="163.5" r="3.5" fill="#FF7A1A"/>
</g>

<g @click="pointSelect('eu')" :class="{selectedPoint: isSelected('eu')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_europe">
<rect width="27" height="18" fill="black" fill-opacity="0" transform="translate(43 49)"/>
<path class="point_circle" id="Rectangle" d="M43 50C43 49.4477 43.4477 49 44 49H69C69.5523 49 70 49.4477 70 50V57C70 57.5523 69.5523 58 69 58H44C43.4477 58 43 57.5523 43 57V50Z" fill="#999797"/>
<text id="EUROPE" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="48.0508" y="54.6211">EUROPE</tspan></text>
<circle class="point_circle" id="Ellipse" cx="57.5" cy="63.5" r="3.5" fill="#FF7A1A"/>
</g>

<g @click="pointSelect('uae')" :class="{selectedPoint: isSelected('uae')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_uae">
<rect width="36" height="11" fill="black" fill-opacity="0" transform="translate(100 106)"/>
<rect class="point_circle" id="Rectangle_2" x="109" y="106" width="27" height="9" rx="1" fill="#999797"/>
<text id="UAE" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="119.387" y="111.621">UAE</tspan></text>
<circle class="point_circle" id="Ellipse_2" cx="103.5" cy="113.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('tha')" :class="{selectedPoint: isSelected('tha')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_thailand">
<rect width="29" height="20" fill="black" fill-opacity="0" transform="translate(190 144)"/>
<rect class="point_circle"  id="Rectangle" x="190" y="144" width="29" height="9" rx="1" fill="#999797"/>
<text id="THAILAND" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="195.104" y="149.621">THAILAND</tspan></text>
<circle class="point_circle" id="Ellipse" cx="206.5" cy="160.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('cam')" :class="{selectedPoint: isSelected('cam')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_cambodia">
<rect width="36" height="9" fill="black" fill-opacity="0" transform="translate(209 162)"/>
<rect class="point_circle" id="Rectangle_2" x="218" y="162" width="27" height="9" rx="1" fill="#999797"/>
<text id="CAMBODIA" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="220.328" y="167.621">CAMBODIA</tspan></text>
<circle class="point_circle" id="Ellipse_2" cx="212.5" cy="167.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('ind')" :class="{selectedPoint: isSelected('ind')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_india">
<rect width="37" height="9" fill="black" fill-opacity="0" transform="translate(133 144)"/>
<rect class="point_circle" id="Rectangle_4" x="143" y="144" width="27" height="9" rx="1" fill="#999797"/>
<text id="INDIA" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="151.162" y="149.621">INDIA</tspan></text>
<circle class="point_circle" id="Ellipse_4" cx="136.5" cy="148.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('sa')" :class="{selectedPoint: isSelected('sa')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_south_africa">
<rect width="27" height="20" fill="black" fill-opacity="0" transform="translate(43 132)"/>
<path class="point_circle" id="Rectangle_5" d="M43 133C43 132.448 43.4477 132 44 132H69C69.5523 132 70 132.448 70 133V143C70 143.552 69.5523 144 69 144H44C43.4477 144 43 143.552 43 143V133Z" fill="#999797"/>
<text id="SOUTH AFRICA" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="49.4395" y="136.621">SOUTH &#10;</tspan><tspan x="49.6621" y="141.221">AFRICA</tspan></text>
<circle class="point_circle" id="Ellipse_5" cx="57.5" cy="148.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('sg')" :class="{selectedPoint: isSelected('sg')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_singapore">
<rect width="33" height="19" fill="black" fill-opacity="0" transform="translate(198 193)"/>
<path class="point_circle" id="Rectangle_6" d="M198 204C198 203.448 198.448 203 199 203H230C230.552 203 231 203.448 231 204V211C231 211.552 230.552 212 230 212H199C198.448 212 198 211.552 198 211V204Z" fill="#999797"/>
<text id="SINGAPORE" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="203.104" y="208.621">SINGAPORE</tspan></text>
<path class="point_circle" id="Ellipse_6" d="M216 196.5C216 198.433 214.433 200 212.5 200C210.567 200 209 198.433 209 196.5C209 194.567 210.567 193 212.5 193C214.433 193 216 194.567 216 196.5Z" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('my')" :class="{selectedPoint: isSelected('my')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_malaysia">
<rect width="42.5" height="9" fill="black" fill-opacity="0" transform="translate(204.5 182)"/>
<path class="point_circle" id="Rectangle_7" d="M216 183C216 182.448 216.448 182 217 182H246C246.552 182 247 182.448 247 183V190C247 190.552 246.552 191 246 191H217C216.448 191 216 190.552 216 190V183Z" fill="#999797"/>
<text id="MALAYSIA" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="222.143" y="187.621">MALAYSIA</tspan></text>
<path class="point_circle" id="Ellipse_7" d="M211.5 187C211.5 188.933 209.933 190.5 208 190.5C206.067 190.5 204.5 188.933 204.5 187C204.5 185.067 206.067 183.5 208 183.5C209.933 183.5 211.5 185.067 211.5 187Z" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('jpn')" :class="{selectedPoint: isSelected('jpn')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_japan" >
<rect  width="39" height="10" fill="black" fill-opacity="0" transform="translate(300 87)"/>
<path transform="translate(45)" class="point_circle"  id="Rectangle_8" d="M261 88C261 87.4477 261.448 87 262 87H287C287.552 87 288 87.4477 288 88V95C288 95.5523 287.552 96 287 96H262C261.448 96 261 95.5523 261 95V88Z" fill="#999797"/>
<text transform="translate(45)" id="JAPAN" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="268.201" y="92.6211">JAPAN</tspan></text>
<circle class="point_circle" id="Ellipse_8" cx="296.5" cy="93.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('us')" :class="{selectedPoint: isSelected('us')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_usa">
<rect width="27" height="17" fill="black" fill-opacity="0" transform="translate(353 46)"/>
<path class="point_circle" id="Rectangle_9" d="M353 47C353 46.4477 353.448 46 354 46H379C379.552 46 380 46.4477 380 47V54C380 54.5523 379.552 55 379 55H354C353.448 55 353 54.5523 353 54V47Z" fill="#999797"/>
<text id="USA" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="363.387" y="51.6211">USA</tspan></text>
<circle class="point_circle" id="Ellipse_9" cx="366.5" cy="59.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('ina')" :class="{selectedPoint: isSelected('ina')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_indonesia">
<rect width="47" height="9" fill="black" fill-opacity="0" transform="translate(234 213)"/>
<path class="point_circle" id="Rectangle_10" d="M245 214C245 213.448 245.448 213 246 213H280C280.552 213 281 213.448 281 214V221C281 221.552 280.552 222 280 222H246C245.448 222 245 221.552 245 221V214Z" fill="#999797"/>
<text id="INDONESIA" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="252.492" y="218.621">INDONESIA</tspan></text>
<circle class="point_circle" id="Ellipse_10" cx="237.5" cy="217.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('tai')" :class="{selectedPoint: isSelected('tai')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"   id="label_taiwan">
<path class="point_circle" id="Rectangle" d="M253.881 115C253.881 114.448 254.328 114 254.881 114H279C279.552 114 280 114.448 280 115V124C280 124.552 279.552 125 279 125H254.881C254.328 125 253.881 124.552 253.881 124V115Z" fill="#999797"/>
<text id="TAIWAN" fill="white" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="259.441" y="120.621">TAIWAN</tspan></text>
<circle class="point_circle" id="Ellipse" cx="259.5" cy="133.5" r="3.5" fill="#FF7A1A"/>
</g>
<g @click="pointSelect('hk')" :class="{selectedPoint: isSelected('hk')}" class="label animated fadeIn faster" :style="{'animation-delay': `${1.5}s` }"  id="label_hong_kong">
<rect width="35" height="19" fill="black" fill-opacity="0" transform="translate(217 113)"/>
<path class="point_circle" id="Rectangle_11" d="M217 114C217 113.448 217.448 113 218 113H251C251.552 113 252 113.448 252 114V123C252 123.552 251.552 124 251 124H218C217.448 124 217 123.552 217 123V114Z" fill="#999797"/>
<text id="HONG KONG" fill="#FFFDFD" xml:space="preserve" style="white-space: pre" font-family="Arial" font-style="Regular" font-size="4" letter-spacing="0em"><tspan x="222.047" y="119.621">HONG KONG</tspan></text>
<g id="Ellipse_11">
<path class="point_circle" d="M238 128.5C238 130.433 236.433 132 234.5 132C232.567 132 231 130.433 231 128.5C231 126.567 232.567 125 234.5 125C236.433 125 238 126.567 238 128.5Z" fill="#FF7A1A"/>
</g>
</g>
</g>
</g>
<defs>
<clipPath id="clip0">
<rect width="398" height="257" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
import MapBg from './MapBg'
export default {
  components: {
    MapBg
  },
  props: ['pointSelected', 'currentPoint', 'registerRoute'],
  data () {
    return {
    }
  },
  methods: {
    showRoute (routes) {
      const show = routes.indexOf(this.currentPoint) > -1
      this.registerRoute(routes)
      return show
    },
    pointSelect (abbrv) {
      this.pointSelected(abbrv)
    },
    isSelected (point) {
      return point === this.currentPoint
    }
  }
}
</script>

<style scope>
.hide{
  display:none
}

.show{
  display:block !important;
}

.label{
  cursor:pointer;
}

.selectedPoint .point_circle {
  fill: #FF0000;
}
</style>