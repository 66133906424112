<template>
    <div v-if="capacity.selected.category != 'onnet'">
        <div v-if="capacity.selected.options.length > 0" class="" style="margin-top:20px;">
            <div class="card">
                <div class="card-header">
                    <span class="card-header-title is-7">
                        {{ capacity.selected.category == 'apollo' ? 'BTN CCT IDS' : 'PROVIDERS' }}
                    </span>
                </div>
                <div class="card-content">
                    <div class="columns">
                        <div class="column ">
                            <span class="menu">
                                <p class="menu-label">
                                    SELECTED
                                </p>
                                <ul class="menu-list">
                                    <li class="animated slideInRight faster" v-for="(o,i) in selected" :key="i" ><a @click="remove(o)" >{{ o }}</a></li>
                                </ul>
                            </span>
                        </div>
                        <div class="column">
                            <span class="menu ">
                                <p class="menu-label">
                                    AVAILABLE
                                </p>
                                <ul class="menu-list ">
                                    <li class="animated slideInLeft faster " v-for="(i,o) in availableOptions" :key="o">
                                        <a :class="{'maxed' : isMaxed}" @click="select(o)">
                                            <span v-if="capacity.selected.category == 'offnet'" class="tag is-dark">{{ i }}</span> {{ o }} 
                                        </a>
                                    </li>
                                </ul>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
export default {
    props: ['capacity', 'qty'],
    data(){
        return {
            selected: []
        }
    },
    watch:{
        selected(value){
            this.$set(this.capacity.selected, 'selected_options', value)
        }
    },
    computed:{
        selectedCount(){
            return R.pipe(
                R.groupBy(R.identity), 
                R.map(data => data.length)
            )(this.selected)
        },
        options(){
            return R.pipe(
                R.groupBy(R.identity), 
                R.mapObjIndexed((data,key) => data.length - (this.selectedCount[key] || 0)),
                R.filter(data => data > 0)
            )(this.capacity.selected.options)
        },
        availableOptions(){
            return this.options
        },
        isMaxed(){
            return this.selected.length >= this.qty
        }
    },
    methods:{
        select(item){
            if(!this.isMaxed){
                this.selected.push(item)
            }
        },
        remove(item){
            this.selected.splice(this.selected.indexOf(item),1)
        },
    }
}
</script>


<style scoped>
.maxed {
    cursor: not-allowed !important;
}
</style>
