<template>
    <div class="section">
        <div class="notification animated faster is-radiusless" style="position: fixed; top: 0px; left:0px; z-index:99; width: 100%;" :class="notificationAnim">
        <div class="container">
          <button class="delete" @click="hideErrorNotification" style="float:right"></button>
          {{ notification.message }}
        </div>
      </div>
        <div class="card" style="margin-bottom: 40px;">
            <div class="card-content"><steps @change="changeStep" :current_step="current_step" :confirmed_order="confirmed_order" /></div>
        </div>

        <step-1 v-if="current_step == 1" @error="showNotification" @nextStep="nextStep" />
        <div v-if="current_step == 2">
            <h1 class="title is-5">New Reservation</h1>
            <step-2 @success="success" @error="showNotification" :confirmed_order="confirmed_order" @changeStep="changeStep" />
        </div>
            
    </div>
</template>


<script>
import * as R from 'ramda'
import Step1 from './Step1'
import Step2 from './Step2'
import Steps from './Steps.vue'

export default {
    beforeRouteEnter(to,from,next){
        next(vm => {
            if(!vm.$store.getters.capry_usercan('manage_reservation')){
                vm.$router.replace('/')
            }
        })
    },
    components:{
        Step1,
        Step2,
        Steps,
    },
    data(){
        return {
            confirmed_order: false,
            current_step: 1,
            notification: {
                status: false,
                message: '',
                timer: false
            },
        }
    },
    computed:{
        notificationAnim(){
            if(!this.notification.status){
                return {'hide': true}
            }

            if(this.notification.status == 'show'){
                return {'slideInDown' : true , [this.notification.type || 'is-danger'] : true}
            }

            if(this.notification.status == 'hide'){
                return {'slideOutUp' : true, [this.notification.type || 'is-danger'] : true}
            }
        },
        isLoading(){
            return this.routes.length == 0
        },
    },
    methods:{
        success({msg,salesforce_id}){
            this.showNotification(msg, 'is-success')
            setTimeout(() => {
                this.$router.push({name: 'reservation.v1.show', params: { id:salesforce_id}})
            },1000)
        },
        changeStep(step){
            this.current_step = step
        },
        nextStep(confirmed_order){
            this.confirmed_order = confirmed_order
            this.current_step = 2
        },
        hideErrorNotification(){
            this.notification = {
                status: 'hide'
            }
        },
        showNotification(msg, type){
            if(this.notification.timer){
                clearTimeout(this.notification.timer)
            }
            this.notification = {
                status: 'show',
                message: msg || 'Validation error. Please check the form',
                timer: setTimeout(() => { this.hideErrorNotification() } , 3000),
                type

            }
        }
    }
}
</script>


<style>
.v-select , .v-select .dropdown-toggle{
    width: 100%;
}

.is-clickable{
    cursor:pointer !important;
}

.has-border-danger{
    box-shadow: 0 2px 3px rgba(255, 17, 17, 0.6), 0 0 0 1px rgba(255, 17, 17, 0.6)
}

.hide{
  display: none;
}
.capry_radio {
  display: inline-block;
  padding: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
}

.capry_radio.is-danger{
  border-color: #ff0000 !important;
}

.easeInOutBack {
    -webkit-animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550) !important;
    animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550)  !important;
}

.easeOutBack {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275) !important;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275)  !important;
}

</style>
