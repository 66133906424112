<template>
    <div class="box">
        <div class="level">
            <div class="level-start">
                <div>
                    <h2 class="title is-5">
                        {{ route[0] }} → {{ route[1] }}
                    </h2>
                    <div class="tags">
                        <span class="tag is-white">
                            <div class="level">
                                <div class="level-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
                                </div>
                                <div class="level-item" style="margin-left:5px;">
                                    {{ options.length }} paths options
                                </div>
                            </div>

                        </span>

                        <span class="tag is-white">
                            <div class="level">
                                <div class="level-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="20" x2="18" y2="10"></line><line x1="12" y1="20" x2="12" y2="4"></line><line x1="6" y1="20" x2="6" y2="14"></line></svg>
                                </div>
                                <div class="level-item" style="margin-left:5px;">
                                    {{ capacity }} GB 
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="level-end">
                <a v-if="!showOption" @click="showOption = true" class="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M9 18l6-6-6-6"/></svg>
                </a>

                <a v-if="showOption" @click="showOption = false" class="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>
                </a>
            </div>
        </div>
        <div v-if="showOption">
            <h3 class="title is-5">Path Options</h3>
            <div class="columns is-multiline">
                <div v-for="(option,i) in sortedOptions" :key="i"  class="column is-6">
                    <path-options :optionId="i" :option="option" :pathfinder="pathfinder" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import * as R from 'ramda'
import RouteFromTo from './RouteFromTo'
import PathOptions from './PathOptions'

export default {
    props:['route', 'options', 'pathfinder'],
    components:{
        RouteFromTo,
        PathOptions,
    },
    data(){
        return {
            showOption: false,
        }
    },
    computed:{
        capacity(){
            return this.pathfinder.calculateCapacity(this.options)
        },
        sortedOptions(){
            return R.sort(R.descend(data => parseFloat(data.capacity)) , this.options)
        }
    }
}
</script>
