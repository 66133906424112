<template>
    <div class="card has-background-light" style="height:500px;">
        <offering-modal :selectedOffering="selectedOffering" @close="selectedOffering = false"/>
        <l-map
            v-if="loaded"
            ref="map"
            :zoom="zoom"
            :maxZoom="14"
            :minZoom="2"
            :center="center"
            :options="mapOptions"
            :style="{
                height: '500px',
                width: '100%',
                zIndex: 27
            }"
            class="has-background-light"
        >
            <l-tile-layer
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors &copy; <a href='https://carto.com/attributions'>CARTO</a>"
            />

            <l-marker v-if="countryA" class="animated fadeInUp" :key="countryA" :lat-lng="getCoordinate(countryA)"><l-popup :content="countryA"></l-popup></l-marker>
            <l-marker v-if="countryB" class="animated fadeInUp" :key="countryB" :lat-lng="getCoordinate(countryB)"><l-popup :content="countryB"></l-popup></l-marker>
            <!-- <l-polyline
                v-if="latlngs && showLine"
                :lat-lngs="latlngs"
                color="blue">
            </l-polyline> -->
            
            <l-control position="topright">
                <div class="box" style="width:250px">
                    <div class="card-content" v-if="!routes_asset || !routes_stock">
                        <span class="button is-loading is-white is-fullwidth"></span>
                    </div>
                    <div v-else>
                        <span class="heading">Pick location to view estimated capacity</span>
                        <div class="field">
                            <div class="control">
                                <div class="select is-fullwidth" aria-placeholder="from">
                                    <select name="countryA" id="countryA" v-model="countryA" placeholder="From">
                                        <option v-for="country in countrylist(countryB)" :key="country" :value="country">{{ country }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="control">
                                <div class="select is-fullwidth" aria-placeholder="from">
                                    <select name="countryB" id="countryB" v-model="countryB" placeholder="From">
                                        <option v-for="country in countrylist(countryA)" :key="country" :value="country">{{ country }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <offering-result-box v-if="offeringResult" :offeringResult="offeringResult" @offeringSelected="offeringSelected" />
            </l-control>
        </l-map>
    </div>
</template>


<script>
import 'animate.css'
import * as R from 'ramda'
import OfferingResultBox from './Offering'
import OfferingModal from './OfferingModal'

import "leaflet/dist/leaflet.css";
import { latLng, Icon } from "leaflet";
const GoogleDocs =  require('./../../../utils/GoogleDocs')
import PathFinderService from './../Offering/PathFinderService'
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import {
  LMap,
  LPopup,
  LMarker,
  LControl,
  LTooltip,
  LTileLayer,
  LIconDefault,
  LPolyline,
} from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
    components:{
        LMap,
        LPopup,
        LMarker,
        LControl,
        LTooltip,
        LTileLayer,
        LIconDefault,
        LPolyline,
        latLng,
        Icon,
        LCluster: Vue2LeafletMarkercluster,
        OfferingResultBox,
        OfferingModal,
    },
    data(){
        return {
            loaded: false,
            zoom : 2,
            center : latLng(4.8, 108.81),
            latlng: latLng(34.28,69.11),
            coordinates: false,
            countryA: false,
            countryB: false,
            paddingBound: 50,
            routes_asset: false,
            routes_stock: false,
            showLine: false,
            selectedOffering: false,
        }
    },
    mounted(){
        this.loadCoordinates()
        this.loadData()
    },
    watch:{
        latlngs(values){
            if(values){
                setTimeout(() => this.zoomFit(), 500)
            }
        },
    },
    computed:{
        mapOptions(){
            return {
            }
        },
        latlngs(){
            if(this.countryA && this.countryB){
                this.showLine = false
                setTimeout(() => this.showLine = true, 1200)
                return [
                    this.getCoordinate(this.countryA),
                    this.getCoordinate(this.countryB),
                ]
            }
        },
        routes(){
            return this[`routes_${this.capacity_type}`]
        },
        segments(){
            return this.routes.segments()  
        },
        miu_routes(){
            return this.$store.state.capry.miu_routes
        },
        
        capacities(){
            return this.$store.state.capry.routes
        },
        offeringResult(){
            if(!this.countryA || !this.countryB){
                return false
            }

            return {
                stock: this.routes_stock.calculate(this.countryA, this.countryB),
                asset: this.routes_asset.calculate(this.countryA, this.countryB),
            }
        },
    },
    methods:{
        countrylist(start = ''){
            if(!this.routes_asset){
                return []
            }
            return this.routes_asset.getCountries(start)
        },
        loadCoordinates(){
            GoogleDocs('1JW3l6A2_9Neg6tMykGtxCVXDbt8FlHHgbu0wgB2n5i0').then(data => {
                this.coordinates = R.indexBy(R.prop('Country'), data)
                this.loaded = true
            })
        },
        getCoordinate(country){
            return latLng((this.coordinates[country] || {}).Latitude, (this.coordinates[country] || {}).Longitude)
        },
        zoomFit(){
            this.$refs.map.mapObject.flyToBounds(this.latlngs, {
                // padding: [100,100],
                paddingBottomRight: [280,50],
                paddingTopLeft:[50,50]
            })
        },
        loadData(){
            Promise.all([
                this.$store.dispatch('capry.load.miuroutes'),
                this.$store.dispatch('capry.loadRoutes')
            ]).then(() => {
                this.routes_asset = PathFinderService(this.capacities, this.miu_routes, 'asset')           
                this.routes_stock = PathFinderService(this.capacities, this.miu_routes, 'stock')           
                this.loaded = true  
            })
        },
        offeringSelected(offering){
            this.selectedOffering = offering
        }
    }
}
</script>

<style lang="scss">
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
