<template>
    <div class="card animated faster zoomIn">
        <div class="card-content has-text-white">
            <div class="content">
                <p class="is-6 has-text-grey">Please check your email and click "verify" to authenticate</p>
                <p class="has-text-centered">
                    <span class="tag is-dark">
                    {{ $store.state.login.phrase }}
                    </span> 
                </p>
            </div>
        </div>
        <div class="card-footer">
            <span class="card-footer-item">
                <p>Didn't get the email?</p>  &nbsp;
                <a href="javascript:void()" @click.prevent="handleLogout" class="button ">
                    Try again
                </a>
            </span>
        </div>
    </div>
</template>


<script>
export default {
    data(){
        return {
            timer: false
        }
    },
    computed: {
        login_status(){
            return this.$store.getters.login_status
        }
    },
    watch:{
        login_status(newval){
            if(newval === 'login'){
                this.$store.dispatch('hideLogin')
            }
        }
    },
    methods:{
        handleLogout(){
            this.$emit('reset')
        },
        doCheck(){
            this.$store.dispatch('login.check')
                .then(res => {
                    if(!res){
                        this.$store.dispatch('login.logout')
                        return
                    }
                    if(res.status === 'pending'){
                        this.check(2000)
                        return
                    }

                    this.$emit('success')
                })
                .catch(err => {
                    this.$emit('error' , (err.response||{}).message ||  err.message)
                })
        },
        check(delay){
            if(this.timer && this.login_status == 'pending'){
                setTimeout(this.doCheck, delay)
            }
        }
    },
    mounted(){
        this.timer = true;
        this.check(0)
    },
    destroyed(){
        this.timer = false
    }
}
</script>
