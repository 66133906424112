const Inventory = require('./Inventory/Index.vue').default
const ReservationV1 = require('./ReservationsV1/Index.vue').default
const ReservationLatest = require('./Reservations/Index.vue').default

module.exports = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/cables',
        component: () => import('./Cables/Cables.vue'),
        children: [
            {
                path: '/',
                component: () => import('./Cables/index.vue')
            },
            {
                path: ':cableId',
                component: () => import('./Cables/_cableId.vue')
            }
        ]
    },
    {
        path: '/migration',
        component: () => import( /* webpackChunkName: "migration" */ './Migration.vue')
    },
    {
        path: '/dashboard-legacy',
        component: () => import( /* webpackChunkName: "dashboard-legacy" */  './../Dashboard.vue'),
    },
    {
        path: '/dashboard',
        component: () => import( /* webpackChunkName: "dashboard-public" */ './../Dashboard/NewDashboardPublic.vue'),
    },
    {
        path: '/cip-dashboard',
        component: () => import( /* webpackChunkName: "dashboard-cip" */ './../Dashboard/CipDashboard.vue'),
    },
    {
        path: '/path-finder', 
        component: () => import( /* webpackChunkName: "offerings" */ './../Offering/index.vue'),
    },
    {
        path: '/inventory',
        component: Inventory,
        children: [
            {
                path: '',
                component: () => import( /* webpackChunkName: "inventory.listContainer" */ './Inventory/ListContainer.vue')
            },
            {
                path: 'add',
                component: () => import( /* webpackChunkName: "inventory.add" */ './Inventory/Add.vue')
            },
            {
                path: 'show/:id',
                component: () => import( /* webpackChunkName: "inventory.show" */ './Inventory/Show.vue')
            }
        ]
    },
    {
        path: '/reservations',
        component: ReservationV1,
        children: [{
                name: 'reservation.v1.index',
                path: '',
                component: () => import( /* webpackChunkName: "reservation.list" */ './ReservationsV1/List.vue')
            },
            {
                name: 'reservation.v1.create',
                path: 'create',
                component: () => import( /* webpackChunkName: "reservation.create" */ './ReservationsV1/Create.vue')
            }, {
                name: 'reservation.v1.show',
                path: 'show/:id',
                component: () => import( /* webpackChunkName: "reservation.show" */ './ReservationsV1/Show.vue')
            }
        ]

    },
    {
        path: '/reservations-new',
        component: ReservationLatest,
        children: [
            {
                name: 'reservation.v2.index',
                path: '',
                component: () => import( /* webpackChunkName: "reservation.list" */ './Reservations/List.vue')
            },
            {
                name: 'reservation.v2.create',
                path: 'create',
                component: () => import( /* webpackChunkName: "reservation.create" */ './Reservations/Create.vue')
            }, {
                name: 'reservation.v2.show',
                path: 'show/:id',
                component: () => import( /* webpackChunkName: "reservation.show" */ './Reservations/Show.vue')
            }
        ]
        
    }
]