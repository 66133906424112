<template>
    <div>
        <h3 class="title is-4">Offnet Info</h3>
        <div class="field">
            <label class="label">Provider</label>
            <div class="control">
                <input type="text" class="input" name="provider" v-validate.initial="rules.provider" v-model="form.provider" :class="hasError('provider')">
            </div>
        </div>

        <label class="label">IRU Cost</label>
        <div class="field">
            <div class="control">
                <vue-numeric class="input" name="iru_cost" v-validate="rules.iru_cost"  currency="$" separator="," v-model="form.iru_cost" :class="hasError('iru_cost')"></vue-numeric>
            </div>
        </div>

        <label class="label">O&amp;M</label>
        <div class="field has-addons">
            <div class="control">
                <vue-numeric class="input" currency="$" v-validate="rules.onm" separator="," v-model="form.onm" :class="hasError('onm')"></vue-numeric>
            </div>
            <div class="control">
                <span class="button is-static">
                    per year
                </span>
            </div>
        </div>

        <label class="label">Contract term</label>
        <div class="field has-addons">
            <div class="control">
                <input class="input" name="contract_years" v-validate.initial="rules.contract_years" type="text" v-model="form.contract_years" :class="hasError('contract_years')">
            </div>
            <div class="control">
                <span class="button is-static">
                    Years
                </span>
            </div>
        </div>

        <label class="label">Contract Start date</label>
        <div class="field">
            <div class="control">
                <input type="hidden" name="contract_start_date" v-model="form.contract_start_date" v-validate.initial="rules.contract_start_date" >
                <month-year @update="(value) => form.contract_start_date = value" :class="hasError('contract_start_date')"/>
            </div>
        </div>

        <div class="field">
            <label class="label">OCM Reference #</label>
            <div class="control">
                <input type="text" class="input" name="ocm" v-validate.initial="rules.ocm" v-model="form.ocm" :class="hasError('ocm')">
            </div>
        </div>

        <!-- <label class="label">Partner Circuit ID [{{qty}}]</label>
        <div v-for="(cct_id, i) in cctRange" :key="i" class="field">
            <div class="control">
                <input :name="`btn_cct_ids_${i}`" type="text" class="input" v-model="form.btn_cct_ids[i]" v-validate="rules.btn_cct_ids" :class="hasError(`btn_cct_ids_${i}`)" :placeholder="`Partner Circuit ${i}`">
            </div>
        </div>
        <div class="buttons">
            <button @click.prevent="$emit('addCircuit')" class="button">Add Circuit</button>
        </div> -->
    </div>
</template>

<script>
import * as R from 'ramda'
import VueNumeric from 'vue-numeric'
import MonthYear from './../../comp/MonthYear.vue'
import { offnet_rules } from '../../../Models/SubmarineCable.js'
export default {
    inject: ['$validator'],
    props:['qty'],
    components: {
        MonthYear,
        VueNumeric
    },
    data(){
        return {
            form: {
                provider: '',
                iru_cost: 0,
                onm: 0,
                contract_years: 10,
                contract_start_date: '',
                ocm: '',
                // btn_cct_ids: [],
            },
            rules: offnet_rules
        }
    },
    watch:{
        form:{
            handler(newValue){
                this.$emit('update', newValue)
            },
            deep: true
        },
        qty(val){
            this.form.btn_cct_ids = R.take(val, this.form.btn_cct_ids)
        }
    },
    methods:{
        hasError(name){
        return { 'is-danger' : this.errors.first(name)}
        },    
    },
    computed:{
        cctRange(){
            if(this.qty == ''){
                return [];
            }
            
            return (Array(parseInt(this.qty)))
        }
    },
    mounted(){
        this.$emit('update', this.form)
    }
}
</script>



<style>
.is-danger .input{
    border-color: #ff0000;
}
</style>