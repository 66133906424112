<template>
    <div class="section">
      <div class="notification animated faster is-radiusless" style="position: fixed; top: 0px; left:0px; z-index:99; width: 100%;" :class="notificationAnim">
        <div class="container">
          <button class="delete" @click="hideErrorNotification" style="float:right"></button>
          {{ notification.message }}
        </div>
      </div>
      <h1 class="title is-3">Add/Remove Capacity</h1>
      <div v-if="!ready">
        <div class="">
          <div class="notification is-loading">
            <h4 class="title is-5">Loading data...</h4>
          </div>
        </div>
      </div>
      <form @submit.prevent="handleSubmit" class="form-horizontal" v-if="ready">
        <div class="columns is-gapless" >
          <div class="column is-6" style="position: relative; z-index:10">
            <div class="card">
              <div class="card-content">
                <div class="field">
                  <label for="cable_system" class="label">Cable System</label>
                  <div class="control">
                    <div class="select" :class="hasError('cable_system')">
                      <select
                        v-validate="rules.cable_system"
                        name="cable_system"
                        v-model="form.cable_system"
                      >
                        <option v-for="(cable, i) in cableNames" :value="cable" :key="i">{{ cable }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div v-if="form.cable_system">
                  <label for="cable_system" class="label">Landing Points</label>
                  <div v-for="(landing_point, i) in form.landing_points" :key="i" class="field">
                    <div class="columns is-vcentered">
                      <div class="column">
                        <div class="control">
                          <div class="select" :class="hasError(`cls_${i}`)">
                            <select
                              v-validate="rules.landing_points.cls"
                              :name="`cls_${i}`"
                              class="select"
                              placeholder="CLS"
                              v-model="landing_point.cls"
                            >
                              <option
                                v-for="(location, i) in landing_points"
                                :value="location"
                                :key="i"
                              >{{ location }}</option>
                              <option value="any">Any</option>
                              <option value="no-cls">No CLS</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="column ">
                        <div class="control">
                          <input v-validate="rules.landing_points.pop" type="text" :name="`pop_${i}`" class="input" :class="hasError(`pop_${i}`)" placeholder="POP (Optional)" v-model="landing_point.pop">
                        </div>
                      </div>
                    </div>
                  </div>
                  <label class="label" for="capacity">Capacity</label>
                  <div class="field has-addons">
                    <div class="control">
                      <input
                        :class="hasError('capacity')"
                        v-validate="rules.qty"
                        name="capacity"
                        class="input"
                        placeholder="Qty or Miu"
                        type="text"
                        v-model="form.qty"
                      >
                    </div>
                    <div class="control">
                            <input v-validate="rules.unit" type="hidden" name="unit" v-model="form.unit">
                      <div class="dropdown is-hoverable is-up">
                        <div class="dropdown-trigger">
                          <div class="button" :class="hasError('unit')">
                            <span>{{ units[form.unit] || 'select unit' }}</span>
                          </div>
                        </div>
                        <div class="dropdown-menu" role="menu">
                          <div class="dropdown-content">
                            <a
                              v-for="(label, unit) in units"
                              :key="unit"
                              class="dropdown-item"
                              @click="form.unit = unit"
                            >{{label}}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <label for="type" class="label">Type</label>
                    <div class="control">
                      <label v-for="(type,i) in types" :key="i" class="radio capry_radio" :class="hasError('type')">
                        <input v-validate="rules.type" type="radio" name="type" :value="type" v-model="form.type" :class="hasError('type')">
                        {{ type | fupper }}
                      </label>
                    </div>
                  </div>

                  <div class="field">
                    <label for="category" class="label">Category</label>
                    <div class="control">
                      <label v-for="(category,i) in categories" :key="i" class="radio capry_radio" :class="hasError('category')">
                        <input v-validate="rules.category" type="radio" name="category" :value="category" v-model="form.category">
                        {{ category | fupper }}
                      </label>
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Remarks</label>
                    <div class="control">
                      <textarea v-validate="rules.remarks" class="textarea" name="remarks" v-model="form.remarks" placeholder="eg: Upgrade capacity | released capacity from termination" :class="hasError('remarks')"></textarea>
                      <span class="help">Good remarks can help people to understand the history of this record</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card animated slideInUp easeOutBack faster" key="offnet" v-if="form.category == 'offnet'" style="height:100%">
              <div class="card-content">
                <offnet data-vv-value-path="form.meta" @update="updateMeta" @addCircuit="form.qty++" :qty="form.qty"/>
              </div>
            </div>

            <div class="card animated slideInUp easeOutBack faster" key="apollo" v-if="form.category == 'apollo'" style="height:100%">
              <div class="card-content">
                <apollo @update="updateMeta" @addCircuit="form.qty++" :qty="form.qty"/>
              </div>
            </div>
          </div>
        </div>
        <hr>
        
        <div class="buttons" v-if="!isDisabled" >
            <button type="submit" class="button is-primary" :class="{'is-loading':isLoading}">Save Capacity</button>
            <button class="button" @click="closeModal" :disabled="isLoading">Cancel</button>
        </div>
        <div v-else>
          Redirecting...
        </div>
        <hr>
      </form>
    </div>
</template>

<script>
import * as R from "ramda";
import Offnet from './Offnet.vue'
import Apollo from './Apollo.vue'
import { rules, validate } from './../../../Models/SubmarineCable.js'

export default {
  beforeRouteEnter(to,from,next){
      next(vm => {
          if(!vm.$store.getters.capry_usercan('manage_capacity')){
              vm.$router.replace('/')
          }
      })
  },
  components:{
    Offnet,
    Apollo
  },
  props: ["store"],
  data() {
    return {
      isLoading: false,
      isDisabled: false,
      notification: {
        status: false,
        message: '',
        timer: false
      },
      rules,
      categories: ["apollo", "offnet", "onnet"],
      units: {
        STM1: "x STM1",
        STM16: "x STM16",
        STM64: "x STM64",
        "10Gbps": "x 10Gbps",
        "100Gbps": "x 100Gbps",
        miu: "Miu"
      },
      types: ["stock", "asset"],
      form: {
        cable_system: "",
        category: "",
        type: "",
        qty: "",
        unit: "",
        landing_points: [
          { cls: '', pop:''},
          { cls: '', pop:''},
        ],
        meta: {},
        remarks: '',
      },
    };
  },
  mounted(){
    this.loadDataFromQuery()
  },
  methods: {
    loadDataFromQuery(){
      this.form = Object.assign({},this.form, this.$route.query)
    },
    closeModal() {
      this.$router.back()
    },
    hasError(name){
      return { 'is-danger' : this.errors.first(name)}
    },
    handleSubmit(){
      // console.log('Add Capacity Payload', this.form);
      this.isLoading = true;
      this.$validator.validateAll().then(() => {
        if(this.$validator.errors.any()){
          this.showNotification()
          this.isLoading = false
        }else{
          this.$store.dispatch('capry.saveCapacity', this.form).then(res =>  {
            console.log(res.data)
            this.isDisabled = true
            this.showNotification('Capacity saved', 'is-success')
            setTimeout(() => this.$router.push(`/inventory/show/${res.data.capacity}`), 500)
            
          }).catch(err => {
            this.showNotification(err.response.data.message);
          }).then(() => {
            this.isLoading = false;
            
          })
        }
      })
    },
    updateMeta(meta){
      this.form.meta = meta
    },
    hideErrorNotification(){
      this.notification = {
        status: 'hide'
      }
    },
    showNotification(msg, type){
      if(this.notification.timer){
        clearTimeout(this.notification.timer)
      }
      this.notification = {
        status: 'show',
        message: msg || 'Validation error. Please check the form',
        timer: setTimeout(() => { this.hideErrorNotification() } , 3000),
        type

      }
    }
  },
  computed: {
    notificationAnim(){
      if(!this.notification.status){
        return {'hide': true}
      }

      if(this.notification.status == 'show'){
        return {'slideInDown' : true , [this.notification.type || 'is-danger'] : true}
      }

      if(this.notification.status == 'hide'){
        return {'slideOutUp' : true, [this.notification.type || 'is-danger'] : true}
      }
    },
    cables() {
      return this.store.state.capry.cables;
    },
    cableNames() {
      return R.pluck("name", this.cables);
    },
    ready() {
      return this.cableNames.length > 0;
    },
    selected_cable() {
      return (
        R.head(
          R.filter(data => data.name === this.form.cable_system, this.cables)
        ) || {}
      );
    },
    landing_points() {
      return this.selected_cable.landing_points;
    }
  },
  filters:{
    fupper(value){
      return value[0].toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss">

.hide{
  display: none;
}
.capry_radio {
  display: inline-block;
  padding: 20px;
  border: solid 1px #ccc;
  border-radius: 5px;
}

.capry_radio.is-danger{
  border-color: #ff0000 !important;
}

.easeInOutBack {
    -webkit-animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550) !important;
    animation-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550)  !important;
}

.easeOutBack {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275) !important;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275)  !important;
}

</style>