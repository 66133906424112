<template>
    <div>
        <div class="modal is-active" v-if="showContractsPhase !== false">
            <div class="modal-background" @click="showContractsPhase = false" ></div>
                <div class="modal-card animated zoomIn faster" style="min-width: 60vw;">
                    <div class="card ">
                        <div class="card-header">
                            <div class="card-header-title">
                                Active Contract for {{ showContractsPhase }} as of Dec {{selectedYear}}
                            </div>
                            <span href="#" class="card-header-icon">
                                <div class="field has-addons">
                                    <p @click="selectedYear--" class="control">
                                        <a class="button">
                                            <span>&laquo; {{selectedYear - 1}}</span>
                                        </a>
                                    </p>
                                    <p class="control">
                                        <a @click="selectedYear++" class="button">
                                            <span> {{selectedYear + 1}} &raquo;</span>
                                        </a>
                                    </p>
                                    </div>
                            </span>
                        </div>
                    <div style="width:100%; overflow: auto; -webkit-overflow-scrolling: touch;">
                        <table class="table is-hoverable is-narrow is-fullwidth is-bordered is-marginless">
                        <thead class="has-background-light">
                            <tr> 
                                <th style="vertical-align:middle;">Phase</th>
                                <th style="vertical-align:middle;">Customer</th>
                                <th style="vertical-align:middle;">Segment</th>
                                <th style="vertical-align:middle;">Capacity</th>
                                <th style="vertical-align:middle;">Contract Start</th>
                                <th style="vertical-align:middle;">Contract End</th>
                                <th style="vertical-align:middle;" class="has-text-centered">Contract term</th>
                                <th style="vertical-align:middle;" class="has-text-right">MRC</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(contract, i) in showContracts" :key="i" :class="{'has-background-danger has-text-white' : contractExceedEndDate(contract)}">
                                <td>{{ contract.phase }}</td>
                                <td>{{ contract.contract_party }}</td>
                                <td>{{ contract.segment }}</td>
                                <td>{{ contract.capacity }} Gbps</td>
                                <td>{{ contract.contract_start_date.format('MMM-YYYY') }}</td>
                                <td>{{ contract.contract_end_date.format('MMM-YYYY') }}</td>
                                <td class="has-text-centered">{{ contract.contract_term }} months</td>
                                <td class="has-text-right">{{ contract.mrc | currency }}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>

                    <div class="card-footer is-marginless">
                        <div class="card-content">
                            Note: {{ showContractsPhase }} contract ends on {{ phaseEndDate(showContractsPhase).format('MMM-YYYY') }}
                        </div>
                    </div>
                </div>
            </div>
            <button @click="showContractsPhase = false" class="modal-close is-large" aria-label="close"></button>
        </div>
        <h1 class="title is-3">UTILIZATION ({{ contractYearRange }})</h1>
        <div class="tiles is-ancestor">
            <div class="tile is-parent">
                <div class="tile is-child">
                    <div class="card" style="width:100%">
                        <div class="card-content">
                            <apexcharts type="bar" height="300" :options="chartOptions" :series="utilizationSeries"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tiles is-ancestor">
            <div class="tile is-vertical">
                <div class="tile is-parent">
                <div class="tile is-child">
                    <div class="card" style="width:100%" >
                        <table class="table is-bordered is-hoverable is-fullwidth is-narrow">
                            <thead class="has-background-light">
                                <tr>
                                    <th colspan="10" class="has-text-centered">Year: {{ selectedYear}}</th>
                                </tr>
                                <tr>
                                    <th rowspan="2" style="vertical-align:middle;" >Phase</th>
                                    <th colspan="3">Total Capacity (Gbps)</th>
                                    <th colspan="3">Activated(Gbps)</th>
                                    <th colspan="3">Available(Gbps)</th>
                                </tr>
                                <tr>
                                    <th>S1</th>
                                    <th>S2</th>
                                    <th>S3</th>
                                    <th>S1</th>
                                    <th>S2</th>
                                    <th>S3</th>
                                    <th>S1</th>
                                    <th>S2</th>
                                    <th>S3</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="phase in phases" :key="phase">
                                    <th><a href="javascript:void(0);" @click="viewContracts(selectedYear, phase)">{{ phase }}</a></th>
                                    <td>{{ totalCapacity(phase,'S1', selectedYear)}}</td>
                                    <td>{{ totalCapacity(phase,'S2',selectedYear)}}</td>
                                    <td>{{ totalCapacity(phase,'S3',selectedYear)}}</td>
                                    <td>{{ activatedCapacity(phase, 'S1', selectedYear) }}</td>
                                    <td>{{ activatedCapacity(phase, 'S2', selectedYear) }}</td>
                                    <td>{{ activatedCapacity(phase, 'S3', selectedYear) }}</td>
                                    <td :class="{'has-background-danger has-text-white' : availableCapacity(phase, 'S1', selectedYear) < 0}">{{ availableCapacity(phase, 'S1', selectedYear) }}</td>
                                    <td :class="{'has-background-danger has-text-white' : availableCapacity(phase, 'S2', selectedYear) < 0}">{{ availableCapacity(phase, 'S2', selectedYear) }}</td>
                                    <td :class="{'has-background-danger has-text-white' : availableCapacity(phase, 'S3', selectedYear) < 0}">{{ availableCapacity(phase, 'S3', selectedYear) }}</td>
                                </tr>
                            </tbody>
                            <tfoot class="has-background-light">
                                <tr>
                                    <th style="vertical-align:middle;" rowspan="2">TOTAL</th>
                                    <th>{{ totalCapacity(false,'S1', selectedYear)}}</th>
                                    <th>{{ totalCapacity(false,'S2',selectedYear)}}</th>
                                    <th>{{ totalCapacity(false,'S3',selectedYear)}}</th>
                                    <th>{{ activatedCapacity(false, 'S1', selectedYear) }}</th>
                                    <th>{{ activatedCapacity(false, 'S2', selectedYear) }}</th>
                                    <th>{{ activatedCapacity(false, 'S3', selectedYear) }}</th>
                                    <th>{{ availableCapacity(false, 'S1', selectedYear) }}</th>
                                    <th>{{ availableCapacity(false, 'S2', selectedYear) }}</th>
                                    <th>{{ availableCapacity(false, 'S3', selectedYear) }}</th>
                                </tr>
                                <tr>
                                    <th colspan="3">{{ allTotalCapacity(selectedYear)}}</th>
                                    <th colspan="3">{{ allActivatedCapacity(selectedYear) }}</th>
                                    <th colspan="3">{{ allAvailableCapacity(selectedYear)}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment-timezone';
moment().tz("Asia/Kuala_Lumpur");
import * as R from 'ramda';

export default {
    props: ['state','getters'],
    components: {
        apexcharts: VueApexCharts,
    },
    data() {
        return {
            selectedYear: moment().format('YYYY'),
            currentYearData: [],
            showContractsPhase: false,
        };
    },
    computed: {
        showContracts(){
            const date = moment(this.selectedYear, 'YYYY')
                .add(1, 'year')
                .subtract(1, 'day');
            
            return R.pipe(
                R.filter(
                    contract =>
                        contract.isActive(date) &&
                        contract.isIncome() &&
                        !contract.isIRU() &&
                        contract.phaseIs(this.showContractsPhase)
                ),
                R.sortBy(R.prop('phase'))
            )(this.state.contracts);
        },
        contractYearRange() {
            return this.getters.apollo_contractYearRange.join(' - ');
        },
        selectedYearContract() {
            const date = moment(this.selectedYear, 'YYYY')
                .add(1, 'year')
                .subtract(1, 'day');
            return R.pipe(
                R.filter(
                    contract =>
                        contract.isActive(date) &&
                        contract.isIncome() &&
                        !contract.isIRU()
                ),
                R.sortBy(R.prop('phase'))
            )(this.state.contracts);
        },
        chartOptions() {
            return {
                chart: {
                    stacked: true,
                    events: {
                        dataPointSelection: this.dataPointSelection
                    }
                },
                legend: {
                    position: 'right'
                },
                labels: this.years,
                xaxis: {
                    title: {
                        text: 'Year'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Capacity (Gbps)'
                    }
                },
                tooltip: {
                    shared: true,
                    y: {
                        formatter(value) {
                            return `${value} Gbps`;
                        }
                    },
                    x: {
                        show: true
                    }
                }
            };
        },
        utilizationSeries() {
            return [
                {
                    name: 'Activated',
                    data: this.years.map(year =>
                        this.allActivatedCapacity(year)
                    )
                },
                {
                    name: 'Available',
                    data: this.years.map(year =>
                        this.allAvailableCapacity(year)
                    )
                }
            ];
        },
        phases() {
            return this.getters.apollo_phases;
        },
        years() {
            const years = this.getters.apollo_contractYearRange;
            return Array.from(Array(years[1] - years[0] + 1).keys()).map(
                i => parseInt(years[0]) + i
            );
        }
    },
    methods: {
        dataPointSelection(event, ctx, config){
            this.selectedYear = this.years[
                config.dataPointIndex
            ];
            this.currentYearData = this.utilizationSeries[
                config.seriesIndex
            ];
        },
        phaseEndDate(phase){
            return this.getters.apollo_phaseEndDate(phase);
        },
        contractExceedEndDate(contract){
            const date = moment(this.selectedYear, 'YYYY')
                .add(1, 'year')
                .subtract(1, 'day');
            return this.phaseEndDate(contract.phase).isBefore(date)
        },
        viewContracts(year, phase) {
            this.showContractsPhase = phase;
        },
        totalCapacity(phase, segment, year) {
            const date = moment(year, 'YYYY')
                .add(1, 'year')
                .subtract(1, 'day');
            return this.getters.apollo_totalCapacity(
                asset =>
                    asset.phaseIs(phase) &&
                    asset.segmentIs(segment) &&
                    asset.isActive(date)
            );
        },
        activatedCapacity(phase, segment, year) {
            const date = moment(year, 'YYYY')
                .add(1, 'year')
                .subtract(1, 'day');
            return this.getters.apollo_totalActivatedCapacity(
                record =>
                    record.phaseIs(phase) &&
                    record.segmentIs(segment) &&
                    record.isActive(date)
            );
        },
        availableCapacity(phase, segment, year) {
            const date = moment(year, 'YYYY')
                .add(1, 'year')
                .subtract(1, 'day');
            return this.getters.apollo_totalAvailableCapacity(
                record =>
                    record.phaseIs(phase) &&
                    record.segmentIs(segment) &&
                    record.isActive(date)
            );
        },
        allTotalCapacity(year) {
            return (
                this.totalCapacity(false, 'S1', year) +
                this.totalCapacity(false, 'S2', year) +
                this.totalCapacity(false, 'S3', year)
            );
        },
        allActivatedCapacity(year) {
            return (
                this.activatedCapacity(false, 'S1', year) +
                this.activatedCapacity(false, 'S2', year) +
                this.activatedCapacity(false, 'S3', year)
            );
        },
        allAvailableCapacity(year) {
            return (
                this.allTotalCapacity(year) - this.allActivatedCapacity(year)
            );
        }
    },
    filters: {
        currency(value) {
            return value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
        }
    }
};
</script>

