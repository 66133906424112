<template>
   <div>
     <div v-if="chartdata && (chartdata||[]).length === 0" class="has-text-centered">
       <h1 class="is-title is-size-1 has-text-grey-light has-text-weight-bold">NO DATA</h1>
     </div>
     <div v-else>
        <h2 class="is-title is-size-3">{{title}}</h2>
        <apexcharts v-if="showGraph" type="bar" :options="chartOptions || {}" :series="series || []"></apexcharts>
        <div class="tags">
          <div v-for="(data, i) in chartdata" :key="i" class="tags has-addons" style="margin:5px;">
            <span class="tag is-dark">{{ data.cable }}</span>
            <span class="tag is-info">{{ cableRemainingDesignLife(data.cable) }} years</span>
          </div>
        </div>
     </div>
   </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
const R = require('ramda')

export default {
  components: {
    apexcharts: VueApexCharts
  },
  props: ['chartdata', 'title', 'capacities'],
  data: function () {
    return {
      show: false
    }
  },
  computed: {
    unit () {
      return this.capacities.totalCableCapacity(this.chartdata).unit
    },
    showGraph () {
      return (this.series || []).length > 0
    },
    chartDataMap(){
      return R.indexBy(R.prop('cable'),this.chartdata)
    },
    chartOptions () {
      const unit = this.unit
      return {
        chart: {
          id: 'capacity-chart',
          width: '100%'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return `${val}${unit}`
          },
          style: {
            fontSize: '12px'
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            opacity: 0.45
          }
        },
        xaxis: {
          categories: this.cableNames()
        },
        legend: {
          position: 'top'
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              height: 600
            },
            legend: {
              position: 'top'
            },
            dataLabels: {
              style: {
                fontSize: '14px'
              },
              offsetX: -10
            },
            plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top'
                }
              }
            }
          }
        }]
      }
    },
    series () {
      return [
        this.getSeriesData('stm_1', 'STM 1'),
        this.getSeriesData('stm_16', 'STM 16'),
        this.getSeriesData('stm_64', 'STM 64'),
        this.getSeriesData('asset_10g', 'Asset - 10G'),
        this.getSeriesData('asset_100g', 'Asset - 100G'),
        this.getSeriesData('stock_10g', 'Stock - 10G'),
        this.getSeriesData('stock_100g', 'Stock - 100G'),
        this.getSeriesData('mil_miu', 'Mil MIU')
      ].filter(series => R.sum(series.data) > 0)
    }
  },
  methods: {
    getSeriesData (key, label, data) {
      return {
        name: label,
        data: this.cableNames().map(cable => {
          return this.chartDataMap[cable][key] || 0
        })
      }
    },
    cableNames () {
      return R.pluck('cable', this.chartdata)
    },
    cableRemainingDesignLife (cable) {
      return this.capacities.cableRemainingDesignLife(cable)
    }
  }
}
</script>
