<template>
    <div class="steps">
        <div class="step-item" :class="isCompletedClass(1)">
            <div class="step-marker">
                <span class="icon" v-if="isCompleted(1)">
                    <svg v-if="isCompleted(1)" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                    </svg>
                </span>
            </div>
            <div class="step-details">
            <p class="step-title">Capacity Reservation</p>
            <p>Reserve Capacity for funnel</p>
            </div>
        </div>

        <div class="step-item" :class="isCompletedClass(2)">
            <a class="step-marker" @click="$emit('change', 2)">
            <span class="icon">
                <svg v-if="isCompleted(2)" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                </svg>
            </span>
            </a>
            <div class="step-details">
            <p class="step-title">Order Confirmation</p>
            <p>Received confirmation and SOFCA from customer</p>
            </div>
        </div>

        

        <div class="step-item" :class="isCompletedClass(3)">
            <div class="step-marker">
                <span class="icon" v-if="isCompleted(3)">
                    <svg v-if="isCompleted(1)" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                    </svg>
                </span>
            </div>
            <div class="step-details">
            <p class="step-title">Order Provisioning</p>
            <p>Order submitted and provisining in progress</p>
            </div>
        </div>

        <div class="step-item" :class="isCompletedClass(4)">
            <div class="step-marker">
            <span class="icon" v-if="isCompleted(4)">
                    <svg v-if="isCompleted(1)" xmlns="http://www.w3.org/2000/svg" fill="#fff" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                    </svg>
                </span>
            </div>
            <div class="step-details">
            <p class="step-title">Capacity Activated</p>
            <p>Order completed and activated</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['current_step'],
    methods:{
        isCompleted(step){
            return this.current_step > step
        },
        isCompletedClass(step){
            return {
                'is-completed': this.isCompleted(step),
                'is-active': this.current_step == step,
                
            }
        }
    }
}
</script>

<style lang="scss">
@import './../../../../utils/styles/tm.scss';
@import './../../../../node_modules/bulma-steps/src/sass/index.sass';
</style>
