import axios from 'axios'
import csv from 'csvtojson'
const R = require('rambda')

const CAPACITIES_SHEET = '1PXFPB0_eKYv2ove8G5aEpMVyG1e_NhMj1ljURHqphQs'
// const CABLES_SHEET = '1d5v3teBn8d8grcJROed4JRAFF8P8TeVA5dl96HxssMo'

const getNAAttributes = data => {
  var keys = []

  R.keys(data).forEach(key => {
    if (data[key] === 'N/A') {
      keys.push(key)
    }
  })

  return keys
}

const filterRouteBy = routeName => R.filter(data => data.routes === routeName)
const removeNAAttributes = (data) => R.omit(getNAAttributes(data), data)
const toNum = R.map(data => parseFloat(data) || data)

const loadGoogleSheet = async (id, gid) => {
  var url = `https://37a0gi9tyj.execute-api.ap-southeast-1.amazonaws.com/prod/`
  return axios.post(url, {id, gid}).then(res => res.data)
}

const find = (routes, capacities) => {
  const routeName = `${routes[0]}-${routes[1]}`
  const process = R.pipe(filterRouteBy(routeName), R.map(R.pipe(removeNAAttributes, toNum)))
  return process(capacities)
}

const extractCapacity = cable => [
  cable.asset_10g * 1 || 0,
  cable.asset_100g * 1 || 0,
  cable.stock_10g * 1 || 0,
  cable.stock_100g * 1 || 0,
  cable.stm_1 * 1 || 0,
  cable.stm_16 * 1 || 0,
  cable.stm_64 * 1 || 0, // TODO: to confirm 
]

const sum = R.reduce((total, num) => total + num, 0)

const cableUtilization = (cable) => {
  var calculatedUtilization = false

  if (cable.total_capacity && cable.remaining_capacity) {
    calculatedUtilization = (cable.total_capacity - cable.remaining_capacity) / cable.total_capacity
  }

  if (calculatedUtilization && calculatedUtilization !== cable.utilization) {
    // console.log('UTILIZATION NOT MATCHED', cable.cable, calculatedUtilization, cable.utilization)
  }

  return calculatedUtilization
}

const totalCableCapacity = (cables) => {
  if (cables.length === 0) {
    return false
  }

  const totalBw = R.pipe(R.map(extractCapacity), R.flatten, sum)(cables)
  const totalMiu = R.pipe(R.pluck('mil_miu'), sum)(cables)

  return {
    total: totalBw || totalMiu,
    unit: totalBw ? 'G' : 'mil'
  }
}

const getCable = (cables, cable) => {
  return cables[cable] || {}
}

const load = async () => {
  var [capacities, cables, metadata] = await Promise.all([
    loadGoogleSheet(CAPACITIES_SHEET, 1462501707),
    loadGoogleSheet(CAPACITIES_SHEET, 137740937).then(R.indexBy(R.prop('cable'))),
    loadGoogleSheet(CAPACITIES_SHEET, 941916839)
  ])

  cables = R.map(cable => {
    return Object.assign({}, cable, {
      utilization: cableUtilization(cable)
    })
  }, cables)

  return {
    find: (routes) => find(routes, capacities),
    totalCableCapacity,
    totalCapacity: (routes) => totalCableCapacity(find(routes, capacities)),
    cables: (routes) => R.pluck('cable', find(routes, capacities)),
    cableRemainingDesignLife: (cable) => getCable(cables, cable).remaining_design_life,
    allCables: () => cables,
    metadata: R.pipe(R.indexBy(R.prop('key')), R.map(data => data.value))(metadata),
    capacities,
  }
}

export default {
  load
}
