<template>
    <div >
        <div v-if="loading">
            <div class="has-text-centered">
                <span class="button is-text is-loading"></span>
            </div>
        </div>
        <div class="columns" v-else>
            <div class="column is-9">
                <h2 class="title is-5">Cable information</h2>
                <div class="content">
                    <markdown :editable=editable :content="info.details" @onsave="saveCableInfo" />
                    <span class="help has-text-grey-light" v-if="info">Last updated on <span class="tooltip has-text-grey-darker" :data-tooltip="info.lastUpdateDate | formatDate ">{{ info.lastUpdateDate | fromNow}}</span> by <span class="has-text-grey-darker">{{ info.created_by | username}}</span></span>
                </div>
            </div>
            <div class="column">
                <div class="level">
                    <div class="level-start">
                        <h2 class="title is-5">Cable Details</h2>
                    </div>
                    <div class="level-end">
                        <a v-if="editable && !editOwner" @click="editOwner = true" class="button is-small is-pulled-right">Change</a>
                    </div>
                </div>
                
                <div v-if="editOwner" class="box">
                    <div class="field">
                        <span class="heading">Select user</span>
                        <v-select v-model="currentUser" class="select2 is-fullwidth has-background-white" style="width: 100%;" label="name"  :options="users" >
                            <template slot="option" slot-scope="option">
                                <div>
                                    <span class="heading">{{option.staff_id}}</span>
                                    <span>{{option.name}}</span>
                                </div>
                            </template>
                        </v-select>
                    </div>

                    <div class="field">
                        <span class="heading">Cable End of Life</span>
                        <div class="control">
                            <select v-model="endOfLife" class="select">
                                <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                            </select>
                        </div>
                    </div>

                    <!-- <div class="field">
                        <span class="heading">Network Diagram</span>
                        <div class="control">
                            <div class="file">
                                <label class="file-label">
                                    <input class="file-input" type="file" name="resume">
                                    <span class="file-cta">
                                    <span class="file-icon">
                                        <i class="fas fa-upload"></i>
                                    </span>
                                    <span class="file-label">
                                        Choose a file…
                                    </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div> -->

                    <hr>
                    <div class="buttons">
                        <button @click="changeOwner" class="button is-primary">Update</button> <button @click="editOwner = false" class="button is-text">Cancel</button>
                    </div>
                </div>
                <div v-else>
                    <div v-if="owner.details" class="content">
                        <p>
                            <span class="heading">DATA MANAGER</span>
                            <span class="has-text-weight-bold">{{owner.details.user.name}}</span> <br>
                            <span class="is-size-7">{{owner.details.user.staff_id}} - {{ owner.details.user.division }}</span>
                        </p>
                        <hr>
                        <p v-if="remaingUsefulLife !== false">
                            <span class="heading">REMAINING USEFUL LIFE</span>
                            <span class="has-text-weight-bold">{{ remaingUsefulLife }} year(s)</span> <br>
                            <span class="is-size-7 has-text-grey-light">Ended on {{ end_of_life }}</span>
                        </p>
                        <p v-else>
                            <span class="heading">REMAINING USEFUL LIFE</span>
                            <span class="has-text-weight-bold">Unspecified</span> <br>
                        </p>
                    </div>
                    <div v-else>
                        <div class="has-text-centered">
                            <span class="is-size-4 has-text-grey-lighter">No owner updated</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import * as R from 'ramda'
import moment from 'moment'
import Markdown from './Markdown.vue'
import VSelect from 'vue-select'
export default {
    components:{
        Markdown,
        VSelect,
    },
    props:['cable', 'route', 'editable'],
    data(){
        return {
            loading: false,
            editOwner: false,
            currentUser: '',
            endOfLife: '',
        }
    },
    mounted(){
        this.loadCableInfo()
        this.loadRouteInfo(this.route)
    },
    computed:{
        years(){
            return [...Array(30).keys()].map(i => i + 2019)
        },
        contents(){
            return this.$store.state.capry.content[this.cableId] || {}
        },
        info(){
            return this.contents.information || {}
        },
        cableId(){
            return (this.$store.state.capry.cables.find(data => data.name == this.cable) || {}).cable_id
        },
        users(){
            return this.$store.state['user-management'].users
        },
        owner(){
            return this.contents.owner || {}
        },
        end_of_life(){
            return R.view(R.lensPath(['details', 'content']), this.contents.end_of_life)
        },
        remaingUsefulLife(){
            const thisyear = moment(moment().format('YYYY'), 'YYYY')
            const diff = moment(this.end_of_life, 'YYYY').diff(thisyear,'years')
            if(isNaN(diff)){
                return false
            }

            return diff
        }
    },
    watch:{
        cableId(){
            this.loadCableInfo()
        },
        editOwner(value){
            if(value){
                this.loadUsers()
            }

        }
    },
    methods:{
        changeOwner(){
            if(!this.currentUser || this.$endOfLife){
                alert("Please select user and end of life")
                return;
            }
            this.editOwner = false;
            this.loading = true
            Promise.all([
                this.$store.dispatch('capry.content.update', {
                    PK: this.cableId,
                    type: 'owner',
                    details: {
                        user: this.currentUser
                    },
                }),
                this.$store.dispatch('capry.content.update', {
                    PK: this.cableId,
                    type: 'end_of_life',
                    details: {
                        content: this.endOfLife
                    },
                })
            ]).then(() => {
                this.loading = false
            })
        },
        loadUsers(){
            if(this.users.length == 0){
                this.$store.dispatch('user-management.load')
            }
        },
        loadCableInfo(){
            this.loading = true
            this.$store.dispatch('capry.content.load', this.cableId).then(() => {
                this.loading = false
                this.currentUser = (this.owner.details || {}).user
                this.endOfLife = this.end_of_life
            })
        },
        loadRouteInfo(route){

        },
        saveCableInfo(content){
            this.loading = true
            this.$store.dispatch('capry.content.update', {
                PK: this.cableId,
                type: 'information',
                details: {
                    content
                },
            }).then(() => {
                this.loading = false
            })
        }
        
    },
    filters:{
        username(value){
            return R.pipe(R.split(":"),R.last)(value || '')
        },
        formatDate(value){
            return moment(value).format('DD MMM YYYY hh:mm A')
        },
        fromNow(value){
            return moment(value).fromNow()
        }
    }
}
</script>



<style>
.float{
    position: absolute;
}


.v-select , .v-select .dropdown-toggle{
    width: 100%;
}

.is-clickable{
    cursor:pointer !important;
}

</style>